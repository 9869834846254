import {Box, Button, Tabs} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import React, {useEffect, useState} from "react";
import DetailProcessing from "./DetailProcessing/DetailProcessing";
import {constantsErrorMessages} from "../../../Constants";
import DetailControllerService from "../../../API/details/DetailControllerService";
import {useNavigate, useParams} from "react-router-dom";
import VariableControllerService from "../../../API/details/VariableControllerService";
import VariablesTable from "../../../components/UI/table/customer-detales/processing/VariablesTable";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";
import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import {ButtonGroupTabBar} from "../../../components/UI/button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";
import ExportDetail from "./ExportDetail";
import SamplesControllerService from "../../../API/samples/Customers/SamplesController";

const LOADING_BOX_SX = {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const Loading = React.memo(() => (
    <Box sx={LOADING_BOX_SX}>
        <CircularProgress disableShrink/>
    </Box>
))

const Processing = () => {
    const params = useParams();
    const detailId = params.id
    const {orderId = 0, customerOrderNumber, requestsCuttingId, requestsCuttingNumber} = params
    const navigate = useNavigate();

    const redirectOrders = () => {
        navigate('/customer/orders/');
    };

    const redirectDetails = () => {
        const pathname = `/customer/orders/${orderId}/${customerOrderNumber}`
        EditLocalStoragePagesData(pathname, "tab", "6")
        navigate(pathname);
    };

    const [tab, setTab] = useState(1);

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [change, setChange] = useState(true);

    const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'
    const [detailData, setDetailData] = useState({});
    const [variables, setVariables] = useState({});

    const handleError = (error) => {
        // setIsError(true)
        setAlertData({
            type: 'error',
            open: true,
            message: constantsErrorMessages[error.response?.status] || error.response.data.message || error.message
        })
    }
    const getDetailData = () => {
        if (orderId) {
            DetailControllerService.getById(orderId, detailId).then((response) => {
                setDetailData(response.data);
                setIsError(false);
            })
                .catch((error) => {
                    handleError(error)
                    redirectDetails()
                })
                .finally(() => setIsLoading(false))
        } else {
            SamplesControllerService.getById(detailId)
                .then(function (response) {
                    setDetailData(response.data.detailDto);
                    setVariables(response.data.detailDto.variables);
                    setIsError(false)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => setIsLoading(false))
        }

    }

    const getVariablesData = () => {
        orderId && VariableControllerService.get(orderId, detailId)
            .then(function (response) {
                const data = response?.data
                setVariables(data);
                setIsError(false)
            })
            .catch(function (error) {
                handleError(error)
            })
    }

    const handleEditVariables = (data) => {
        setVariables(data)
        getDetailData()
    }

    useEffect(() => {
        setIsLoading(true)
        getDetailData()
        getVariablesData()

    }, [change]);


    return (
        isLoading
            ? <Loading/>
            : (
                <Box sx={{padding: "0 30px"}}>
                    <BreadcrumbsComponent
                        page={`Обработка детали ${detailData.position}`}
                        path={orderId ? [
                            {name: "Главная", href: "/"},
                            {name: "Мои заказы", href: "/customer/orders"},
                            {
                                name: `Заказ № ${customerOrderNumber}`,
                                href: `/customer/orders/${orderId}/${customerOrderNumber}`
                            },
                            {
                                name: `Детали`, href: requestsCuttingId ?
                                    `/customer/orders/${orderId}/${customerOrderNumber}/requests-cutting/${requestsCuttingId}/${requestsCuttingNumber}/details` :
                                    `/customer/orders/${orderId}/${customerOrderNumber}`
                            }
                        ] : [{name: "Главная", href: "/"}, {name: "Шаблоны", href: "/customer/samples"},]}/>
                    <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                        <ButtonGroupTabBar activeTab={tab}>
                            <Button tabIndex={1}
                                    onClick={() => setTab(1)}
                            >
                                <CheckIcon/>
                                Обработка
                            </Button>
                            <Button tabIndex={2}
                                    onClick={() => setTab(2)}
                            >
                                <CheckIcon/>
                                Переменные
                            </Button>
                            <Button tabIndex={3}
                                    onClick={() => setTab(3)}
                            >
                                <CheckIcon/>
                                Экспорт
                            </Button>
                        </ButtonGroupTabBar>
                        {tab === 1 ? <DetailProcessing
                            setIsLoading={setIsLoading}
                            setIsError={setIsError}
                            detailId={detailId}
                            handleError={handleError}
                            setChange={setChange}
                            orderId={orderId}
                            detailData={detailData}
                            redirect={{orders: redirectOrders, details: redirectDetails}}
                            variables={variables}
                        /> : tab === 2 ? <VariablesTable
                                data={variables}
                                isError={isError}
                                setData={handleEditVariables}
                                enableEditing={true}
                                orderId={orderId}
                                detailId={detailId}/> :
                            <ExportDetail
                                orderId={orderId}
                                detailId={detailId}
                                position={detailData.position}
                                handleError={handleError}
                                setIsError={setIsError}/>}
                    </Box>
                    <ModalAlert
                        data={alertData}
                        onClose={() => setAlertData((alert) => ({...alert, open: false}))}
                    />
                </Box>
            )
    )
}
export default Processing
