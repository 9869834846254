export const addressInputFields = {
    "postalcode": "Почтовый индекс",
    "country": "Страна",
    "republic": "Республика",
    "territory": "Край",
    "region": "Область",
    "districts": "Район",
    "locality": "Населённый пункт",
    "street": "Улица",
    "houseNumber": "Номер дома(здания)",
    "building": "Корпус",
    "roomType": "Тип помещения",
    "roomNumber": "Номер помещения",
    "latitude": "Широта",
    "longitude": "Долгота",
    "additionalInfo": "Дополнительная информация",
}