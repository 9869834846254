import React, {useEffect, useState} from 'react';
import WorkerTable from "../../../../components/UI/table/orders/organization/workerTable/WorkerTable";
import WorkerService from "../../../../API/orders/job/WorkerService";

const Workers = () => {
    const [data, setData] = useState([]);
    const [unable, setUnable] = useState({});
    const [unableProduction, setUnableProduction] = useState({});
    const [unableConstructor, setUnableConstructor] = useState({});

    useEffect(() => {
        WorkerService.getAll().then(function (response) {
            let data = response.data;
            setData(response.data);
            data.forEach((row) => {
                unable[row.id] = row.enabled;
                unableProduction[row.id] = row.production;
                unableConstructor[row.id] = row.constructor;
            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);
    return (
        <div>
            <WorkerTable
                data={data} setData={setData}
                unable={unable} setUnable={setUnable}
                unableProduction={unableProduction}
                setUnableProduction={setUnableProduction}
                unableConstructor={unableConstructor}
                setUnableConstructor={setUnableConstructor}/>
        </div>
    );
};

export default Workers;