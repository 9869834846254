import React from 'react';
import CustomerOffersTable from "../../../components/UI/table/orders/customer/order/CustomerOffersTable";
import {useParams} from "react-router-dom";

const CustomerOrderOffers = () => {
    const params = useParams();
    const customerOrderNumber = params.customerOrderNumber;
    const orderId = params.orderId;

    return (
        <div>
            <CustomerOffersTable
                orderId={orderId}
                customerOrderNumber={customerOrderNumber}
            />
        </div>
    );
};

export default CustomerOrderOffers;