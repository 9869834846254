import React, {useState} from "react";
import {CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";
import UploadFilesForm from "../../form/UploadFilesForm";

const UploadGroupOfMaterialFileForm = ({
                                           label, uploadData, multiple
                                       }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleUpload = async (event) => {
        setIsLoading(true)
        event.preventDefault();
        uploadData.downloadFile(multiple ? selectedFile : selectedFile[0])
            .then(function (response) {
                event.target.reset();
                setSelectedFile(null)
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                console.log(error);
            });
    };

    return (
        <div>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '3px',
            }}>
                <Box sx={{marginRight: '10px'}}>
                    <UploadFilesForm
                        uploadData={uploadData}
                        handleUpload={handleUpload}
                        selectedFiles={selectedFile}
                        setSelectedFiles={setSelectedFile}
                        label={label}
                        multiple={multiple}
                    />
                </Box>
                {isLoading && <CircularProgress
                    color={'secondary'}
                    size={30}
                    thickness={4}
                />}
            </Box>
        </div>
    );
};

export default UploadGroupOfMaterialFileForm;