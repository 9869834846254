import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import SignJobNameService from "../../../../../../API/orders/job/SignJobNameService";
import ButtonCreate from "../../../../button/ButtonCreate";
import ModalAlert from "../../../customer-detales/ModalAlert";
import JobNameModal from "./JobNameModal";
import {constantsErrorMessages} from "../../../../../../Constants";

const JobNameTable = ({}) => {
    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [alertData, setAlertData] = useState({});
    const [modal, setModal] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);

    useEffect(() => {
        SignJobNameService.getAllOrganization().then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error', open: true, message: constantsErrorMessages[error.response.status] || error.message
        })
    }

    const handleClose = () => {
        setModal(false)
        setCurrentEditRowData(null)
    }

    const handleUpdate = (data) => {
        SignJobNameService.update(data, currentEditRowData.id)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleDelete = (row) => {
        SignJobNameService.delete(row.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.id))
            })
            .catch(function (error) {
                handleError(error)
            })
    };

    const handleSubmit = (data) => {
        SignJobNameService.create(data)
            .then(function (response) {
                setData(prev => [...prev, response.data])
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }


    const handleAdd = () => {
        setModal(true)
    }
    const handleEdit = (row) => {
        setCurrentEditRowData(row);
        setModal(true)
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'Найменование', accessorKey: 'name', minSize: 200,
            },
        ]

        return baseColumns;

    }, [data]);

    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEdit},
        {type: "delete", text: "Удалить", handler: handleDelete}
    ]

    return (<div>
        <Box
            sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
        >
            <ButtonCreate
                text={"Добавить найменование"}
                width={255}
                onClick={handleAdd}
            />
        </Box>
        <MaterialReactTable
            muiTableContainerProps={{
                sx: {
                    width: '800px',
                }
            }}
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": {
                        padding: 0,
                        width: "100%",
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",
                        "&:hover& td:after": {
                            display: "none",
                        },
                        "&:hover": {
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={isError ? [] : data}
            rowHeight={13}
            columnSpacing={1}
            getRowId={(originalRow) => originalRow.id}
            selectionPadding={1}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return (el.type === "edit" || (el.type === "delete" && !row.original.blocked)) && <MenuItem
                    key={el.type}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })
            }
            localization={MRT_Localization_RU}
            enableRowActions={true}
            enableToolbarInternalActions={false}

        />
        <ModalAlert data={alertData} onClose={handleCloseAlert}/>
        <JobNameModal title={currentEditRowData ? "Изменить" : "Добавить"}
                      open={modal} onClose={handleClose}
                      handleSubmit={currentEditRowData ? handleUpdate : handleSubmit}
                      initValue={currentEditRowData}/>
    </div>);
};

export default JobNameTable;
