import React from "react";
import {Button, DialogContent, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import TextFieldWithSetValueMainOptions
    from "../../../components/UI/modul/mainoptions/TextFieldWithSetValueMainOptions";
import MainOptionsMaterialModuleEntityTextField
    from "../../../components/UI/modul/mainoptions/MainOptionsMaterialModuleEntityTextField";
import TextFieldArrivalMaterialModuleItem
    from "../../../components/UI/modul/arrivalmaterialmoduleitem/TextFieldArrivalMaterialModuleItem";
import MaterialTextFieldObjectWithEntityList
    from "../../../components/UI/modul/mainoptions/MaterialTextFieldObjectWithEntityList";
import {typeMaterial} from "../../../data/TypeMaterial";
import {statusesYesOrNo} from "../../../data/StatusesYesOrNo";
import {materialCutDefault} from "../../../data/MaterialCutDefault";
import {structure} from "../../../data/Structure";

const MaterialItemSettings = ({
                                  data,
                                  dataMaterial,
                                  setData,
                                  supplierList,
                                  groupMaterialList,
                                  manufacturerList,
                                  handleSubmit,
                                  addMaterial,
                                  setSupplierId,
                                  setManufacturerId,
                                  setGroupMaterialId,
                                  baseUnit,
                                  isDataMOO,
                                  newMaterial,
                                  isMaterialOrganizationOption
                              }) => {
    const isSlab = dataMaterial?.typeMaterial === 'SLAB' || data?.typeMaterial === 'SLAB'
    const isPiece = data?.typeMaterial === 'PIECE'
    console.log(data, data?.typeMaterial)
    return <div>
        <DialogContent>
            <form onSubmit={(e) => e.preventDefault()}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    "& > *:not(:last-child)": {marginRight: '20px'}
                }}>
                    {isDataMOO && <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            maxWidth: '500px',
                        }}
                    >
                        <Box>
                            <TextFieldWithSetValueMainOptions
                                label="Артикул"
                                values={data}
                                setValues={setData}
                                field={'articleNumber'}
                                disabled={!newMaterial}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Текущее найменование"
                                values={data}
                                setValues={setData}
                                field={'name'}
                                disabled={!newMaterial}
                            />
                            {supplierList && <MainOptionsMaterialModuleEntityTextField
                                label={'Поставщик по умолчанию'}
                                values={data}
                                field={'defaultSupplier'}
                                childFirstField={'id'}
                                setId={setSupplierId}
                                entityList={supplierList}
                                disabled={!newMaterial}
                            />}
                            <TextFieldArrivalMaterialModuleItem
                                label="Ед. измерения"
                                value={baseUnit}
                                field={baseUnit}
                                maxWidth={'600px'}
                                disabled={!newMaterial}

                            />
                            <MaterialTextFieldObjectWithEntityList
                                label="Тип материала"
                                values={data}
                                setValues={setData}
                                field={'typeMaterial'}
                                entityList={typeMaterial}
                                disabled={!newMaterial}
                            />
                            {isPiece && <TextFieldWithSetValueMainOptions
                                label="Вес"
                                values={data}
                                setValues={setData}
                                field={'density'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={!newMaterial}
                            />}
                            {!isPiece && <MaterialTextFieldObjectWithEntityList
                                label="Архив"
                                values={data}
                                setValues={setData}
                                field={'archive'}
                                entityList={statusesYesOrNo}
                                disabled={!newMaterial}
                            />}
                            {newMaterial && <MaterialTextFieldObjectWithEntityList
                                label="Выдача не по смете"
                                values={data}
                                setValues={setData}
                                field={'deliveryNotAccordingToEstimate'}
                                entityList={statusesYesOrNo}
                                disabled={!newMaterial}
                            />}
                            {(isSlab || newMaterial) && <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                                <TextFieldWithSetValueMainOptions
                                    label="Ширина"
                                    values={dataMaterial}
                                    setValues={setData}
                                    field={'length'}
                                    marginRight={'10px'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                    disabled={!newMaterial}
                                />
                                <TextFieldWithSetValueMainOptions
                                    label="Высота"
                                    values={dataMaterial}
                                    setValues={setData}
                                    field={'width'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                    disabled={!newMaterial}
                                />
                            </Box>}
                            {(isSlab || newMaterial) && <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                                <TextFieldWithSetValueMainOptions
                                    label="Толщина"
                                    values={dataMaterial}
                                    setValues={setData}
                                    field={'thickness'}
                                    marginRight={'10px'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                    disabled={!newMaterial}
                                />
                                {isPiece && <TextFieldWithSetValueMainOptions
                                    label="Плотность"
                                    values={dataMaterial}
                                    setValues={setData}
                                    field={'density'}
                                    minWidthXS={'25px'}
                                    minWidthSM={'25px'}
                                    minWidthPX={'25px'}
                                    disabled={!newMaterial}
                                />}
                            </Box>}
                            {(isSlab || newMaterial) && <MaterialTextFieldObjectWithEntityList
                                label="Структура"
                                values={dataMaterial}
                                setValues={setData}
                                field={'structure'}
                                entityList={structure}
                                disabled={!newMaterial}
                            />}
                        </Box>
                    </Stack>}
                    {isDataMOO && <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            maxWidth: '200px',
                        }}
                    >
                        {(isSlab || newMaterial) && <MaterialTextFieldObjectWithEntityList
                            label="Кроить по умолчанию"
                            values={data}
                            setValues={setData}
                            field={'cutDefault'}
                            entityList={materialCutDefault}
                            disabled={!newMaterial}
                        />}

                        {(isSlab || newMaterial) && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп слева"
                                values={data}
                                setValues={setData}
                                field={'trimmingLeft'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={!newMaterial}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп справа"
                                values={data}
                                setValues={setData}
                                field={'trimmingRight'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={!newMaterial}
                            />
                        </Box>}
                        {(isSlab || newMaterial) && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            maxWidth: '100%',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп снизу"
                                values={data}
                                setValues={setData}
                                field={'trimmingBottom'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                marginRight={'10px'}
                                disabled={!newMaterial}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп сверху"
                                values={data}
                                setValues={setData}
                                field={'trimmingTop'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={!newMaterial}
                            />
                        </Box>}
                        {(isSlab || newMaterial) && <TextFieldWithSetValueMainOptions
                            label="Минимальный остаток до целого листа"
                            values={data}
                            setValues={setData}
                            field={'minBalanceUpToWholeSheet'}
                            minWidthXS={'25px'}
                            minWidthSM={'25px'}
                            minWidthPX={'25px'}
                            disabled={!newMaterial}
                        />}

                        {(isSlab || newMaterial) && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Мин. ширина обрезка"
                                values={data}
                                setValues={setData}
                                field={'minWeightCutOff'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={!newMaterial}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Мин. высота обрезка"
                                values={data}
                                setValues={setData}
                                field={'minHeightCutOff'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={!newMaterial}
                            />
                        </Box>}
                        {(isSlab || newMaterial) && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп обрезка слева"
                                values={data}
                                setValues={setData}
                                field={'trimmingLeftCutOff'}
                                marginRight={'10px'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={!newMaterial}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп обрезка справа"
                                values={data}
                                setValues={setData}
                                field={'trimmingRightCutOff'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={!newMaterial}
                            />
                        </Box>}
                        {(isSlab || newMaterial) && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            maxWidth: '100%',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп обрезка снизу"
                                values={data}
                                setValues={setData}
                                field={'trimmingBottomCutOff'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                marginRight={'10px'}
                                disabled={!newMaterial}
                            />
                            <TextFieldWithSetValueMainOptions
                                label="Отсуп обрезк сверху"
                                values={data}
                                setValues={setData}
                                field={'trimmingTopCutOff'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={!newMaterial}
                            />
                        </Box>}
                        {(isSlab || newMaterial) && <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            maxWidth: '100%',
                        }}>
                            <TextFieldWithSetValueMainOptions
                                label="Ширина реза"
                                values={data}
                                setValues={setData}
                                field={'cutWeight'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={!newMaterial}
                            />
                            <Box sx={{marginLeft: '10px'}}/>
                            <TextFieldWithSetValueMainOptions
                                label="Кол. поворотов"
                                values={data}
                                setValues={setData}
                                field={'limitNumberTurns'}
                                minWidthXS={'25px'}
                                minWidthSM={'25px'}
                                minWidthPX={'25px'}
                                disabled={!newMaterial}
                            />
                        </Box>}
                    </Stack>}
                </Box>
            </form>
        </DialogContent>
    </div>
}
export default MaterialItemSettings;