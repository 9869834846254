import React, {useState} from 'react';
import UserTable from "../../components/UI/table/common/user/UserTable";
import {BreadcrumbsComponent} from "../../components/UI/breadcrumb/BreadcrumbComponent";
import Box from "@mui/material/Box";
import Footer from "./Footer";
import {backdropClasses, Divider, Input, InputLabel, Paper, Typography} from "@mui/material";
import {Container} from "@mui/system";
import ButtonArrow from "../../components/UI/button/ButtonArrow";
import work from "../../components/Icons/work.png";
import ButtonAdd from "../../components/UI/button/ButtonAdd";
import TextFieldValidation from "../../components/UI/table/customer-detales/TextFieldValidation";
import AttachFileIcon from '@mui/icons-material/AttachFile';

const Work = () => {
    const vacancies = [
        {
            id: 1,
            isQuickly: true,
            date: "11.11.12",
            name: "Наименование вакансии",
            information: "Небольшой текст в две строки, информация о вакансии. Текст в 2 строки с многоточием"
        },
        {
            id: 2,
            isQuickly: false,
            date: "11.11.12",
            name: "Наименование вакансии",
            information: "Небольшой текст в две строки, информация о вакансии. Текст в 2 строки с многоточием"
        },
        {
            id: 3,
            isQuickly: false,
            date: "11.11.12",
            name: "Наименование вакансии",
            information: "Небольшой текст в две строки, информация о вакансии. Текст в 2 строки с многоточием"
        }]
    const [formState, setFormState] = useState({name: "", mail: "", comment: ""})
    const [validationState, setValidationState] = useState({name: "", mail: "", comment: ""})
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
    };
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {

            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            console.log("send")
        }
    }
    return (
        <>
            <Paper sx={{
                width: '100%',
                padding: "40px 0",
                display: 'flex',
                justifyContent: "space-between",

            }}>
                <Container sx={{
                    "&.MuiContainer-root": {
                        maxWidth: "1668px",
                        display: 'flex',
                        flexDirection: "column",
                        gridGap: "100px",
                    },
                }}>
                    <Box>
                        <BreadcrumbsComponent
                            page={"Работа в UPME"}
                            path={[
                                {name: "Главная", href: "/"},
                            ]}/>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "75px",
                            boxSizing: "border-box",
                            backgroundColor: (theme) => theme.palette.background.grey,
                            height: '788px',
                            borderRadius: "16px",
                            marginTop: "10px",
                            paddingLeft: "94px",
                        }}>
                            <Box sx={{
                                height: '624px',
                                width: "659px",
                                borderRadius: "8px",
                                backgroundImage: `url(${work})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",

                            }}/>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: "column",
                                gap: "40px",
                                width: "698px",
                                height: '588px',
                                backgroundColor: "white",
                                borderRadius: "16px",
                                boxShadow: "0px 3px 12px 0px rgba(40, 65, 155, 0.12)",
                                padding: "32px",
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: "column",
                                    gap: "24px"
                                }}>
                                    <Typography sx={{fontSize: 40, fontWeight: '700', lineHeight: 1.2,}}>
                                        В поиске работы?
                                    </Typography>
                                    <Typography sx={{fontSize: 16, lineHeight: 1.2,}}>
                                        Оформляйте заказ самостоятельно из любой точки мира
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: "column",
                                    gap: "16px"
                                }}>
                                    <Box>
                                        <InputLabel htmlFor="input-name">
                                            Введите
                                        </InputLabel>
                                        <TextFieldValidation
                                            id="input-name"
                                            name={"name"}
                                            placeholder={"ФИО"}
                                            value={formState?.name}
                                            setCurrValues={setFormState}
                                            validationError={validationState?.name}
                                            setValidationState={setValidationState}
                                        />
                                    </Box>

                                    <Box>
                                        <InputLabel htmlFor="input-mail">
                                            Почта или номер телефона
                                        </InputLabel>
                                        <TextFieldValidation
                                            id="input-mail"
                                            name={"mail"}
                                            placeholder={"Почта или номер телефона"}
                                            value={formState?.mail}
                                            setCurrValues={setFormState}
                                            validationError={validationState?.mail}
                                            setValidationState={setValidationState}
                                        />
                                    </Box>
                                    <Box>
                                        <InputLabel htmlFor="input-comment">
                                            Комментарий
                                        </InputLabel>
                                        <TextFieldValidation
                                            id="input-comment"
                                            name={"comment"}
                                            placeholder={"Какую работу вы ищете?"}
                                            value={formState?.comment}
                                            setCurrValues={setFormState}
                                            validationError={validationState?.comment}
                                            setValidationState={setValidationState}
                                        />
                                    </Box>
                                    <Box sx={{display: "flex", alignItems: "center", gap: "16px"}}>
                                        <label htmlFor={`input`}
                                               style={{
                                                   cursor: "pointer",
                                                   display: "flex",
                                                   alignItems: "center",
                                                   gap: "8px"
                                               }}>
                                            <AttachFileIcon
                                                fontSize="small"
                                                sx={{
                                                    color: (theme) => theme.palette.primary.dark,
                                                    transform: 'rotate(45deg)',
                                                }}/>
                                            <Typography sx={{color: (theme) => theme.palette.primary.dark}}>
                                                Прикрепить файл</Typography>
                                        </label>
                                        <Input id={`input`} sx={{display: "none"}} type="file"
                                               inputProps={{multiple: true}}
                                               onChange={handleFileSelect}/>
                                        <Typography
                                            sx={{fontSize: "16px"}}>
                                            {selectedFiles.map(el => ` ${el.name}; `)}
                                        </Typography>
                                    </Box>

                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <ButtonAdd onClick={handleValidationData} text={"Отправить заявку"} width={226}
                                               minWidth={184}/>
                                    <Divider sx={{bgcolor: (theme) => theme.palette.secondary.add, width: "80px"}}/>
                                    <Box sx={{
                                        bgcolor: (theme) => theme.palette.secondary.add,
                                        width: "7px",
                                        height: "7px",
                                        borderRadius: "7px",
                                    }}></Box>
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: "column",
                        gridGap: "40px",
                        justifyContent: "center",
                        width: "100%",


                    }}>
                        <Typography sx={{
                            fontSize: "40px", fontWeight: 700, lineHeight: 1,
                            '@media (max-width: 1450px)': {
                                fontSize: "28px",
                            }
                        }}>
                            Актуальные вакансии
                        </Typography>
                        <Box sx={{
                            display: "flex", gap: "16px", flexWrap: "wrap",
                        }}>
                            {vacancies.map((el) => {
                                return <Box
                                    key={el.id}
                                    sx={{
                                        cursor: "pointer",
                                        display: 'flex',
                                        backgroundColor: (theme) => theme.palette.background.grey,
                                        minHeight: '131px',
                                        width: 529,
                                        borderRadius: "16px",
                                        border: (theme) => `transparent 1px solid`,
                                        padding: "16px",
                                        justifyContent: "space-between",
                                        boxSizing: "border-box",
                                        position: "relative",

                                        "&:hover": {
                                            backgroundColor: "white",
                                            border: (theme) => `${theme.palette.primary.main} 1px solid`,
                                            "& .MuiButton-root": {
                                                backgroundColor: "white",
                                                boxShadow: "12px 12px 22px 0px rgba(9, 88, 146, 0.12),0px 12px 24px 0px rgba(44, 112, 163, 0.22)",
                                                "& svg": {
                                                    fill: "#3B8BEC"
                                                }
                                            }
                                        }
                                    }}>
                                    <Box sx={{
                                        paddingRight: "8px",
                                        display: 'flex',
                                        flexDirection: "column",
                                        gap: "8px"
                                    }}>
                                        <Box sx={{display: "flex", alignItems: "center", gap: "16px"}}>
                                            {el.isQuickly &&
                                                <Box sx={{
                                                    borderRadius: "28px",
                                                    backgroundColor: (theme) => theme.palette.primary.main
                                                }}>
                                                    <Typography sx={{
                                                        fontSize: "12px",
                                                        color: "white",
                                                        lineHeight: 1,
                                                        padding: "4px 8px",
                                                    }}>Срочно</Typography></Box>}
                                            <Typography sx={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                lineHeight: 1.2,
                                                color: (theme) => theme.palette.text.light,
                                            }}>
                                                {el.date}
                                            </Typography></Box>

                                        <Typography sx={{
                                            fontSize: "18px",
                                            fontWeight: 700,
                                            lineHeight: 1.2,
                                            color: (theme) => theme.palette.text.main,
                                        }}>
                                            {el.name}
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            lineHeight: 1.2,
                                            color: (theme) => theme.palette.text.main,
                                        }}>
                                            {el.information}
                                        </Typography>

                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "end",
                                        }}>
                                        <ButtonArrow/>
                                    </Box>
                                </Box>
                            })}
                        </Box>
                    </Box>
                </Container>
            </Paper>
            <Footer></Footer>
        </>
    );
};

export default Work;