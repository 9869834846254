import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Step,
    StepLabel,
    Stepper, Typography,
} from "@mui/material";
import React, {useState} from "react";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {Login} from "@mui/icons-material";

const ModalAddJobMetric = ({open, data, onClose, handleSubmit}) => {
    const [activeStep, setActiveStep] = useState(0)
    const materialNames = data.reduce((acc, it) => acc.includes(it.materialName) ? acc : [...acc, it.materialName], [])
    const [selectedMaterialName, setSelectedMaterialName] = useState(materialNames[0])
    const [operations, setOperations] = useState([]);
    const [selectedOperation, setSelectedOperation] = useState({})

    const handleClose = () => {
        onClose()
        setActiveStep(0)
    }
    const handleValidationData = () => {
        handleSubmit({})
        handleClose()
    }
    const submit = () => {
        if (activeStep === 0) {
            const operations = data.filter((it) => it.materialName === selectedMaterialName)
            setOperations(operations)
            setSelectedOperation(operations[0].id)
            setActiveStep(1)
        } else {
            setActiveStep(0)
            handleValidationData()
        }
    }
    return <Dialog open={open} PaperProps={{
        style: {
            minWidth: "800px",
            height: "716px",
        }
    }}>
        <Box style={{
            padding: 40,
            display: "flex",
            flexDirection: "column",
            gap: 16,
            alignItems: "center"
        }}>
            <DialogTitle sx={{
                color: (theme) => theme.palette.text.main,
                margin: "auto",
                padding: 0,
                fontSize: 24,
                fontWeight: 700
            }}>Добавить работу</DialogTitle>
            <Stepper
                alternativeLabel
                style={{width: "100%"}}
                activeStep={activeStep}
            >
                {["Материал", "Наименование операции"].map((label) => (
                    <Step key={label} sx={{
                        "& .MuiStepConnector-root": {
                            top: "20px",
                            left: 'calc(-50% + 30px)',
                            right: "calc(50% + 30px)"
                        }
                    }}>
                        <StepLabel sx={{
                            ".MuiSvgIcon-root": {
                                fontSize: 40,
                                color: "#737478",
                                "&.Mui-active": {
                                    color: "#3B8BEC",
                                }
                            },
                            "& .MuiStepLabel-label": {
                                fontSize: 18,
                                "&.Mui-active": {
                                    fontWeight: 700,
                                }
                            },

                        }}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
        <DialogContent sx={{}}>
            {activeStep === 0
                ?
                <Box
                    style={{width: "100%"}}
                >
                    <RadioGroup
                        defaultValue="female"
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                        value={selectedMaterialName}
                        onChange={(event) => setSelectedMaterialName(event.target.value)}
                    >
                        {materialNames?.map((option) => (
                                <FormControlLabel
                                    value={option}
                                    control={<Radio/>}
                                    label={option}
                                    sx={{
                                        // width: "752px",
                                        height: "36px",
                                        "&:not(:last-of-type)": {
                                            marginBottom: "4px",
                                        },
                                        borderBottom: (theme) => `${theme.palette.primary.stroke} solid 1px`,
                                        "&:hover": {
                                            backgroundColor: (theme) => theme.palette.primary.light
                                        }
                                    }
                                    }

                                />
                            )
                        )}
                    </RadioGroup>
                </Box> : activeStep === 1
                    ?
                    <Box
                        style={{width: "100%"}}
                    >
                        <RadioGroup
                            defaultValue="female"
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                            value={selectedOperation}
                            onChange={(event) => setSelectedOperation(event.target.value)}
                        >
                            {operations?.map((option) => (
                                    <FormControlLabel
                                        value={option.id}
                                        control={<Radio/>}
                                        label={option.metricName}
                                        sx={{
                                            // width: "752px",
                                            height: "36px",
                                            "&:not(:last-of-type)": {
                                                marginBottom: "4px",
                                            },
                                            borderBottom: (theme) => `${theme.palette.primary.stroke} solid 1px`,
                                            "&:hover": {
                                                backgroundColor: (theme) => theme.palette.primary.light
                                            }
                                        }
                                        }

                                    />
                                )
                            )}

                        </RadioGroup>
                    </Box> : activeStep === 2
                        ? <Box></Box> :
                        <Box></Box>}
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <ButtonExit text={"Выйти"} onClick={handleClose}/>
            <ButtonAdd text={"Выбрать"} onClick={submit} disabled={false}/>
        </DialogActions>
    </Dialog>
}
export default ModalAddJobMetric