import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../Constants";

const jobRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/sign-job-names'

export default class SignJobNameService {

    static async getAllOrganization() {
        return axios.get(jobRestUrl);
    }

    static async update(data, id) {
        return await axios.put(jobRestUrl + "/" + id,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async delete(id) {
        await axios.delete(jobRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async create(data) {
        return await axios.post(jobRestUrl,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }
}