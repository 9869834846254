import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Checkbox, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";
import {modelParameterUnit} from "../../../../../data/ModelParameterUnit";
import {orderJobType} from "../../../../../data/OrderJobType";
import UtilityService from "../../../../../API/common/UtilityService";
import ModalAddJob from "./ModalAddJob";
import JobMetricOrganizationService from "../../../../../API/orders/job/JobMetricOrganizationService";

const JobMetricOrganizationTable = ({
                                        data,
                                        setData,
                                        change,
                                        enableEditing,
                                        uploadSignJob,
                                        isRedirectSettings,
                                        isChooseSignJob,
                                        // handleSubmitAddSign,
                                        rowSelection,
                                        setRowSelection,
                                        rowIndexSelection,
                                        setRowIndexSelection,
                                        selectedIds,
                                        setSelectedIds,
                                        isPrice,
                                        isUnit,
                                        baseCurrency,
                                        alternativeCurrency,
                                        isModal,
                                        selectedString,
                                        setSelectedString
                                    }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [currencies, setCurrencies] = useState([])
    const [currentEditRowData, setCurrentEditRowData] = useState({});

    useEffect(() => {
        UtilityService.getCurrencies()
            .then(function (response) {
                setCurrencies(response.data)
            })
    }, []);

    const navigate = useNavigate();

    const redirectSettings = () => {
        navigate('/settings-orders');
    };

    const handleSubmitAddSign = (value) => {
        JobMetricOrganizationService.create(currentEditRowData.metricName, value.currency, currentEditRowData.orderJobType, value.price)
            .then(function (response) {
                console.log(currentEditRowData, response.data)
                setData(prev => prev.map(el => el.metricName === response.data.metricName ? response.data : el))
            })
    }

    const handleChange = (row) => {
        if (row?.added) {
            JobMetricOrganizationService.delete(row.id)
                .then(function (response) {
                    setData(prev => prev.map(el => el.metricName === row.metricName ? {
                        ...row,
                        added: false,
                        basePrice: null,
                        alternativePrice: null
                    } : el))
                })
        } else {
            setCurrentEditRowData(row)
            setModalOpen(true)
        }
    }
    const sortArr = {"CUT": 0, "MILLING": 1, "EDGE": 2, "DRILLING": 3, "PACKAGING": 4}

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: "metricName",
                header: 'Найменование операции',
                accessorKey: 'metricName',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
                Cell: ({cell, row}) => (row.original?.added ?
                    <Link to={`/settings/signJob/${row.original.id}`}>{cell.getValue()}</Link> : cell.getValue()),
            },
            {
                header: `Стоимость: ${baseCurrency}`,
                accessorFn: (row) => row.basePrice?.price,
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? '-' : value.toFixed(2)
                },
            },
            {
                header: `Стоимость: ${alternativeCurrency}`,
                accessorFn: (row) => row.alternativePrice?.price,
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? '-' : value.toFixed(2)
                },
            },
            {
                header: `Тип работ`,
                accessorKey: 'orderJobType',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                Cell: ({cell}) => orderJobType[cell.getValue()],
                sortingFn: (rowA, rowB, columnId) => {
                    return sortArr[rowA.original.orderJobType] > sortArr[rowB.original.orderJobType] ? 1 : -1
                }
            },
            {
                id: "modelParameterUnit",
                header: 'Параметр с модели',
                accessorKey: 'modelParameterUnit',
                enableEditing: false,
                enableGrouping: true,
                minSize: 280,
                Cell: ({cell}) => modelParameterUnit[cell.getValue()],
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ];

        if (isUnit) {
            baseColumns.splice(2, 0, {
                header: 'Ед.изм.',
                accessorFn: (row) => row.unit?.name,
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            });
        }
        if (!isModal) {
            baseColumns.splice(0, 0, {
                header: 'Добавлен',
                accessorKey: 'added',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                Cell: ({cell, row}) => <Checkbox
                    cell={cell}
                    checked={row.original.added}
                    id={data[row.index].id}
                    sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                    onChange={() => handleChange(row.original)}
                    disabled={row.original.blockDelete && row.original.added === true}
                >
                </Checkbox>,
            });
        }
        return baseColumns;

    }, [data, setData, isPrice, isUnit]);

    return (
        <Box sx={{overflow: 'auto'}}>
            <MaterialReactTable
                muiTableContainerProps={{
                    sx: {
                        width: isModal ? "100%" : '75vw',
                        maxHeight: isModal ? "530px":`calc(100vh - 350px)`
                }
                }}
                muiTopToolbarProps={{
                    sx: {
                        "& .MuiBox-root": {
                            padding: "8px 0"
                        }
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        "&:first-of-type": !isModal && {
                            padding: 0,
                            width: 'calc(100% - 1px)',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        },
                        border: "none",
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',
                        },
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"

                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        onClick: () => isModal && setSelectedString(row.original),
                        sx: () => ({
                            height: '36px',
                            cursor: isModal && "pointer",
                            backgroundColor: row.original.id === selectedString?.id ? "#D7E8FA" : "white",
                            opacity: !row.original?.added && !isModal && 0.6,
                            "&:hover& td:after": {
                                display: "none",
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: row.original.id === selectedString?.id ? "#D7E8FA" : '#F5F6FA',
                            },
                        })
                    })
                }}

                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    expanded: false,
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 100},
                    sorting: isModal ?
                        [{id: 'orderJobType', desc: false}] :
                        [{id: 'added', desc: true}, {id: 'orderJobType', desc: false}],
                }}
                onRowSelectionChange={setRowSelection}
                state={{showGlobalFilter: true,}}
                enableMultiRowSelection={false}
                getRowId={(originalRow) => originalRow.id}
                localization={MRT_Localization_RU}
                enableEditing={enableEditing}
                enableToolbarInternalActions={false}
                renderTopToolbarCustomActions={({table}) => {
                    const chooseSignJob = () => {
                        const selectedRows = table.getSelectedRowModel().flatRows;
                        const selectedIds = selectedRows.map((row) => row.getValue('id'));
                        const selectedIndexRows = selectedRows.map((row) => row.index);
                        setSelectedIds(selectedIds);
                        setRowIndexSelection(selectedIndexRows);
                        // setShowConfirmAddSignJob(true)
                    };

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {isRedirectSettings && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectSettings}
                                    variant="contained"
                                >
                                    Вернуться в настройки
                                </Button>}
                                {isChooseSignJob && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={chooseSignJob}
                                    variant="contained"
                                    disabled={table.getSelectedRowModel().rows.length === 0}
                                >
                                    Установить
                                </Button>}
                                {/*{uploadSignJob && <UploadSignJobFileForm*/}
                                {/*    label={'Загрузить операции'}*/}
                                {/*    change={change}*/}
                                {/*    setChange={setChange}*/}
                                {/*/>}*/}
                            </Box>
                        </div>
                    );
                }}
            />

            {!isModal && <ModalAddJob
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                handleSubmit={handleSubmitAddSign}
                currencies={currencies}
            />}
        </Box>
    );
};

export default JobMetricOrganizationTable;