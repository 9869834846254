import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {constantsErrorMessages} from "../../../../../Constants";
import ModalAddEdges from "./ModalAddEdges";
import OrderEdgeController from "../../../../../API/details/OrderEdgeController";
import CellShortenedName from "../../orders/organization/estimate/CellShortenedName";
import {gluing} from "../../../../../data/Gluing";
import ModalAlert from "../ModalAlert";
import ButtonCreate from "../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";

const CustomerEdgesTable = ({
                                data,
                                samples,
                                isError,
                                setData,
                                enableEditing,
                                enableRowSelection,
                                orderId,
                            }) => {
    const rowInitValue = {material: {}, edgeType: Object.entries(gluing)[0][0]}

    const [addEdgeModalOpen, setAddEdgeModalOpen] = useState(false);
    const [alertData, setAlertData] = useState({});

    const [rowSelection, setRowSelection] = useState({});
    const [currentEditRowData, setCurrentEditRowData] = useState(rowInitValue);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [isNew, setIsNew] = useState(true);

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error', open: true, message: constantsErrorMessages[error.response.status] || error.message
        })
    }
    const handleClose = () => {
        setAddEdgeModalOpen(false)
        setCurrentEditRowData(rowInitValue)
        setIsNew(true)
    }

    const handleUpdate = (data) => {
        OrderEdgeController.edit(currentEditRowId, data)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleDeleteDetail = (row) => {
        OrderEdgeController.delete(row.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.id))
            })
            .catch(function (error) {
                handleError(error)
            })
    };

    const handleSubmit = (data) => {
        OrderEdgeController.save(orderId, data)
            .then(function (response) {
                setData(prev => [...prev, response.data])
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleAddEdge = () => {
        setAddEdgeModalOpen(true)
    };

    const handleEditEdge = (row) => {
        setCurrentEditRowData({
            material: {
                articleNumber: row.materialArticleNumber,
                id: row.materialId,
                name: row.materialName
            },
            edgeType: row.edgeType
        })
        setAddEdgeModalOpen(true)
        setCurrentEditRowId(row.id);
        setIsNew(false);
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'Артикул',
                accessorKey: 'materialArticleNumber',
                size: 250,
                Cell: ({cell}) => <CellShortenedName
                    value={cell.getValue()}
                    numberOfCharacters={15}
                />,
            },
            {
                header: 'Найменование', accessorKey: 'materialName', minSize: 600,
            },
            {
                header: 'Тип поклейки', accessorKey: 'edgeType', size: 250,
                Cell: ({cell}) => gluing[cell.getValue()],
            },
        ]

        return baseColumns;

    }, [data]);
    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEditEdge},
        {type: "delete", text: "Удалить", handler: handleDeleteDetail}
    ]

    return (<div>
        <Box
            sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
        >
            <ButtonCreate
                text={samples ? "Добавить кромку в шаблон" : "Добавить кромку в заказ"}
                width={255}
                onClick={handleAddEdge}
            />
        </Box>
        <MaterialReactTable
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": {
                        padding: 0,
                        width: "100%",
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",

                        "&:hover": {
                            backgroundColor: (theme) => theme.palette.primary.light,
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.light
                            },
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={isError ? [] : data}
            rowHeight={13}
            columnSpacing={1}
            onRowSelectionChange={setRowSelection}
            state={{rowSelection, showGlobalFilter: true}}
            enableRowSelection={enableRowSelection}
            getRowId={(originalRow) => originalRow.id}
            selectionPadding={1}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return <MenuItem
                    key={el.type}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })
            }
            localization={MRT_Localization_RU}
            enableRowActions={true}
            enableToolbarInternalActions={false}

        />
        <ModalAddEdges title={isNew ? "Добавить кромку" : "Изменить кромку"}
                       initValue={currentEditRowData}
                       open={addEdgeModalOpen}
                       onClose={handleClose}
                       handleSubmit={isNew ? handleSubmit : handleUpdate}
        />
        <ModalAlert data={alertData} onClose={handleCloseAlert}/>
    </div>);
};

export default CustomerEdgesTable;
