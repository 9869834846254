import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";

const steps = [
    'Материал',
    'Способ поклейки',
];

const ModalEditProductionCosts = ({
                                      open,
                                      onClose,
                                      onSubmit,
                                      initValue
                                  }) => {


    const [formState, setFormState] = useState({quantity: initValue})
    const [validationState, setValidationState] = useState({
        "distancePrev": "", "distanceNext": "", "pointId": "",
    })
    useEffect(() => {
        setFormState({quantity: initValue})
    }, [initValue])

    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit(formState?.quantity)
        }
    }


    return (<Dialog
        open={open}>
        <DialogTitle textAlign="center">Обьём в заказе</DialogTitle>
        <DialogContent style={{paddingBottom: 7}}>
            <FormControl sx={{minWidth: 300}}>
                <TextFieldValidation
                    type={"number"}
                    name={"quantity"}
                    value={formState?.quantity}
                    label="Обьём в заказе"
                    setCurrValues={setFormState}
                    validationError={validationState?.quantity}
                    setValidationState={setValidationState}
                />
            </FormControl>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <ButtonExit text={"Отменить"} onClick={onClose}/>
            <ButtonAdd text={"Выбрать"} onClick={handleValidationData}/>
        </DialogActions>
    </Dialog>)
}
export default ModalEditProductionCosts