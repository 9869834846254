import axios from "axios";
import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";

const jobRestUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/user/job-metric-edge-material'

export default class JobMetricEdge {

    static async getJobMetricEdgeByMaterialId(materialId) {
        return axios.get(jobRestUrl + '/by-material-id-for-catalog'
            + '?materialId=' + materialId);
    }
}