import React, {useEffect, useState} from 'react';
import SuppliersTable from "../../../components/UI/table/orders/organization/suppliers/SuppliersTable";
import SupplierService from "../../../API/orders/warehouse/supplier/SupplierService";

const Suppliers = ({suppliers}) => {
    const [data, setData] = useState([])

    useEffect(() => {
        SupplierService.getAllByOrganization().then(function (response) {
            let data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [])
    return (
        <div>
            <SuppliersTable data={data} setData={setData} suppliers={suppliers}/>
        </div>
    );
};

export default Suppliers;