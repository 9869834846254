import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../Constants";
import {log} from "three/src/nodes/math/MathNode";

const signJobTableUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/sign-jobs'

export default class SignJobService {

    static async get(id) {
        return axios.get(signJobTableUrl + "/by-job-metric-organization-id?jobMetricOrganizationId=" + id);
    }

    static async getByType(id) {
        return axios.get(signJobTableUrl + "/by-job-metric-organization-id-by-type-job?typeJobId=" + id);
    }

    static async getForType(id, signJobType) {
        return axios.get(signJobTableUrl + "/by-job-metric-organization-id-for-type-job?jobMetricOrganizationId=" + id + "&signJobType=" + signJobType);
    }

    static async create(data) {
        const queryString = Object.entries(data).map(([key, value], i) => `${key}=${value}`).join("&")
        return axios.post(`${signJobTableUrl}?${queryString}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data, id) {
        const queryString = Object.entries(data).map(([key, value], i) => `${key}=${value}`).join("&")
        return axios.patch(`${signJobTableUrl}/${id}?${queryString}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(signJobTableUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByMaterialId(materialId) {
        return axios.get(signJobTableUrl + "/by-material-id/" + materialId);
    }
}
