import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const restSupplierUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/suppliers'

export default class SupplierService {

    static async getAllByOrganization() {
        return axios.get(restSupplierUrl + '/all-by-organization');
    }

    static async update(data) {
        return await axios.put(restSupplierUrl + '/' + +data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(restSupplierUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(restSupplierUrl + '/' + id)
    }
}