import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Step,
    StepLabel,
    Stepper,
} from "@mui/material";
import React, {useState} from "react";
import ButtonExit from "../../button/ButtonExit";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import ButtonAdd from "../../button/ButtonAdd";
import CustomerSamples from "../../../../pages/customer/samples/samples/Samples";
import TextFieldValidation from "./TextFieldValidation";

const steps = [
    1,
    2,
    3
];
const modalActions = [{type: "mySamples", name: "Мои шаблоны"}, {type: "systemSamples", name: "Шаблоны системы"}]

const ModalAddBySamples = ({open, onClose, handleSubmit}) => {
    const [activeStep, setActiveStep] = useState(0)
    const [selectedType, setSelectedType] = useState(modalActions[0].type)
    const [selectedSamples, setSelectedSamples] = useState(null)
    const [formState, setFormState] = useState({position: "", name: "", length: "", width: "", quantity: ""})
    const [validationState, setValidationState] = useState({
        position: "",
        name: "",
        length: "",
        width: "",
        quantity: ""
    });

    const handleClose = () => {
        onClose()
        setActiveStep(0)
        setSelectedSamples(null)
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {

            if (value === "") {
                newErrors[name] = "поле не должно быть пустым"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit({
                "position": formState.position,
                "name": formState.name,
                "length": formState.length,
                "width": formState.width,
                "count": formState.quantity,
                "sampleId": selectedSamples.sampleId
            }, selectedType)
            onClose()
        }
    }

    const submit = () => {
        if (activeStep === 2) {
            handleValidationData()
        } else if (activeStep === 1) {
            setFormState({
                position: selectedSamples.detailDto.position,
                name: selectedSamples.detailDto.name,
                length: selectedSamples.detailDto.length,
                width: selectedSamples.detailDto.width,
                quantity: selectedSamples.detailDto.quantity
            })
            setActiveStep(2)
        } else {
            setActiveStep(1)
        }
    }
    return <Dialog open={open} PaperProps={{
        style: {
            minWidth: `${activeStep === 1 ? "1100px" : "800px"}`,
            height: `${activeStep === 0 ? "485px" : "716px"}`,
        }
    }}>
        <Box style={{
            padding: 40,
            display: "flex",
            flexDirection: "column",
            gap: 16,
            alignItems: "center"
        }}>
            <DialogTitle sx={{
                color: (theme) => theme.palette.text.main,
                margin: "auto",
                padding: 0,
                fontSize: 24,
                fontWeight: 700
            }}>Добавить по шаблону</DialogTitle>
            <Stepper
                style={{width: 446}}
                activeStep={activeStep}
            >
                {steps.map((id) => (
                    <Step key={id}>
                        <StepLabel sx={{
                            ".MuiSvgIcon-root": {
                                fontSize: 40,
                                color: "#737478",
                                "&.Mui-active": {
                                    color: "#3B8BEC",
                                }
                            },
                            "& .MuiStepLabel-label": {
                                fontSize: 18,
                                "&.Mui-active": {
                                    fontWeight: 700,
                                }
                            }
                        }}></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
        <DialogContent sx={{
            minWidth: 300,

        }}>
            {activeStep === 0 ? <RadioGroup
                defaultValue="female"
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                value={selectedType}
                onChange={(event) => setSelectedType(event.target.value)}
            >
                {modalActions.map((option) => (
                        <FormControlLabel
                            key={option.type}
                            value={option.type}
                            control={<Radio/>}
                            label={option.name}
                            sx={{
                                margin: 0,
                                height: "36px",
                                "&:not(:last-of-type)": {
                                    marginBottom: "4px",
                                },
                                borderBottom: (theme) => `${theme.palette.primary.stroke} solid 1px`,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.light
                                }
                            }
                            }
                        />
                    )
                )}
            </RadioGroup> : activeStep === 1 ?
                <CustomerSamples
                    modal={true}
                    setSelectedSamples={setSelectedSamples}
                    selectedSamples={selectedSamples}
                    system={selectedType === "systemSamples"}/> :
                <Stack
                    sx={{
                        width: '100%',
                        minHeight: "365px",
                        minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    }}
                >
                    <TextFieldValidation
                        name={"position"}
                        label={"Позиция"}
                        value={formState?.position}
                        setCurrValues={setFormState}
                        validationError={validationState?.position}
                        setValidationState={setValidationState}
                    />
                    <TextFieldValidation
                        name={"name"}
                        label={"Наименование"}
                        value={formState?.name}
                        setCurrValues={setFormState}
                        validationError={validationState?.name}
                        setValidationState={setValidationState}
                    />
                    <TextFieldValidation
                        type="number"
                        name={"length"}
                        label={"Длина"}
                        value={formState?.length}
                        setCurrValues={setFormState}
                        validationError={validationState?.length}
                        setValidationState={setValidationState}
                    />
                    <TextFieldValidation
                        type="number"
                        name={"width"}
                        label={"Ширина"}
                        value={formState?.width}
                        setCurrValues={setFormState}
                        validationError={validationState?.width}
                        setValidationState={setValidationState}
                    />
                    <TextFieldValidation
                        type="number"
                        name={"quantity"}
                        label={"Количество"}
                        value={formState?.quantity}
                        setCurrValues={setFormState}
                        validationError={validationState?.quantity}
                        setValidationState={setValidationState}
                    />
                </Stack>}
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <ButtonExit text={"Выйти"} onClick={handleClose}/>
            <ButtonAdd text={"Выбрать"} onClick={submit} disabled={!selectedSamples && activeStep === 1}/>
        </DialogActions>
    </Dialog>
}
export default ModalAddBySamples