import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {FileUploadSharp} from "@mui/icons-material";
import Box from "@mui/material/Box";
import CutFileService from "../../../../API/cut-file/CutFileService";
import {getFileExtension} from "../../../../utils/common/getFileExtension";

const CutFileTable = ({cuttingMapId}) => {

    const [data, setData] = useState([
        {name: "SELCO", cutFileNameType: "SELCO"},
        {name: "HOLZMA", cutFileNameType: "HOLZMA"},
        {name: "KDT пильный центр", cutFileNameType: "KDT"},
        {name: "KDT ФРС", cutFileNameType: "KDT_PANEL_SAW"},
    ]);

    const downloadFile = useCallback(
        (row) => {
            CutFileService.downloadFile(cuttingMapId, row.original.cutFileNameType)
                .then(function (response) {
                    const fileExtension = getFileExtension(response);
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: fileExtension}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${row.original.nameFile}.${fileExtension}`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [],
    );

    const columns = useMemo(
        () => [
            {
                id: 'cutFileNameType',
                header: 'Тип',
                accessorKey: 'cutFileNameType',
                minSize: 70,
                sx: {
                    p: '0px 0px 0px 0px',
                    m: '0px',
                },
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'left',
                            sx: {
                                p: '0px 0px 0px 15px',
                                m: '0px',
                                justifyContent: 'flex-start',
                            },
                        },
                        size: 5,
                        maxWidth: '5px',
                        sx: {
                            p: '0px 0px 0px 15px',
                            m: '0px',
                            justifyContent: 'flex-start',
                        },
                    },
                }}
                columns={columns}
                data={data}
                enableEditing
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '5px'
                    }}>
                        <Tooltip arrow placement="left" title="Загрузить файл">
                            <IconButton color="success" onClick={() => downloadFile(row)}>
                                <FileUploadSharp/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                }}
                localization={MRT_Localization_RU}
            />
        </>
    );
};

export default CutFileTable;