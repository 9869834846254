import {Button} from "@mui/material";

const ButtonText = ({onClick, text, width, disabled = false, blue}) => {
    return <Button
        onClick={onClick}
        disabled={disabled}
        variant="text"
        sx={{
            color: (theme) => blue ? theme.palette.primary.dark : theme.palette.text.main,
            padding: 0,
            boxShadow: "none",
            textTransform: 'none',
            width: width,
            "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
                boxShadow: "none",
            }
        }}>
        {text}
    </Button>
}
export default ButtonText