import FileService from "../API/orders/orders/organization/FileService";

export const importArray = [
    {
        id: 1,
        helpText: "Импорт групп",
        downloadFile: FileService.downloadGroupFile
    },
    {
        id: 2, helpText: "Импорт базы материалов",
        downloadFile: FileService.downloadMaterialsFile
    },
    {
        id: 3,
        helpText: "Импорт цен поставщиков",
        downloadFile: FileService.downloadSupplierMaterialsFile
    },
    {
        id: 4,
        helpText: "Импорт наименований работ(при совпадении имени данные перезаписываются)",
        downloadFile: FileService.uploadSignJobNameFile
    },
    {
        id: 5,
        helpText: "Импорт наименований затрат",
        downloadFile: FileService.uploadJobMetricOrganizationFile
    },
    {
        id: 6,
        helpText: "Импорт затрат по работам",
        downloadFile: FileService.uploadSignJobFile
    }]