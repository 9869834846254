import React from "react";
import {COLORS} from "../../../../../../Constants";
import Hole from "./Hole";
import Layer from "./Layer";
import LineElement from "./LineElement";
import {noopStopPropagation} from "../../../../../../utils/common/StopPropagation";
import {calcPrevGroove} from "../CanvasCalcPositions/calcPrevGroove";
import * as THREE from "three";

const Detail = ({
                    reaOnly,
                    shape,
                    points,
                    holes,
                    sideHoles,
                    detailData,
                    selectedHole,
                    setSelectedHole,
                    hoveredHole,
                    setHoveredHole,
                    processingHandle,
                    addEdgeHandle,
                    texture,
                    prevInnerContour,
                    ...props
                }) => {

    return (
        <Layer layer={0}>
            <mesh
                scale={props.scale}
                position={[props.position[0], props.position[1], props.position[2] - props.scale * 2]}
                onClick={!reaOnly&&noopStopPropagation}>

                <extrudeGeometry args={[shape, {
                    bevelEnabled: false,
                }]}/>
                <meshBasicMaterial color={"#EAEBEA"} toneMapped={false}/>

                {prevInnerContour?.type === "groove" &&
                    ["TOP", "BOTTOM"].includes(prevInnerContour?.contour?.position) && calcPrevGroove(prevInnerContour?.contour).lines.map((el) => {
                        return <Layer layer={3} key={el.id}>
                            <LineElement
                                width={4}
                                points={[el.line[0], el.line[1]]}
                                color={COLORS[6][800]}
                                dashSize={0.05 / props.scale} gapSize={0.05 / props.scale}
                            />
                        </Layer>
                    })}

                {["TOP", "BOTTOM"].includes(prevInnerContour?.contour?.position) && prevInnerContour?.type === "round" &&
                    <Layer layer={3}><LineElement
                        width={4}
                        points={new THREE.EllipseCurve(+prevInnerContour?.contour.x, +prevInnerContour?.contour.y, +prevInnerContour?.contour.radius, +prevInnerContour?.contour.radius, 2 * Math.PI, false, 0).getPoints(100)}
                        color={COLORS[6][800]}
                        dashSize={0.05 / props.scale} gapSize={0.05 / props.scale}
                    /></Layer>}

                {holes?.map((el, i) => {
                        const isHole = el.name.includes("hole")
                        const border = el.depth === detailData.height ? "solid" : el?.contour.position === "BOTTOM" ? "dash" : "none"
                        const holeColor = el.depth === detailData.height ? "#F3F4F6" : el?.contour.position === "BOTTOM" ? COLORS[6][600] : COLORS[5][600]

                        const data = {
                            id: el.id,
                            name: el.name,
                            x: el.center.x,
                            y: el.center.y,
                            z: props.scale,
                            radius: el.radius,
                            color: holeColor,
                            border: border,
                        }
                        return (
                            isHole && <Hole
                                reaOnly={reaOnly}
                                key={`${el.id}${el.name}`}
                                data={data}
                                processingHandle={processingHandle}
                                hoveredHole={hoveredHole}
                                selectedHole={selectedHole}
                                setSelectedHole={setSelectedHole}
                                setHoveredHole={setHoveredHole}
                                scale={props.scale}/>
                        )
                    }
                )
                }
            </mesh>
        </Layer>
    )
}
export default Detail
