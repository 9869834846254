import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import React, {useEffect, useMemo, useState} from "react";
import Box from "@mui/material/Box";
import ButtonCreate from "../../../../components/UI/button/ButtonCreate";
import GroupMaterialUtil from "../../../../utils/orders/GroupMaterialUtil"
import AddGroupModal from "./AddGroupModal";
import {MaterialReactTable} from "material-react-table";
import MenuItem from "@mui/material/MenuItem";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {constantsErrorMessages} from "../../../../Constants";
import SamplesControllerServiceGroupAdmin from "../../../../API/samples/Admin/SamplesGroupAdmin";
import SamplesControllerServiceGroup from "../../../../API/samples/Customers/SamplesGroup";

const SamplesGroups = ({system}) => {
    const [alertData, setAlertData] = useState({});
    const [change, setChange] = useState(false)
    const [groupMap, setGroupMap] = useState(null);
    const [filterGroup, setFilterGroup] = useState(new Map());
    const [changeFilterGroup, setChangeFilterGroup] = useState(false);

    const rowInitValue = {name: "", currentGroup: null}
    const [data, setData] = useState([]);
    const [dataTree, setDataTree] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [currentEditRowData, setCurrentEditRowData] = useState(rowInitValue);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [isNew, setIsNew] = useState(true);

    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        const api = system ? SamplesControllerServiceGroupAdmin : SamplesControllerServiceGroup
        api.getAll().then((response) => {
            setData(response.data);
            const buildTreeGroups = GroupMaterialUtil.buildTreeGroupsSamples(response.data);
            setDataTree(buildTreeGroups)
            const groupMap = new Map();
            GroupMaterialUtil.addToMap(buildTreeGroups, groupMap)
            setGroupMap(groupMap);
            // setCurrentGroup({})
        }).catch(function (error) {
            handleError(error)
        });
    }, [change]);

    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: constantsErrorMessages[error.response?.status] || error.response?.data.message || error.message
        })
    }
    const handleClose = () => {
        setModalOpen(false)
        setCurrentEditRowData(rowInitValue)
        setIsNew(true)
    }
    const handleEditGroup = (row) => {
        setCurrentEditRowData({name: row.name, currentGroup: row.parentGroup || {}})
        setCurrentEditRowId(row.id);
        setIsNew(false);
        setModalOpen(true)
    };

    const handleAddGroup = (data) => {
        const api = system ? SamplesControllerServiceGroupAdmin : SamplesControllerServiceGroup
        api.save(data).then(() => {
            setChange(!change)
        }).catch(function (error) {
            handleError(error)
        }).finally(handleClose)
    }

    const handleUpdate = (data) => {
        const api = system ? SamplesControllerServiceGroupAdmin : SamplesControllerServiceGroup
        api.edit(currentEditRowId, data)
            .then(function (response) {
                setChange(!change)
            })
            .catch(function (error) {
                handleError(error)
            }).finally(handleClose)
    }

    const handleDeleteGroup = (row) => {
        const api = system ? SamplesControllerServiceGroupAdmin : SamplesControllerServiceGroup
        api.delete(row.id)
            .then(function () {
                setChange(!change)
            })
            .catch(function (error) {
                handleError(error)
            })
    };
    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'Найменование',
                accessorKey: 'name',
                minSize: 600
            },
            {
                header: 'Родительская группа', accessorKey: 'parentGroup.name', minSize: 600,
                Cell: ({cell}) => {
                    {
                        return cell.getValue() || "Группа является корневой"
                    }
                }
            },
        ]
        return baseColumns;
    }, [data]);

    console.log(dataTree)
    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEditGroup},
        {type: "delete", text: "Удалить", handler: handleDeleteGroup}
    ]

    return <Box sx={{padding: "0 30px"}}>
        <BreadcrumbsComponent
            page={"Группы"}
            path={[
                {name: "Главная", href: "/"},
            ]}/>

        <Box
            sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px"}}
        >
            <ButtonCreate
                onClick={setModalOpen}
                variant="contained"
                text={"Cоздать группу"}
            />
        </Box>
        <MaterialReactTable
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "& td:after": {
                        "&:hover": {
                            backgroundColor: "red"
                        }
                    },
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',

                    },
                    "&:first-of-type": {
                        padding: 0,
                        width: "100%",
                        height: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.light,
                        "& .MuiButtonBase-root": {
                            borderRadius: "4px",
                            width: "24px",
                            height: "24px",
                            opacity: 1,
                            margin: 0,
                            padding: 0,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.stroke,
                            }
                        }
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    padding: "10px",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}
            muiTableBodyRowProps={({row}) => {
                return ({
                    sx: () => ({
                        height: '36px',
                        backgroundColor: "white",

                        "&:hover": {
                            backgroundColor: (theme) => theme.palette.primary.light,
                            "& td:after": {
                                display: "none",
                            },
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.light
                            },
                        },
                    })
                })
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: "",
                    size: 3,
                    minSize: 3,
                    grow: false,
                },
                'mrt-row-expand': {
                    size: 3,
                    minSize: 3,
                    grow: false,
                }
            }}
            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                backgroundColor: "red",
                sx: {
                    backgroundColor: "red",
                    paddingTop: "5px",
                    paddingBottom: "0px",
                    "& .MuiCollapse-root": {
                        borderRadius: '12px',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            columns={columns}
            data={dataTree}
            rowHeight={13}
            columnSpacing={1}
            onRowSelectionChange={setRowSelection}
            state={{rowSelection, showGlobalFilter: true}}
            getRowId={(originalRow) => originalRow.id}
            enableExpanding={true}
            getSubRows={(originalRow) => originalRow.subGroups}
            selectionPadding={1}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                return <MenuItem
                    key={el.type}
                    onClick={() => {
                        el.handler(row.original)
                        closeMenu();
                    }}
                    sx={{
                        margin: "0 8px",
                        borderRadius: "4px",
                        "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                    }}
                >
                    {el.text}
                </MenuItem>
            })
            }
            localization={MRT_Localization_RU}
            enableRowActions={true}
            enableToolbarInternalActions={false}

        />
        <AddGroupModal
            title={isNew ? "Добавить группу" : "Изменить группу"}
            dataTree={dataTree}
            groupMap={groupMap}
            open={modalOpen}
            onSubmit={isNew ? handleAddGroup : handleUpdate}
            onClose={handleClose}
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
            changeFilterGroup={changeFilterGroup}
            setChangeFilterGroup={setChangeFilterGroup}
            initValue={currentEditRowData}>

        </AddGroupModal>
    </Box>
}
export default SamplesGroups