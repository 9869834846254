import React, {useCallback, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import CellCheckboxWithUnable from "../../../сheckbox/CellCheckboxWithUnable";
import ModalOpenSetValuesCutOrTurnDetail from "../../../modal/ModalOpenSetValuesCutOrTurnDetail";
import DetailService from "../../../../../API/orders/cutting/detail/DetailService";
import ModalAddDetailOrRequestsCuttingForFiles from "./ModalAddDetailOrRequestsCuttingForFiles";
import FileService from "../../../../../API/orders/orders/organization/FileService";
import ModalCreateOrUpdateDetail from "./ModalCreateOrUpdateDetail";
import DetailUtil from "../../../../../utils/cutting/DetailUtil";
import ErrorModal from "../../../modal/ErrorModal";
import InfoModal from "../../../modal/InfoModal";
import ModalConfirm from "../../../modal/ModalConfirm";
import {useNavigate} from "react-router-dom";

const DetailTable = ({
                         data,
                         setData,
                         isError,
                         unableCut,
                         unableTurn,
                         setUnableCut,
                         setUnableTurn,
                         selectedIds,
                         setSelectedIds,
                         modalStatusOpen,
                         setModalStatusOpen,
                         status,
                         setStatus,
                         typeStatus,
                         setTypeStatus,
                         isColumnMaterial,
                         isColumnOrder,
                         enableGrouping,
                         columnGrouping,
                         expanded,
                         pageSize,
                         warehouse,
                         requestsCuttingId,
                         isGetTXTSELCO,
                         optimizationNumber,
                         optimizationId,
                         change,
                         setChange,
                         isOptimization,
                         materialList,
                         isRedirectOptimizations,
                         isRedirectPlans,
                         isButtonSetStatus,
                         isButtonRunModify,
                         isButtonAddDetail,
                         isAddDetailForFiles,
                         isColumnCut,
                         isColumnTurn,
                         enableRowSelection,
                         enableEditing,
                         hideQuantity
                     }) => {

        const [isFullScreen, setIsFullScreen] = useState(false);

        const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
        const [errorModalOpen, setErrorModalOpen] = useState(false);
        const [infoModalOpen, setInfoModalOpen] = useState(false);

        const [modalAddDetailForFilesOpen, setModalAddDetailForFilesOpen] = useState(false);
        const [isNew, setIsNew] = useState(null);

        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);

        const [titleModalOpenSetValues, setTitleModalOpenSetValues] = useState(null);
        const [labelModalOpenSetValues, setLabelModalOpenSetValues] = useState(null);

        const [showConfirmationModalModified, setConfirmationModalModified] = useState(false);

        const [messageError, setMessageError] = useState(null);
        const [messageInfo, setMessageInfo] = useState(null);
        const [rowSelection, setRowSelection] = useState({});
        const [materialId, setMaterialId] = useState(null);

        const handleDeleteRow = useCallback(
            (row) => {
                if (isOptimization) {
                    DetailService.deleteFromOptimization(row.original.id, optimizationId)
                        .then(function () {
                            data[row.index]['excluded'] = true;
                            setData([...data]);
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                } else {
                    DetailService.delete(row.original.id, warehouse)
                        .then(function () {
                            data.splice(row.index, 1);
                            setData([...data]);
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                }
            },
            [data],
        );

        const setTitleModalOpenMethod = (typeStatus) => {
            return typeStatus === "cut" ?
                'Установть статус кроить' : 'Установть статус поворот';
        };

        const setLabelModalOpenMethod = (typeStatus) => {
            return typeStatus === "cut" ?
                'Установть статус кроить' : 'Установть статус поворот';
        };

        const handleUnableCheckboxCut = (id, status) => {
            DetailService.unableCutOrTurn(id, status, 'cut', warehouse).then(function () {
                handleUnableCut(id, status)
            })
                .catch(function (error) {
                    console.log(error);
                });
        };

        const handleUnableCut = (id, status) => {
            unableCut[id] = status
            setUnableCut(prevState => ({
                ...prevState,
                [id]: status
            }));
        };

        const handleUnableCheckboxTurn = (id, status) => {
            DetailService.unableCutOrTurn(id, status, 'turn', warehouse).then(function () {
                handleUnableTurn(id, status);
            })
                .catch(function (error) {
                    console.log(error);
                });
        };

        const handleUnableTurn = (id, status) => {
            unableTurn[id] = status
            setUnableTurn(prevState => ({
                ...prevState,
                [id]: status
            }));
        };

        const updateStatusByIds = async () => {
            DetailService.updateCutOrTurnByIds(selectedIds, status, typeStatus, warehouse)
                .then(function () {
                })
                .catch(function (error) {
                    console.log(error);
                })
            handleCloseModalStatus();
        }

        const handleCloseModalStatus = () => {
            setModalStatusOpen(false);
        };

        const handleAddDetail = () => {

            setIsNew(true);

            const detail = {
                position: "",
                name: "",
                length: "",
                width: "",
                quantity: 0,
                turn: false,
                cut: true,
                l1: "",
                l2: "",
                w1: "",
                w2: "",
                groove: ""
            };

            setCurrentEditRowData(detail);
            setCreateOrUpdateModalOpen(true);
        };

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row.original);
                setCurrentEditRowId(row.index);
                setCreateOrUpdateModalOpen(true);
                setIsNew(false);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleUpdate = (values) => {
            data[currentEditRowId] = values;
            setData([...data]);
        };

        const handleSubmitUpdateDetail = () => {

            if (!DetailUtil.compareEqual(currentEditRowData, data[currentEditRowId])) {
                const newData = Array.from(data);
                newData[currentEditRowId] = currentEditRowData;

                if (isOptimization) {
                    newData[currentEditRowId].modified = true
                }

                const error = DetailUtil.checkDuplicatePositions(newData, isOptimization);

                if (!error) {
                    if (isOptimization) {
                        DetailService.updateOptimization(currentEditRowData, optimizationId,
                        ).then(function (response) {
                            data[currentEditRowId]['excluded'] = true;
                            setData([...data]);
                            handleCreateNewRow(response.data)
                            handleCloseCreateOrUpdate();
                        })
                            .catch(function (error) {
                                console.log(error);
                            });
                    } else {
                        DetailService.update(currentEditRowData, warehouse,
                        ).then(function (response) {
                            let data = response.data;
                            handleUpdate(data)
                            handleCloseCreateOrUpdate();
                        })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                } else {
                    setMessageError("Одинаковые позиции, но разные детали");
                    setErrorModalOpen(true)
                }
            } else {
                setMessageError("Детали одинаковые");
                setErrorModalOpen(true)
            }
        }

        const downloadFile = () => {
            DetailService.createTXTFileSelco(selectedIds, optimizationNumber)
                .then(function (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', optimizationNumber);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                })
        }

        const handleSubmitCreateDetail = () => {


            const newData = Array.from(data);
            newData.push(currentEditRowData);
            const error = DetailUtil.checkDuplicatePositions(newData, isOptimization);
            if (!error) {
                if (isOptimization) {
                    const currentMaterialId = materialId === null ? materialList[0].id : materialId;
                    DetailService.saveOptimizations(currentEditRowData, optimizationId, currentMaterialId).then(function (response) {
                        let data = response.data;
                        handleCreateNewRow(data)
                        handleCloseCreateOrUpdate();
                        setChange(!change)
                    })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    DetailService.save(currentEditRowData, requestsCuttingId, warehouse).then(function (response) {
                        let data = response.data;
                        handleCreateNewRow(data)
                        handleCloseCreateOrUpdate();
                        setChange(!change)
                    })
                        .catch(function (error) {
                            console.log(error);
                        });
                }

            } else {
                setMessageError("Одинаковые позиции, но разные детали");
                setErrorModalOpen(true)
            }
        }

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
        };

        const handleCloseCreateOrUpdate = () => {
            setCurrentEditRowData(null);
            setCurrentEditRowId(null);
            setIsNew(null);
            setCreateOrUpdateModalOpen(false);
            setMaterialId(null)
        };

        /*const handleSubmitCreateTxtFileDetailsSelco = () => {
            DetailService.createTXTFileSelco(selectedIds, optimizationNumber,
            ).then(function () {
                setMessageInfo("TXT файл успешно создан");
                setInfoModalOpen(true);
                setConfirmationModalModified(false);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }
*/
        const handleSubmitModify = () => {
            DetailService.modifyOptimizations(optimizationId,
            ).then(function () {
                setInfoModalOpen(true);
                setMessageInfo("Модификция прошла успешна");
                setChange(!change)
                setConfirmationModalModified(false);
            })
                .catch(function (error) {
                    console.log(error);
                });

        }

        const navigate = useNavigate();

        const redirectOptimizations = () => {
            navigate('/optimizations');
        };

        const redirectPlans = () => {
            navigate('/plans');
        };

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'position',
                    header: 'Позиция',
                    accessorKey: 'position',
                    minSize: 70,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'name',
                    header: 'Наименование',
                    accessorKey: 'name',
                    minSize: 60,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'length',
                    header: 'W',
                    accessorKey: 'length',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'width',
                    header: 'H',
                    accessorKey: 'width',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'l1',
                    header: 'l1',
                    accessorKey: 'l1',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'l2',
                    header: 'l2',
                    accessorKey: 'l2',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'w1',
                    header: 'w1',
                    accessorKey: 'w1',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'w2',
                    header: 'w2',
                    accessorKey: 'w2',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    id: 'groove',
                    header: 'Паз',
                    accessorKey: 'groove',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                },
                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableHiding: false,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                },
            ];

            if (!hideQuantity) {
                baseColumns.splice(1, 0, {
                    id: 'quantity',
                    header: 'кол.',
                    accessorKey: 'quantity',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
                    }}>{cell.getValue()}</span>,
                });
            }

            if (isColumnCut) {
                baseColumns.splice(1, 0, {
                    id: 'cut',
                    header: 'Кроить',
                    accessorKey: 'cut',
                    size: 8,
                    enableEditing: false,
                    filterVariant: 'multi-select',
                    filterSelectOptions: FilterUtil.statusList,
                    filterFn: (row, id, filterValue) => {
                        return FilterUtil.checkStatus(row.getValue(id), filterValue)
                    },
                    Cell: ({cell, row}) => <CellCheckboxWithUnable
                        id={row.id}
                        unable={unableCut}
                        setUnable={handleUnableCheckboxCut}
                        disabled={row.original.excluded}
                    >
                    </CellCheckboxWithUnable>,
                });
            }

            if (isColumnTurn) {
                baseColumns.splice(2, 0, {
                    id: 'turn',
                    header: 'Поворот',
                    accessorKey: 'turn',
                    size: 8,
                    enableEditing: false,
                    filterVariant: 'multi-select',
                    filterSelectOptions: FilterUtil.statusList,
                    filterFn: (row, id, filterValue) => {
                        return FilterUtil.checkStatus(row.getValue(id), filterValue)
                    },
                    Cell: ({cell, row}) => <CellCheckboxWithUnable
                        id={row.id}
                        unable={unableTurn}
                        setUnable={handleUnableCheckboxTurn}
                        disabled={row.original.excluded}
                    >
                    </CellCheckboxWithUnable>,
                });
            }

            if (isColumnMaterial) {
                baseColumns.splice(14, 0, {
                    id: 'materialName',
                    header: 'материал',
                    accessorKey: 'name',
                    minSize: 60,
                    maxSize: 120,
                });
            }

            if (isColumnOrder) {
                baseColumns.splice(isColumnMaterial ? 15 : 14, 0, {
                    id: 'orderNumber',
                    header: 'Заказ',
                    accessorKey: 'orderNumber',
                    minSize: 25,
                    maxSize: 30,
                    Cell: ({cell, row}) => <span style={{
                        color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : 'black')
                    }}>{cell.getValue()}</span>,
                });
            }

            return baseColumns;

        }, [data, setData, isColumnMaterial, isColumnOrder]);

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    isFullScreen={isFullScreen}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                            material: isColumnMaterial,
                            order: isColumnOrder,
                        },
                        isFullScreen: false,
                        density: "compact",
                        grouping: columnGrouping,
                        expanded: expanded,
                        pagination: {pageIndex: 0, pageSize: pageSize},
                        sorting: [{id: 'position', desc: false}],
                    }}

                    enableEditing={enableEditing}
                    enableGrouping={enableGrouping}
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}
                    enableRowSelection={enableRowSelection}
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                            <Tooltip arrow placement="left" title="Редактировать">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => handleUpdateModel(row)}>
                                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Удалить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                        onClick={() => handleDeleteRow(row)}>
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        const setStatus = (typeStatus) => {
                            setModalStatusOpen(true)
                            setTypeStatus(typeStatus)
                            setTitleModalOpenSetValues(setTitleModalOpenMethod(typeStatus))
                            setLabelModalOpenSetValues(setLabelModalOpenMethod(typeStatus))
                            const selectedRows = table.getSelectedRowModel().flatRows;
                            const selectedIds = selectedRows.map((row) => row.getValue('id'));
                            setSelectedIds(selectedIds);
                        };

                        const createTxtFileDetailsSelco = () => {
                            const selectedRows = table.getSelectedRowModel().flatRows;
                            const selectedIds = selectedRows.map((row) => row.getValue('id'));
                            setSelectedIds(selectedIds);
                            downloadFile();
                        };

                        const runModify = () => {
                            setConfirmationModalModified(true);
                        };

                        return (
                            <div>
                                <Box
                                    sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                    {isRedirectOptimizations && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectOptimizations}
                                        variant="contained"
                                    >
                                        Оптимизации
                                    </Button>}
                                    {isRedirectPlans && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectPlans}
                                        variant="contained"
                                    >
                                        Планы
                                    </Button>}
                                    {isError && <Button
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        sx={{
                                            animation: 'blinking 1s infinite',
                                            WebkitAnimation: 'blinking 1s infinite',
                                            '@keyframes blinking': {
                                                '0%': {backgroundColor: '#fff'},
                                                '50%': {backgroundColor: '#f00'},
                                                '100%': {backgroundColor: '#fff'},
                                            },
                                        }}
                                    >
                                        Ошибка позиции
                                    </Button>}
                                    {isButtonAddDetail && <Button
                                        size="small"
                                        color="success"
                                        onClick={handleAddDetail}
                                        variant="contained"
                                    >
                                        Добавить деталь
                                    </Button>}
                                    {!isOptimization && isAddDetailForFiles && <Button
                                        size="small"
                                        color="success"
                                        onClick={() => setModalAddDetailForFilesOpen(true)}
                                        variant="contained"
                                    >
                                        Добавить детали из файла
                                    </Button>}
                                    {isButtonSetStatus && <Button
                                        size="small"
                                        color="success"
                                        disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                        onClick={() => setStatus('cut')}
                                        variant="contained"
                                    >
                                        Установить статус кроить
                                    </Button>}
                                    {isButtonSetStatus && <Button
                                        size="small"
                                        color="success"
                                        disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                        onClick={() => setStatus('turn')}
                                        variant="contained"
                                    >
                                        Установить статус поворот
                                    </Button>}
                                    {isButtonRunModify && <Button
                                        size="small"
                                        color="success"
                                        onClick={() => runModify()}
                                        variant="contained"
                                    >
                                        Модифицировать
                                    </Button>}
                                    {isGetTXTSELCO && <Button
                                        size="small"
                                        color="success"
                                        disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                        onClick={() => createTxtFileDetailsSelco()}
                                        variant="contained"
                                    >
                                        Создать TXT файл
                                    </Button>}
                                </Box>
                            </div>
                        );
                    }}

                />
                <ModalOpenSetValuesCutOrTurnDetail
                    title={titleModalOpenSetValues}
                    label={labelModalOpenSetValues}
                    modalOpen={modalStatusOpen}
                    handleClose={handleCloseModalStatus}
                    setValue={setStatus}
                    handleSubmit={updateStatusByIds}
                />
                <ModalAddDetailOrRequestsCuttingForFiles
                    title={'Добавить детали из файла'}
                    labelForm={'Загрузить файлы деталей'}
                    modalOpen={modalAddDetailForFilesOpen}
                    handleClose={() => setModalAddDetailForFilesOpen(false)}
                    requestsCuttingId={requestsCuttingId}
                    requestsCutting={true}
                    change={change}
                    setChange={setChange}
                    upload={FileService.uploadDetailFiles}
                />
                <ModalCreateOrUpdateDetail
                    open={createOrUpdateModalOpen}
                    onClose={handleCloseCreateOrUpdate}
                    title={isNew ? 'Создать деталь' : 'Редактировать деталь'}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    labelButtonSSubmit={isNew ? 'Создать' : 'Обновить'}
                    handleSubmit={isNew ? handleSubmitCreateDetail : handleSubmitUpdateDetail}
                    isOptimization={isOptimization}
                    materialList={materialList}
                    setMaterialId={setMaterialId}
                />
                <ErrorModal
                    open={errorModalOpen}
                    setOpen={setErrorModalOpen}
                    message={messageError}
                />
                <InfoModal
                    open={infoModalOpen}
                    setOpen={setInfoModalOpen}
                    message={messageInfo}
                />
                <ModalConfirm
                    title={"Подтвердить модификацию?"}
                    modalOpen={showConfirmationModalModified}
                    handleClose={() => setConfirmationModalModified(false)}
                    handleSubmit={handleSubmitModify}
                    action={"Подтвердить"}/>
            </div>
        );
    }
;

export default DetailTable;