import React, {useEffect, useState} from 'react';

import {Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import {TabContext, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import JobMetricCloudTable from "./JobMetricCloudTable";
import JobMetricCutting from "../../../../../API/material-cloud/common/JobMetricCutting";
import JobMetricEdge from "../../../../../API/material-cloud/common/JobMetricEdge";
import JobMetricMilling from "../../../../../API/material-cloud/common/JobMetricMilling";
import JobMetricDrilling from "../../../../../API/material-cloud/common/JobMetricDrilling";
import JobMetricPacking from "../../../../../API/material-cloud/common/JobMetricPacking";

const JobMetricCloudModul = ({id}) => {
    const [value, setValue] = useState('0');
    const [dataJobMetricCutting, setDataJobMetricCutting] = useState([]);
    const [dataJobMetricEdge, setDataJobMetricEdge] = useState([]);
    const [dataJobMetricMilling, setDataJobMetricMilling] = useState([]);
    const [dataJobMetricDrilling, setDataJobMetricDrilling] = useState([]);
    const [dataJobMetricPacking, setDataJobMetricPacking] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        JobMetricCutting.getJobMetricCuttingByMaterialId(id).then(function (response) {
            let data = response.data;
            setDataJobMetricCutting(data);
        })
            .catch(function (error) {
                console.log(error);
            });
        JobMetricEdge.getJobMetricEdgeByMaterialId(id).then(function (response) {
            let data = response.data;
            setDataJobMetricEdge(data);
        })
            .catch(function (error) {
                console.log(error);
            });
        JobMetricMilling.getJobMetricMillingByMaterialId(id).then(function (response) {
            let data = response.data;
            setDataJobMetricMilling(data);
        })
            .catch(function (error) {
                console.log(error);
            });
        JobMetricDrilling.getJobMetricDrillingByMaterialId(id).then(function (response) {
            let data = response.data;
            setDataJobMetricDrilling(data);
        })
            .catch(function (error) {
                console.log(error);
            });
        JobMetricPacking.getJobMetricPackingByMaterialId(id).then(function (response) {
            let data = response.data;
            setDataJobMetricPacking(data);
        })
            .catch(function (error) {
                console.log(error);
            });

    }, []);
    return (
        <div>
            {<Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Box sx={{flexGrow: 1}}>
                    <TabContext value={value}>
                        <Box sx={{
                            borderBottom: 1, borderColor: 'divider'
                        }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Раскрой" value='0'/>
                                {<Tab label="Фрезерование" value='1'/>}
                                {<Tab label="Кромление" value='2'/>}
                                {<Tab label="Сверление" value='3'/>}
                                {<Tab label="Упаковка" value='4'/>}
                            </Tabs>
                        </Box>
                        <TabPanel value='0'>
                            <JobMetricCloudTable
                                data={dataJobMetricCutting}
                                setData={setDataJobMetricCutting}
                                isUnit={true}
                                isCutting={true}
                            />
                        </TabPanel>
                        <TabPanel value='1'>
                            <JobMetricCloudTable
                                data={dataJobMetricMilling}
                                setData={setDataJobMetricMilling}
                                isUnit={true}
                                isCutting={true}
                            />
                        </TabPanel>
                        <TabPanel value='2'>
                            <JobMetricCloudTable
                                data={dataJobMetricEdge}
                                setData={setDataJobMetricEdge}
                                isUnit={true}
                                isCutting={true}
                            />
                        </TabPanel>
                        <TabPanel value='3'>
                            <JobMetricCloudTable
                                data={dataJobMetricDrilling}
                                setData={setDataJobMetricDrilling}
                                isUnit={true}
                                isCutting={true}
                            />
                        </TabPanel>
                        <TabPanel value='4'>
                            <JobMetricCloudTable
                                data={dataJobMetricPacking}
                                setData={setDataJobMetricPacking}
                                isUnit={true}
                                isCutting={true}
                            />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Box>}
        </div>
    );
};

export default JobMetricCloudModul;