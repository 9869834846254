import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../../Constants";

const samplesControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/customers/detail-samples'

export default class SamplesControllerService {

    static async getAll(pageNumber, pageSize, group) {
        const groupId = (group && typeof group.id === 'number') ? group.id : null;
        const params = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            group: groupId,
        };

        const queryString = Object.keys(params)
            .filter(key => params[key] !== undefined && params[key] !== null)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');

        return axios.get(`${samplesControllerRestUrl}?${queryString}`);
    }

    static async getById(id) {
        return axios.get(`${samplesControllerRestUrl}/${id}`);
    }

    static async save(data) {
        return await axios.post(`${samplesControllerRestUrl}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(sampleId, data) {
        return await axios.put(`${samplesControllerRestUrl}/${sampleId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(sampleId) {
        await axios.delete(`${samplesControllerRestUrl}/${sampleId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}