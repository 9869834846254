import {
    Box,
    Dialog,
    DialogActions,
    DialogContent, DialogTitle, Step, StepLabel, Stepper,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import ButtonExit from "../../button/ButtonExit";
import ButtonAdd from "../../button/ButtonAdd";
import SignJobService from "../../../../API/orders/job/SignJobService";
import SettingsSignJobTable from "./SettingsSignJobTable";
import JobMetricOrganizationTable from "../prodaction/jobmetricorganization/JobMetricOrganizationTable";

const AddSignJobMetricModal = ({
                                   open, onClose, handleSubmit, title, jobMetric, baseCurrency,
                                   alternativeCurrency
                               }) => {
    const [activeStep, setActiveStep] = useState(0)
    const [selectedJobMetricOrganization, setSelectedJobMetricOrganization] = useState({})
    const [data, setData] = useState([])
    const [selectedString, setSelectedString] = useState({})

    const handleClose = () => {
        onClose()
        setActiveStep(0)
        setSelectedJobMetricOrganization({})
        setSelectedString({})
    }
    const submit = () => {
        if (activeStep === 0) {
            SignJobService.getForType(selectedJobMetricOrganization.id, "SALARY")
                .then(function (response) {
                    setData(response?.data)
                    setActiveStep(1)
                })
                .catch(function (error) {
                    console.log(error)
                })

        } else {
            handleSubmit(selectedString?.id)
            handleClose()
        }
    }
    return <Dialog open={open} PaperProps={{
        style: {
            minWidth: activeStep === 0 ? "1580px" : "1400px",
            height: "1000px",
        }
    }}>
        <Box style={{
            padding: 40,
            display: "flex",
            flexDirection: "column",
            gap: 16,
            alignItems: "center"
        }}>
            <DialogTitle sx={{
                color: (theme) => theme.palette.text.main,
                margin: "auto",
                padding: 0,
                fontSize: 24,
                fontWeight: 700
            }}>{title}</DialogTitle>
            <Stepper
                alternativeLabel
                style={{width: "100%"}}
                activeStep={activeStep}
            >
                {["Наименование работ", "Метрики работ"].map((label) => (
                    <Step key={label} sx={{
                        "& .MuiStepConnector-root": {
                            top: "20px",
                            left: 'calc(-50% + 30px)',
                            right: "calc(50% + 30px)"
                        }
                    }}>
                        <StepLabel sx={{
                            ".MuiSvgIcon-root": {
                                fontSize: 40,
                                color: "#737478",
                                "&.Mui-active": {
                                    color: "#3B8BEC",
                                }
                            },
                            "& .MuiStepLabel-label": {
                                fontSize: 18,
                                "&.Mui-active": {
                                    fontWeight: 700,
                                }
                            },

                        }}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
        <DialogContent sx={{}}>
            {activeStep === 0
                ?
                <Box
                    style={{width: "100%"}}
                >
                    <JobMetricOrganizationTable
                        data={jobMetric}
                        isModal={true}
                        selectedString={selectedJobMetricOrganization}
                        setSelectedString={setSelectedJobMetricOrganization}
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}/>
                </Box> :
                <Box
                    style={{width: "100%"}}
                >
                    <SettingsSignJobTable
                        data={data}
                        isModal={true}
                        selectedString={selectedString}
                        setSelectedString={setSelectedString}/>
                </Box>}
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <ButtonExit text={"Выйти"} onClick={handleClose}/>
            <ButtonAdd text={"Выбрать"} onClick={submit}
                       disabled={(activeStep === 0 && !selectedJobMetricOrganization.id) || (activeStep === 1 && !selectedString.id)}/>
        </DialogActions>
    </Dialog>
}
export default AddSignJobMetricModal