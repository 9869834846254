import React from "react";
import Layer from "./Layer";
import * as THREE from "three";
import LineElement from "./LineElement";

const Hole = ({
                  reaOnly,
                  data,
                  hoveredHole,
                  hoveredSideHole,
                  setSelectedHole,
                  setHoveredHole,
                  processingHandle,
                  side
              }) => {
    const isHovered = hoveredHole?.id === data.id || hoveredSideHole?.id === data.id
    const onclickHoleHandle = () => {
        processingHandle("editHole", data.id)
        setSelectedHole(data.id)
    }
    return (
        <Layer layer={2}>

            <mesh
                onClick={(event) => !reaOnly && (event.stopPropagation(), onclickHoleHandle())}
                onPointerOver={(event) => !reaOnly && (event.stopPropagation(), setHoveredHole(`${data.id}${data.name}`))}
                onPointerOut={(event) => !reaOnly && (event.stopPropagation(), setHoveredHole(null))}
                position={[data.x, data.y, data.z]}>

                {isHovered && <circleGeometry args={[hoveredHole ? data.radius + 10 : data.radius + 0.1]}/>}
                <meshBasicMaterial
                    toneMapped={false}
                    color={"#D7E8FA"}/>
                <mesh>
                    {data.border !== "none" && <circleGeometry args={[data.radius]}/>}
                    <meshBasicMaterial color={"black"} toneMapped={false}/>
                    <mesh>
                        {!side && <circleGeometry
                            args={[data.border === "none" ? data.radius : data.radius - 0.5]}/>}
                        <meshBasicMaterial color={data.color} toneMapped={false}/>
                    </mesh>
                </mesh>
            </mesh>

        </Layer>
    )
}
export default Hole
