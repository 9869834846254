import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const groupMaterialRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/group-materials'

export default class GroupMaterialService {

    static async getAllForSettings() {
        return axios.get(groupMaterialRestUrl + '/get-all-by-organization-for-settings');
    }

    static async getAllByOrganization(typeMaterial, subTypeMaterial) {
        const params = {typeMaterial: typeMaterial, subTypeMaterial: subTypeMaterial}

        const queryString = Object.keys(params)
            .filter(key => params[key] !== undefined && params[key] !== null)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');

        return axios.get(groupMaterialRestUrl + '/get-all-by-organization?' + queryString);
    }

    static async update(id, data) {
        const parent = data.parentId ? `?parentId=${data.parentId}` : ''
        return await axios.put(`${groupMaterialRestUrl}/${id}${parent}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        const parent = data.parentId ? `?parentId=${data.parentId}` : ''
        return await axios.post(`${groupMaterialRestUrl}${parent}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(groupMaterialRestUrl + '/' + id);
    }
}