export const INIT_SCALE = 0.005;
export const INIT_SCALE_PREV_DETAIL = 0.011;

export const calcNewScale = (canvas, detailData, prevDetail) => {
    const {clientHeight, clientWidth} = canvas;
    const scale = prevDetail ? INIT_SCALE_PREV_DETAIL : INIT_SCALE

    const lengthDetailRatio = clientWidth / detailData.length
    const widthDetailRatio = clientHeight / detailData.width

    return lengthDetailRatio < widthDetailRatio
        ? lengthDetailRatio * scale
        : widthDetailRatio * scale;
}

