import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip} from "@mui/material";
import React, {useState} from "react";
import ButtonExit from "../../../button/ButtonExit";
import UploadFilesRequestsForm from "../../../../../pages/organization/orders/estimate/UploadFilesRequestsForm";
import FileService from "../../../../../API/orders/orders/customer/FileService";

const ModalImport = ({open, onClose}) => {

    const [change, setChange] = useState(false);

    return <Dialog open={open}>
        <DialogTitle textAlign="center">Импорт</DialogTitle>
        <DialogContent sx={{minWidth: 300}}>
            <UploadFilesRequestsForm
                orderId={null}
                detail={true}
                change={change}
                setChange={setChange}
                calculationRequestsCutting={false}
                uploadData={{
                    id: 1,
                    helpText: "Импорт деталей из файла"
                }}
            />
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <ButtonExit text={"Закрыть"} onClick={onClose}/>
        </DialogActions>
    </Dialog>
}
export default ModalImport