import React, {useEffect, useState} from 'react';
import AddressOrganizationService from "../../../API/organization/AddressOrganizationService";
import AddressOrganizationTable from "../../../components/UI/table/settings/AddressOrganizationTable";

const AddressOrganization = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        AddressOrganizationService.getAllOrganizations().then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <AddressOrganizationTable
                data={data}
                setData={setData}
            />
        </div>
    );
};

export default AddressOrganization;