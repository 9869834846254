import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent, DialogTitle, FormControlLabel,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import TextFieldValidation from "../customer-detales/TextFieldValidation";
import ButtonExit from "../../button/ButtonExit";
import ButtonAdd from "../../button/ButtonAdd";

const TypeJobOrganizationModal = ({open, onClose, handleSubmit, initValue, title}) => {
    const [formState, setFormState] = useState(initValue)
    const [validationState, setValidationState] = useState({name: "", outputPriority: ""})

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])

    const handleClose = () => {
        setValidationState({name: "", outputPriority: ""})
        onClose()
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (name === "outputPriority" && !Number.isInteger(+value)) {
                newErrors[name] = "Введите целое число"
            } else if (name!=="workers" && value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState)
            handleClose()
        }
    }
    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <TextFieldValidation
                    name={"name"}
                    value={formState?.name}
                    label="Наименование"
                    setCurrValues={setFormState}
                    validationError={validationState?.name}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    type={"number"}
                    name={"outputPriority"}
                    value={formState?.outputPriority}
                    label="Приоритет"
                    setCurrValues={setFormState}
                    validationError={validationState?.outputPriority}
                    setValidationState={setValidationState}
                />
                {initValue.id && <FormControlLabel control={<Checkbox
                    checked={formState.active}
                    onChange={() => setFormState(value => ({
                        ...value,
                        "active": !value.active
                    }))}
                />} label="Aктивная"/>}
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={"Выбрать"} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
}
export default TypeJobOrganizationModal