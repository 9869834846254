import Box from "@mui/material/Box";
import {BreadcrumbsComponent} from "../../components/UI/breadcrumb/BreadcrumbComponent";
import React, {useEffect, useState} from "react";
import UploadGroupOfMaterialFileForm from "../../components/UI/cloud/UploadGroupOfMaterialFileForm";
import ModelHeadersTable from "../../components/UI/table/orders/organization/header/ModelHeadersTable";
import AttributesTable from "../../components/UI/table/orders/organization/attributes/AttributesTable";
import JobMetricOrganization from "./settings/JobMetricOrganization";
import JobNameTable from "../../components/UI/table/orders/organization/job/JobNameTable";
import Workers from "../organization/orders/worker/Workers";
import ModelHeaders from "../organization/orders/ModelHeaders";
import PackagingUnit from "../organization/model-estimate/packaging/PackagingUnit";
import PackagingSet from "../organization/model-estimate/packaging/PackagingSet";
import Models from "../organization/model-estimate/models/Models";
import BasicModels from "../organization/model-estimate/models/BasicModels";
import Customers from "../organization/orders/Customers";
import TypeJobOrganization from "./settings/TypeJobOrganization";
import AddressOrganization from "./settings/AddressOrganization";
import {EditLocalStoragePagesData} from "../../utils/EditLocalStoragePagesData";
import {useLocation} from "react-router-dom";
import TreeViewSettings from "./settings/TreeViewSettings";
import Suppliers from "../organization/orders/Suppliers";
import WorkerService from "../../API/orders/job/WorkerService";
import MaterialCloudSuppliers from "../../API/material-cloud/common/MaterialCloudSuppliers";
import {importArray} from "../../data/ImportArray";
import MaterialGroups from "./settings/MaterialGroups";
import MaterialPageItemModule from "../../components/UI/modul/MaterialPageItemModule";

const Settings = () => {
    const pathname = useLocation().pathname
    const profileStorage = JSON.parse(localStorage.getItem("profile"))
    const [activeSetting, setActiveSetting] = useState("profile")
    const [expandedItems, setExpandedItems] = React.useState([]);

    const [workers, setWorkers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    const handleExpandedItemsChange = (event, itemIds) => {
        setExpandedItems(itemIds);
    };

    let userRoles = null
    profileStorage?.userRoles.find(el => el.name === "SYSTEM_ADMIN" || el.name === "ROLE_ADMIN"
        ? userRoles = {
            type: "admin",
            name: "Настройки системы"
        } : el.name === "ROLE_ORGANIZATION_SUPER_USER" || el.name === "ROLE_ORGANIZATION_USER_SETTINGS"
            ? userRoles = {
                type: "organization",
                name: "Настройки организации"
            } : el.name === "ROLE_CUSTOMER_USER_SETTINGS"
                ? userRoles = {type: "customer", name: "Настройки заказчика"} : null)

    window.onunload = function () {
        EditLocalStoragePagesData(pathname, "settingName", activeSetting)
        EditLocalStoragePagesData(pathname, "expanded", expandedItems)
    }
    useEffect(() => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const {settingName, expanded} = pagesData[pathname]
        setActiveSetting(settingName)
        setExpandedItems(expanded)

        WorkerService.getWorkers().then(function (response) {
            setWorkers(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });

        MaterialCloudSuppliers.get().then(function (response) {
            setSuppliers(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent page={"Настройки"} path={[{name: "Главная", href: "/"}]}/>
            <Box sx={{display: "flex", gap: "50px"}}>
                <Box sx={{minHeight: 352, minWidth: 300}}>
                    <TreeViewSettings
                        activeSetting={activeSetting}
                        setActiveSetting={setActiveSetting}
                        expandedItems={expandedItems}
                        handleExpandedItemsChange={handleExpandedItemsChange}
                        userRoles={userRoles}/>
                </Box>
                <Box>
                    {(() => {
                        switch (activeSetting) {
                            case "profile":
                                return <Box>Тут будут настройки профиля</Box>;
                            //Склад
                            case "imports":
                                return (
                                    <Box sx={{display: "flex", flexDirection: "column", gap: "5px"}}>
                                        {importArray.slice(0, 3).map(el => {
                                            return <UploadGroupOfMaterialFileForm
                                                key={el.id}
                                                label={'Отправить'}
                                                uploadData={el}
                                                multiple={false}
                                            />
                                        })}
                                    </Box>
                                );
                            case "headers":
                                return <ModelHeadersTable/>;
                            case "attributes":
                                return <AttributesTable/>;
                            //Материалы
                            case "materialGroups":
                                return <MaterialGroups/>;
                            case "material":
                                return <MaterialPageItemModule
                                    refMaterial={true}
                                    isAddMaterial={true}
                                    pageSize={30}
                                />;
                            //Работы
                            case "listJob":
                                return <JobMetricOrganization/>;
                            case "listJobName":
                                return <JobNameTable/>;
                            case "listTypeJob":
                                return <TypeJobOrganization workers={workers}/>;
                            case "importJob":
                                return (
                                    <Box sx={{display: "flex", flexDirection: "column", gap: "5px"}}>
                                        {importArray.slice(3, 7).map(el => {
                                                return <UploadGroupOfMaterialFileForm
                                                    key={el.id}
                                                    label={'Отправить'}
                                                    uploadData={el}
                                                    multiple={false}
                                                />
                                            }
                                        )}
                                    </Box>
                                )
                                    ;
                            //Модели
                            case
                            "packagingUnits"
                            :
                                return <PackagingUnit/>;
                            case
                            "packagingSets"
                            :
                                return <PackagingSet/>;
                            case
                            "modelHeaders"
                            :
                                return <ModelHeaders/>;
                            case
                            "basicModels"
                            :
                                return <BasicModels/>;
                            case
                            "models"
                            :
                                return <Models/>;
                            //Работники
                            case
                            "workers"
                            :
                                return <Workers/>;
                            //Заказчики
                            case
                            "organizationCustomers"
                            :
                                return <Customers/>;
                            //Поставщики
                            case
                            "organizationSuppliers"
                            :
                                return <Suppliers suppliers={suppliers}/>;
                            //АдресаОрганизации
                            case
                            "addressOrganizationList"
                            :
                                return <AddressOrganization/>;
                            default:
                                return <Box/>;
                        }
                    })()}
                </Box>
            </Box>
        </Box>
    );
};

export default Settings;