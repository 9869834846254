import {
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import TextFieldValidation from "../customer-detales/TextFieldValidation";
import ButtonExit from "../../button/ButtonExit";
import ButtonAdd from "../../button/ButtonAdd";
import MenuItem from "@mui/material/MenuItem";

const SignJobModal = ({
                          open, onClose, handleSubmit, initValue, title, dataForSelects
                      }) => {
    const [formState, setFormState] = useState(initValue)
    const [validationState, setValidationState] = useState({price: "", laborIntensity: ""})

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])

    const handleClose = () => {
        onClose()
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)
        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState)
            handleClose()
        }
    }
    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <TextFieldValidation
                    select
                    name={"signJobNameId"}
                    label="Наименование затраты"
                    value={formState?.signJobNameId}
                    setCurrValues={setFormState}
                >
                    {dataForSelects.jobNames.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>))}
                </TextFieldValidation>
                {!initValue.id && <TextFieldValidation
                    select
                    name={"signJobType"}
                    label="Тип работы"
                    value={formState?.signJobType}
                    setCurrValues={setFormState}
                >
                    {dataForSelects.allSignJobType.map((option) => (
                        <MenuItem key={option[0]} value={option[0]}>
                            {option[1]}
                        </MenuItem>))}
                </TextFieldValidation>}
                <TextFieldValidation
                    select
                    name={"modelParameterUnit"}
                    label="Параметр с модели"
                    value={formState?.modelParameterUnit}
                    setCurrValues={setFormState}
                >
                    {dataForSelects.allParameterUnit.map((option) => (
                        <MenuItem key={option[0]} value={option[0]}>
                            {option[1]}
                        </MenuItem>))}
                </TextFieldValidation>

                <TextFieldValidation
                    select
                    name={"curAbbreviation"}
                    label="Валюта"
                    value={formState?.curAbbreviation}
                    setCurrValues={setFormState}
                >
                    {dataForSelects.currencies.map((option) => (
                        <MenuItem key={option.id} value={option.curAbbreviation}>
                            {option.curName}
                        </MenuItem>))}
                </TextFieldValidation>
                <TextFieldValidation
                    type={"number"}
                    name={"price"}
                    value={formState?.price}
                    label="Стоимость"
                    setCurrValues={setFormState}
                    validationError={validationState?.price}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    type={"number"}
                    name={"laborIntensity"}
                    value={formState?.laborIntensity}
                    label="Трудоёмкость"
                    setCurrValues={setFormState}
                    validationError={validationState?.laborIntensity}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    select
                    name={"unitId"}
                    label="Ед.измерения"
                    value={formState?.unitId}
                    setCurrValues={setFormState}
                >
                    {dataForSelects.units.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>))}
                </TextFieldValidation>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={"Выбрать"} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
}
export default SignJobModal