import React, {useMemo} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {modelParameterUnit} from "../../../../../data/ModelParameterUnit";

const JobMetricCloudTable = ({
                                 data,
                                 setData,
                                 enableEditing,
                                 isUnit,
                                 isCutting
                             }) => {

    const columns = useMemo(() => {

        const baseColumns = [
            {
                id: "metricName",
                header: 'Найменование операции',
                accessorKey: 'name',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,

            },
            {
                id: "modelParameterUnit",
                header: 'Параметр с модели',
                accessorKey: 'modelParameterUnit',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
                Cell: ({cell}) => modelParameterUnit[cell.getValue()],
            },
            {
                id: "compactPlate",
                header: 'Компакт плита',
                accessorKey: 'compactPlate',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
            {
                id: "detail",
                header: 'Операция над деталью',
                accessorKey: 'detail',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ];

        if (isUnit) {
            baseColumns.splice(2, 0, {
                header: 'Ед.изм.',
                accessorKey: 'unit.name',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            });
        }

        return baseColumns;

    }, [data, setData, isUnit]);

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    expanded: false,
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 100},
                }}

                enableEditing={enableEditing}
                renderTopToolbarCustomActions={({table}) => {
                    /*    const chooseSignJob = () => {
                            const selectedRows = table.getSelectedRowModel().flatRows;
                            const selectedIds = selectedRows.map((row) => row.getValue('id'));
                            const selectedIndexRows = selectedRows.map((row) => row.index);
                            setSelectedIds(selectedIds);
                            setRowIndexSelection(selectedIndexRows);
                            setShowConfirmAddSignJob(true)
                        };*/

                    return (
                        <div>
                            {/*<Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {isRedirectSettings && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectSettings}
                                    variant="contained"
                                >
                                    Вернуться в настройки
                                </Button>}
                                {isChooseSignJob && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={chooseSignJob}
                                    variant="contained"
                                    disabled={table.getSelectedRowModel().rows.length === 0}
                                >
                                    Установить
                                </Button>}
                                {uploadSignJob && <UploadSignJobFileForm
                                    label={'Загрузить операции'}
                                    change={change}
                                    setChange={setChange}
                                />}
                            </Box>*/}
                        </div>
                    );
                }}
            />
            {/*    <ModalConfirm
                title={"Вы действительно хотите добавить работы?"}
                modalOpen={showConfirmAddSignJob}
                handleClose={handleCloseShowConfirmAddSignJob}
                handleSubmit={handleSubmitAddSign}
                action={'Установить'}
            />*/}
        </div>
    );
};

export default JobMetricCloudTable;