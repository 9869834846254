import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Stack, Tooltip} from "@mui/material";
import {Cached, Delete} from "@mui/icons-material";
import MaterialEstimateUnitService
    from "../../../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import ModalConfirm from "../../../../modal/ModalConfirm";
import EstimateService from "../../../../../../API/orders/estimate/organization/EstimateService";
import {useNavigate} from "react-router-dom";

const initColumn = {
    id: false,
    basePrice: true,
    baseJobPrice: true,
    baseTotalPrice: true,
    calculationCostBasePrice: true,
    costBasePrice: true,
    calculationCostBaseJobPrice: true,
    costBaseJobPrice: true,
    calculationTotalCostBasePrice: true,
    totalCostBasePrice: true,
    profitCalculationBaseMaterialPrice: true,
    profitBaseMaterialPrice: true,
    profitCalculationBaseJobPrice: true,
    profitBaseJobPrice: true,
    profitCalculationBaseTotalPrice: true,
    profitBaseTotalPrice: true,
    alternativePrice: false,
    alternativeJobPrice: false,
    alternativeTotalPrice: false,
    calculationCostAlternativePrice: false,
    costAlternativePrice: false,
    calculationCostAlternativeJobPrice: false,
    costAlternativeJobPrice: false,
    calculationTotalCostAlternativePrice: false,
    totalCostAlternativePrice: false,
    profitCalculationAlternativeMaterialPrice: false,
    profitAlternativeMaterialPrice: false,
    profitCalculationAlternativeJobPrice: false,
    profitAlternativeJobPrice: false,
    profitCalculationAlternativeTotalPrice: false,
    profitAlternativeTotalPrice: false,
}
const CalculationTable = ({
                              data,
                              setData,
                              currency,
                              currentCurrency,
                              estimateId,
                              orderId,
                              orderNumber,
                              orderHeaderId,
                              year,
                              isButtonPurchase,
                              isActual,
                              enableEditing,
                              inWork,
                              isFact,
                              detailed
                          }) => {

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [materialPriceUnits, setMaterialPriceUnits] = useState(new Map());
    const [columnVisibility, setColumnVisibility] = useState(initColumn)

    const [setPurchaseModalOpen, setSetPurchaseModalOpen] = useState(false);

    const [currentInWork, setCurrentInWork] = useState(inWork);

    const [searchSimilarMaterials, setSearchSimilarMaterials] = useState(false);

    useEffect(() => {
        if (currentCurrency === 1) {
            setColumnVisibility(initColumn)
        } else {
            const newColumn = Object.entries(initColumn).map(el => el[0] === "id" ? el : [el[0], !el[1]])
            setColumnVisibility(Object.fromEntries(newColumn))
        }
    }, [currentCurrency]);
    const navigate = useNavigate();

    const redirectEstimateItem = () => {
        navigate('/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year + '/estimate');
    };

    const calcSum = (type) => {
        return data.reduce((acc, mEU) => {
            if (mEU[type] !== null && mEU[type] !== 0) {
                return acc + mEU[type].price;
            } else {
                return acc;
            }
        }, 0)?.toLocaleString?.('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        })
    }

    const averageGrossMargin = useMemo(
        () => {
            if (data.length === 0) {
                return 0;
            }
            const totalGrossMargin = data.reduce((acc, mEU) => acc + mEU.grossMargin, 0);
            return totalGrossMargin / data.length;
        },
        [data]
    );

    const handleDeleteRow = useCallback(
        (row) => {
            const id = row.original.id;
            const materialId = row.original.id
            MaterialEstimateUnitService.delete(id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                    materialPriceUnits.delete(materialId);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const handleSubmitSetPurchase = async () => {
        EstimateService.setPurchase(estimateId)
            .then(function () {
                handleCloseSetPurchase()
                setCurrentInWork(false);
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleCloseSetPurchase = () => {
        setSetPurchaseModalOpen(false);
    };

    const openSearchSimilarMaterials = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setSearchSimilarMaterials(true)
        },
        [],
    );

    const columns = useMemo(() => {
        const baseColumns = [

            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'materialOrganizationOption.articleNumber',
                minSize: 15,
                maxSize: 25,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.materialOrganizationOption.articleNumber : '';
                }
            },
            {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
                Cell: ({cell, row}) => {
                    const temp = row.original.materialOrganizationOption === null;
                    return (
                        <span
                            style={{
                                color: temp ? 'red' : 'black'
                            }}>
                               {temp ? row.original.tempName : row.original.materialOrganizationOption.name}
                            </span>
                    );
                }
            },
            {
                id: 'unit',
                header: 'Ед. изм.',
                accessorKey: 'materialOrganizationOption.unit.name',
                minSize: 5,
                maxSize: 5,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.unit.name : row.original.tempUnit.name;
                }
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return (row.original.materialOrganizationOption === null) ?
                        '' : row.original.materialOrganizationOption.typeMaterial === 'SLAB' ?
                            (row.original.optimizationQuantity === null || row.original.optimizationQuantity === undefined
                                ? row.original.quantity : row.original.optimizationQuantity.toFixed(3)) : value.toFixed(3);
                },
            },
            {
                id: 'basePrice',
                header: `Материал`,
                accessorKey: 'basePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {calcSum("basePrice")} {currency[0].value}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'baseJobPrice',
                header: `Работа`,
                accessorKey: 'baseJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? '-' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {calcSum("baseJobPrice")} {currency[0].value}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'baseTotalPrice',
                header: `Итого:`,
                accessorKey: 'baseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {calcSum("baseTotalPrice")} {currency[0].value}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativePrice',
                header: `Материал`,
                accessorKey: 'alternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {calcSum("alternativePrice")} {currency[1].value}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativeJobPrice',
                header: `Работа`,
                accessorKey: 'alternativeJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? '-' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {calcSum("alternativeJobPrice")} {currency[1].value}
                        </Box>
                    </Stack>
                ),
            },
            /*{
                id: 'alternativeTotalPrice',
                header: `Итого:`,
                accessorKey: 'alternativeTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {calcSum("alternativeTotalPrice")} {currency[1].value}
                        </Box>
                    </Stack>
                ),
            },*/
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },
        ];


        baseColumns.splice(4, 0, {
            id: 'costBasePrice',
            header: `СБ мат.`,
            accessorKey: 'costBasePrice',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {calcSum("costBasePrice")} {currency[0].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(5, 0, {
            id: 'costBaseJobPrice',
            header: `СБ раб.`,
            accessorKey: 'costBaseJobPrice',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? '-' : value.price.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {calcSum("costBaseJobPrice")} {currency[0].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(6, 0, {
            id: 'totalCostBasePrice',
            header: `СБ итого:`,
            accessorKey: 'totalCostBasePrice',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {calcSum("totalCostBasePrice")} {currency[0].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(15, 0, {
            id: 'profitBaseMaterialPrice',
            header: `Прибыль мат.`,
            accessorKey: 'profitBaseMaterialPrice',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {calcSum("profitBaseMaterialPrice")} {currency[0].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(16, 0, {
            id: 'profitBaseJobPrice',
            header: `Прибыль раб.`,
            accessorKey: 'profitBaseJobPrice',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? '-' : value.price.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {calcSum("profitBaseJobPrice")} {currency[0].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(17, 0, {
            id: 'profitBaseTotalPrice',
            header: `Прибыль итого:`,
            accessorKey: 'profitBaseTotalPrice',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {calcSum("profitBaseTotalPrice")} {currency[0].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(4, 0, {
            id: 'costAlternativePrice',
            header: `СБ мат.`,
            accessorKey: 'costAlternativePrice',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {calcSum("costAlternativePrice")} {currency[1].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(5, 0, {
            id: 'costAlternativeJobPrice',
            header: `СБ раб.`,
            accessorKey: 'costAlternativeJobPrice',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? '-' : value.price.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {calcSum("costAlternativeJobPrice")} {currency[1].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(6, 0, {
            id: 'totalCostAlternativePrice',
            header: `СБ итого:`,
            accessorKey: 'totalCostAlternativePrice',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {calcSum("totalCostAlternativePrice")} {currency[1].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(20, 0, {
            id: 'profitAlternativeMaterialPrice',
            header: `Прибыль мат.`,
            accessorKey: 'profitAlternativeMaterialPrice',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {calcSum("profitAlternativeMaterialPrice")} {currency[1].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(22, 0, {
            id: 'profitAlternativeJobPrice',
            header: `Прибыль раб.`,
            accessorKey: 'profitAlternativeJobPrice',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? '-' : value.price.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {calcSum("profitAlternativeJobPrice")} {currency[1].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(24, 0, {
            id: 'profitAlternativeTotalPrice',
            header: `Прибыль итого:`,
            accessorKey: 'profitAlternativeTotalPrice',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {calcSum("profitAlternativeTotalPrice")} {currency[1].value}
                    </Box>
                </Stack>
            ),
        });

        baseColumns.splice(30, 0, {
            id: 'grossMargin',
            header: `Валовая маржа`,
            accessorKey: 'grossMargin',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => {
                const value = cell.getValue();
                return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
            },
            Footer: () => (
                <Stack>
                    <Box color="warning.main">
                        {"СР"} {averageGrossMargin?.toLocaleString?.('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                    })}
                    </Box>
                </Stack>
            ),
        });

        return baseColumns;
    }, [data, setData]);

    return (
        <div>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 10,
                        grow: false,
                    },
                    'mrt-row-select': {
                        size: 10,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: initColumn,
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                    sorting: [{id: 'unit', desc: false}],
                    showGlobalFilter: true,
                }}
                enableEditing={false}
                state={{columnVisibility}}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        {!row.original.isBlocked ?
                            <Tooltip arrow placement="left" title="Заменить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => openSearchSimilarMaterials(row)}>
                                    <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> : <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                                 onClick={() => openSearchSimilarMaterials(row)}>
                                <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        }
                        {!row.original.isBlocked ? <Tooltip arrow placement="right" title="Удалить">
                            <Button
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                            >
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip> : <Button
                            size="small"
                            sx={{minWidth: 'auto', marginLeft: 'auto'}}
                            color="error"
                            onClick={() => handleDeleteRow(row)}
                            disabled={true}
                        >
                            <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>}
                    </Box>
                )}
                localization={MRT_Localization_RU}
                enableToolbarInternalActions={false}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <div style={{display: "flex"}}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    p: '0.5rem',
                                    flexWrap: 'wrap'
                                }}
                            >
                                {detailed && <Button
                                    size="small"
                                    variant="outlined"
                                    style={{color: "black", borderColor: "black", fontWeight: "bold", fontSize: 14}}
                                    onClick={() => redirectEstimateItem()}
                                >
                                    Подробно
                                </Button>}
                            </Box>
                        </div>
                    );
                }}
            />
            <ModalConfirm
                title={"Подтвердить создание заявки на закупку?"}
                modalOpen={setPurchaseModalOpen}
                handleClose={handleCloseSetPurchase}
                handleSubmit={handleSubmitSetPurchase}
                action={"Подтвердить"}
            />
        </div>
    );
};

export default CalculationTable;
