import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {ArrowRight} from "@mui/icons-material";
import Box from "@mui/material/Box";
import AttributesService from "../../../../../../API/orders/orders/organization/AttributesService";
import ButtonSettingsOrders from "../../../../button/ButtonSettingsOrders";
import CellCheckboxATUnable from "./CellCheckboxATUnable";
import {useNavigate} from "react-router-dom";
import CellCheckboxCurrent from "../../../../сheckbox/CellCheckboxCurrent";

const AttributesTable = () => {

    const [data, setData] = useState([]);
    const [currentPackagingSetId, setCurrentPackagingSetId] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const navigate = useNavigate();

    const redirectModels = () => {
        navigate('/organization/models/');
    };

    useEffect(() => {
        AttributesService.getAll().then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [currentPackagingSetId]);


    const setPackagingSet = useCallback(
        (row) => {
            const idPackagingSet = row.original.id;
            AttributesService.unableDefault(idPackagingSet)
                .then(function () {
                    setCurrentPackagingSetId(idPackagingSet)
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [],
    );

    const columns = useMemo(
        () => [
            {
                id: 'name',
                header: 'Наименование',
                accessorKey: 'name',
                minSize: 400,

            },
            {
                id: 'enabledDefault',
                header: 'По умочанию',
                accessorKey: 'enabledDefault',
                minSize: 100,
                enableEditing: false,
                Cell: ({cell, row}) => <CellCheckboxCurrent
                    cell={cell}

                >
                </CellCheckboxCurrent>,
            },
            {
                id: 'enabled',
                header: 'Статус',
                accessorKey: 'enabled',
                minSize: 100,
                enableEditing: false,
                Cell: ({cell, row}) => <CellCheckboxATUnable
                    cell={cell}
                    id={data[row.index].id}
                ></CellCheckboxATUnable>,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:first-of-type": {
                            padding: 0,
                            width: "100%",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        sx: () => ({
                            height: '36px',
                            backgroundColor: "white",

                            "&:hover": {
                                backgroundColor: "white",
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: (theme) => theme.palette.primary.lightGrey,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.lightGrey
                                },
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 10,
                        grow: false,
                    },
                    'mrt-row-select': {
                        size: 10,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                columns={columns}
                data={data}
                enableEditing
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip arrow placement="right" title="Установить">
                            <IconButton color="success" onClick={() => setPackagingSet(row)}>
                                <ArrowRight/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                state={{showGlobalFilter: true}}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                    columnPinning: {left: ['number']}
                }}
                localization={MRT_Localization_RU}
                enableRowActions={true}
                enableToolbarInternalActions={false}
            />
        </>
    );
};

export default AttributesTable;