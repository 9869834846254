export default class GroupMaterialUtil {

    static getAllChildrenIds(parentId, groupMap) {
        const childIds = [];
        let queue = [];
        queue.push(parentId);
        while (queue.length > 0) {
            const currentId = queue.shift();
            const group = groupMap.get(currentId);
            if (group) {
                childIds.push(currentId);
                if (group.subGroups) {
                    group.subGroups.forEach((subGroupId) => {
                        queue.push(subGroupId);
                    });
                }
            }
        }
        return childIds;
    }

    static addToMap(groups, map) {
        if (groups !== null) {
            groups.forEach((group) => {
                map.set(group.id, group);
                if (group.subGroups) {
                    GroupMaterialUtil.addToMap(group.subGroups, map);
                }
            });
        }
    }

    static addToSet(group, set) {
        if (group !== null) {
            set.add(group)
            let subGroups = group.subGroups;
            if (subGroups !== null) {
                for (let i = 0; i < subGroups.length; i++) {
                    let gr = subGroups [i];
                    GroupMaterialUtil.addToSet(gr, set);
                }
            }
        }
    }

    static addToFilterMapGroup(object, map) {

        if (object instanceof Map) {
            object.forEach((value, key) => {
                GroupMaterialUtil.addToFilterMapGroup(value, map)
            });
        }

        function addToMapSubGroups(object) {
            let subGroups = object.subGroups;

            if (subGroups) {
                for (let i = 0; i < subGroups.length; i++) {
                    let gr = subGroups [i];
                    GroupMaterialUtil.addToFilterMapGroup(gr, map);
                }
            }
        }

        if (object !== null) {
            if (Array.isArray(object)) {
                object.forEach(function (value, key) {
                    map.set(key, value)
                    addToMapSubGroups(value)
                })
            } else {
                map.set(object.id, object)
                addToMapSubGroups(object)
            }
        }
    }

    static buildTreeGroups(groups) {
        const getSubGroups = (groupMap, group) => {
            const subGroups = group.subGroups;
            subGroups.sort((a, b) => {
                const aUID = a.UID.slice(0, 3);
                const bUID = b.UID.slice(0, 3);
                return aUID.localeCompare(bUID);
            });
            group.subGroups = subGroups.map(subGroup => getSubGroups(groupMap, subGroup));
            return {...groupMap.get(group.id), subGroups};
        };

        const treeGroups = [];
        const groupMap = new Map(groups.map(group => [group.id, {...group, subGroups: []}]));

        for (const group of groupMap.values()) {
            if (group.parent) {
                const parent = groupMap.get(group.parent.id);
                if (parent) {
                    parent.subGroups = parent.subGroups;
                    parent.subGroups.push(group);
                }
            } else {
                treeGroups.push({...group, subGroups: group.subGroups});
            }
        }
        treeGroups.sort((a, b) => {
            const aUID = a.UID.slice(0, 3);
            const bUID = b.UID.slice(0, 3);
            return aUID.localeCompare(bUID);
        });
        return treeGroups.map(group => getSubGroups(groupMap, group));
    }

    static buildTreeGroupsSamples(groups) {
        const treeGroups = [];
        const groupMap = new Map(groups.map(group => [group.id, {...group, subGroups: []}]));
        for (const group of groupMap.values()) {
            if (group.parentGroup) {
                const parent = groupMap.get(group.parentGroup.id);
                parent.subGroups.push(group);
            } else {
                treeGroups.push({...group, subGroups: group.subGroups});
            }
        }
        return treeGroups
    }

    static buildTreeGroupsSettings(groups) {
        const treeGroups = [];
        const groupMap = new Map(groups.map(group => [group.id, {...group, subGroups: []}]));
        for (const group of groupMap.values()) {
            if (group.parent) {
                const parent = groupMap.get(group.parent.id);
                parent.subGroups.push(group);
            } else {
                treeGroups.push({...group, subGroups: group.subGroups});
            }
        }
        return treeGroups
    }

    static localizationReplacement(groupsMaterial) {
        if (groupsMaterial !== null) {
            groupsMaterial.forEach((group) => {
                if (group.name === "custom") {
                    group.name = "Собсвенные"
                }
            });
        }
    }
}





