import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import SettingsSignJobTable from "../../../components/UI/table/settings/SettingsSignJobTable";
import {useParams} from "react-router-dom";
import {constantsErrorMessages} from "../../../Constants";
import CircularProgress from "@mui/material/CircularProgress";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import SignJobService from "../../../API/orders/job/SignJobService";
import JobMetricOrganizationService from "../../../API/orders/job/JobMetricOrganizationService";

const SettingsMetricJob = () => {
    const profile = JSON.parse(localStorage.getItem("profile"))
    const {baseCurAbbreviation: baseCurrency, alternativeCurAbbreviation: alternativeCurrency} = profile
    const params = useParams();
    const {id} = params

    const [data, setData] = useState([]);
    const [jobMetric, setJobMetric] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [alertData, setAlertData] = useState({});

    const handleError = (error) => {
        setIsError(true)
        setAlertData({
            type: 'error',
            open: true,
            message: constantsErrorMessages[error.response.status] || error.message
        })
    }
    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }

    useEffect(() => {
        setIsLoading(true)
        SignJobService.getByType(id)
            .then(function (response) {
                const data = response?.data
                setData(data);
                setIsError(false)
            })
            .catch(function (error) {
                handleError(error)
            })
            .finally(() => setIsLoading(false))

        JobMetricOrganizationService.get().then(function (response) {
            setJobMetric(response.data)
        })
            .catch(function (error) {
                handleError(error)
            })
            .finally(() => setIsLoading(false))

    }, []);

    return isLoading ?
        <Box sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <CircularProgress disableShrink/>
        </Box> :
        <Box>
            <ModalAlert data={alertData} onClose={handleCloseAlert}/>
            <Box sx={{padding: "0 30px"}}>
                <BreadcrumbsComponent
                    page={"Метрики работ"}
                    path={[{name: "Главная", href: "/"}, {name: "Настройки", href: "/settings"}]}/>
                <SettingsSignJobTable
                    data={data}
                    setData={setData}
                    isError={isError}
                    baseCurrency={baseCurrency}
                    alternativeCurrency={alternativeCurrency}
                    id={id}
                    jobMetric={jobMetric}/>
            </Box>
        </Box>

}
export default SettingsMetricJob