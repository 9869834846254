import React, {useEffect, useState} from 'react';
import {Box, Button, Tabs} from "@mui/material";
import Tab from '@mui/material/Tab';
import DetailTable from "../../../../components/UI/table/cutting/details/DetailTable";
import {useNavigate, useParams} from "react-router-dom";
import DetailService from "../../../../API/orders/cutting/detail/DetailService";
import {TabContext, TabPanel} from "@mui/lab";
import MaterialWarehouseTable
    from "../../../../components/UI/table/warehouse/material/materialwarehouse/MaterialWarehouseTable";
import MaterialWarehouseService from "../../../../API/orders/warehouse/material/MaterialWarehouseService";
import Typography from "@mui/material/Typography";
import ReservationTableCuttingItem
    from "../../../../components/UI/table/warehouse/reservation/ReservationTableCuttingItem";
import WorkingCuttingMapService from "../../../../API/orders/cutting/cuttingmap/WorkingCuttingMapService";
import * as Dimensions from "../../../../data/Dimensions";
import SupplierService from "../../../../API/orders/warehouse/supplier/SupplierService";
import CuttingModule from "../../../../components/UI/modul/CuttingModule";
import SettingsCuttingModule from "../../../../components/UI/modul/cuttingmodule/SettingsCuttingModule";
import WarehouseMaterialSettingsService from "../../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import MaterialOrganizationOptionService
    from "../../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import SettingsCutOffCuttingModule from "../../../../components/UI/modul/cuttingmodule/SettingsCutOffCuttingModule";
import ReservationService from "../../../../API/orders/reservation/MaterialReserveService";

const WorkingCuttingMapItem = () => {

        const [data, setData] = useState([]);

        const [dataMaterialWarehouse, setDataMaterialWarehouse] = useState([]);
        const [unableCutMaterialWarehouse, setUnableCutMaterialWarehouse] = useState({});

        const [dataMaterialWarehouseCuttingMap, setDataMaterialWarehouseCuttingMap] = useState([]);

        const [isLoadingCuttingMap, setIsLoadingCuttingMap] = useState(false);

        const [images, setImages] = useState([]);
        const [currentPage, setCurrentPage] = useState(1);
        const [drawEdge, setDrawEdge] = useState(true);

        const [changeCuttingMap, setChangeCuttingMap] = useState(true);
        const [dataMaterialReserve, setDataMaterialReserve] = useState([]);

        const [unableCut, setUnableCut] = useState({});
        const [unableTurn, setUnableTurn] = useState({});
        const [selectedIds, setSelectedIds] = useState([]);
        const [modalStatusOpen, setModalStatusOpen] = useState(false);
        const [status, setStatus] = useState(true);
        const [typeStatus, setTypeStatus] = useState(null);
        const [value, setValue] = useState('2');
        const [change, setChange] = useState(false);
        const [isError, setIsError] = useState(false);
        const [state, setState] = useState(null);
        const [cancelReserveButton, setCancelReserveButton] = useState(null);
        const [approveButton, setApproveButton] = useState(null);
        const [cutButton, setCutButton] = useState(null);
        const [currentOrdersCuttingMapDataObjectDetails, setCurrentOrdersCuttingMapDataObjectDetails] = useState([])
        const [notUsedDetails, setNotUsedDetails] = useState([])
        const [currentOrdersCuttingMapDataObjectReserve, setCurrentOrdersCuttingMapDataObjectReserve] = useState([])

        const [supplierList, setSupplierList] = useState(null);
        const [supplierId, setSupplierId] = useState(null);
        const [defaultSupplierId, setDefaultSupplierId] = useState(null);
        const [minBalanceUpToWholeSheet, setMinBalanceUpToWholeSheet] = useState(null);
        const [material, setMaterial] = useState(null);
        const [cutDefault, setCutDefault] = useState(null);

        const [trimmingLeft, setTrimmingLeft] = useState(null);
        const [trimmingRight, setTrimmingRight] = useState(null);
        const [trimmingBottom, setTrimmingBottom] = useState(null);
        const [trimmingTop, setTrimmingTop] = useState(null);

        const [trimmingLeftCutOff, setTrimmingLeftCutOff] = useState(null);
        const [trimmingRightCutOff, setTrimmingRightCutOff] = useState(null);
        const [trimmingBottomCutOff, setTrimmingBottomCutOff] = useState(null);
        const [trimmingTopCutOff, setTrimmingTopCutOff] = useState(null);

        const [baseCurrency, setBaseCurrency] = useState("");
        const [alternativeCurrency, setAlternativeCurrency] = useState("");
        const [isCalculation, setIsCalculation] = useState(false);
        const [isArchive, setIsArchive] = useState(false);
        const [isCalculationArchive, setIsCalculationArchive] = useState(false);

        const [baseIndents, setBaseIndents] = useState({
            trimmingLeft: 20,
            trimmingRight: 20,
            trimmingTop: 20,
            trimmingBottom: 20,
        });

        const [tempBaseIndents, setTempBaseIndents] = useState({
            trimmingLeft: 20,
            trimmingRight: 20,
            trimmingTop: 20,
            trimmingBottom: 20,
        });

        const params = useParams();

        const workingCuttingMapId = params.workingCuttingMapId;
        const nameMaterial = params.nameMaterial;

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        const notUsedDetailsIsEmpty = () => {
            return !(Array.isArray(notUsedDetails) && notUsedDetails.length === 0);
        };

        useEffect(() => {

            WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
                let data = response.data;
                setBaseCurrency(data.baseCurrency.curAbbreviation)
                setAlternativeCurrency(data.alternativeCurrency.curAbbreviation)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        useEffect(() => {

            DetailService.getAllWorkingCuttingMapId(workingCuttingMapId).then(function (response) {
                let data = response.data;
                setData(data);
                data.forEach((row) => {
                    unableCut[row.id] = row.cut;
                    unableTurn[row.id] = row.turn;
                });
            })
                .catch(function (error) {
                    console.log(error);
                });

            SupplierService.getAllByOrganization().then(function (response) {
                setSupplierList(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });
            MaterialOrganizationOptionService.getWithDefaultSupplier(nameMaterial).then(function (response) {
                const data = response.data;
                setMaterial(data);
                setCutDefault(data.cutDefault);
                setDefaultSupplierId(data.defaultSupplier.id);
                setSupplierId(data.defaultSupplier.id)
                setMinBalanceUpToWholeSheet(data.minBalanceUpToWholeSheet);

                const trimmingLeft = data.trimmingLeft;
                const trimmingRight = data.trimmingRight;
                const trimmingBottom = data.trimmingBottom;
                const trimmingTop = data.trimmingTop;

                const trimmingLeftCutOff = data.trimmingLeftCutOff;
                const trimmingRightCutOff = data.trimmingRightCutOff;
                const trimmingBottomCutOff = data.trimmingBottomCutOff;
                const trimmingTopCutOff = data.trimmingTopCutOff;

                const currentTrimmingLeftCutOff = trimmingLeftCutOff ? trimmingLeftCutOff : 10;
                const currentTrimmingRightCutOff = trimmingRightCutOff ? trimmingRightCutOff : 10;
                const currentTrimmingBottomCutOff = trimmingBottomCutOff ? trimmingBottomCutOff : 10;
                const currentTrimmingTopCutOff = trimmingTopCutOff ? trimmingTopCutOff : 10;

                const trimming = {
                    trimmingLeft: trimmingLeft,
                    trimmingRight: trimmingRight,
                    trimmingTop: trimmingTop,
                    trimmingBottom: trimmingBottom
                }
                setBaseIndents(trimming);

                setTrimmingLeft(trimmingLeft);
                setTrimmingRight(trimmingRight);
                setTrimmingBottom(trimmingBottom);
                setTrimmingTop(trimmingTop);

                setTrimmingLeftCutOff(currentTrimmingLeftCutOff);
                setTrimmingRightCutOff(currentTrimmingRightCutOff);
                setTrimmingBottomCutOff(currentTrimmingBottomCutOff);
                setTrimmingTopCutOff(currentTrimmingTopCutOff);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        useEffect(() => {

                MaterialWarehouseService.getAllByMaterialName(nameMaterial).then(function (response) {
                    const data = response.data;
                    setDataMaterialWarehouse(data);
                    data.forEach((row) => {
                        unableCutMaterialWarehouse[row.id] = row.materialPriceUnit.cut;
                    });
                })
                    .catch(function (error) {
                        console.log(error);
                    });

                WorkingCuttingMapService.getState(workingCuttingMapId).then(function (response) {
                    const data = response.data;
                    setIsCalculation(data === 'CALCULATION' || data === 'CALCULATION_RESERVES_CANCEL' || data === 'CALCULATION_RESERVES_CREATED' || data === 'ARCHIVE_CALCULATION');
                    setIsArchive(data === 'ARCHIVE');
                    setIsCalculationArchive(data === 'ARCHIVE_CALCULATION');
                    setState(data);
                    setApproveButton(data === 'RESERVES_CREATED');
                    setCutButton(data === 'EXPECTATION' || data === 'MAPS_CANCEL' || data === 'CALCULATION'
                        || data === 'RESERVES_CANCEL' || data === 'CALCULATION_RESERVES_CANCEL');
                    setCancelReserveButton(data === 'RESERVES_CREATED' || data === 'CALCULATION_RESERVES_CREATED');
                })
                    .catch(function (error) {
                        console.log(error);
                    });

                DetailService.getAllNotUsedDetailsWorkingCuttingMapId(workingCuttingMapId).then(function (response) {
                    const data = response.data;
                    setNotUsedDetails(data);
                })
                    .catch(function (error) {
                        console.log(error);
                    });

            }, [changeCuttingMap]
        )
        ;

        useEffect(() => {

                setIsLoadingCuttingMap(true)
                WorkingCuttingMapService.getAllWorkingCuttingMapId(workingCuttingMapId, drawEdge)
                    .then(function (response) {
                        setIsLoadingCuttingMap(false)
                        setDataMaterialWarehouseCuttingMap(response.data)
                        const images = response.data.map((item) => {
                            const image = new Image();
                            image.src = 'data:image/png;base64,' + item.base64Image;
                            return image;
                        });
                        setImages(images);
                    })
                    .catch(function (error) {
                        console.log(error);
                        setIsLoadingCuttingMap(false)
                    })
                ReservationService.getAllByWorkingCuttingMapId(workingCuttingMapId).then(function (response) {
                    const data = response.data;
                    setDataMaterialReserve(data);
                })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            [changeCuttingMap]
        );

        useEffect(() => {
            if (dataMaterialWarehouseCuttingMap.length !== 0) {
                const currentOrdersCuttingMapDataObjectId = dataMaterialWarehouseCuttingMap[currentPage - 1].id
                DetailService.getAllByCuttingMapDataObjectId(currentOrdersCuttingMapDataObjectId).then(function (response) {
                    const data = response.data;
                    setCurrentOrdersCuttingMapDataObjectDetails(data);
                })
                    .catch(function (error) {
                        console.log(error);
                    });
                ReservationService.getByOrdersCuttingMapDataObjectId(currentOrdersCuttingMapDataObjectId).then(function (response) {
                    const data = response.data;
                    const array = [];
                    if (data !== '') {
                        array.push(data)
                        setCurrentOrdersCuttingMapDataObjectReserve(array);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }, [currentPage, dataMaterialWarehouseCuttingMap, changeCuttingMap]);

        const navigate = useNavigate();

        const redirectOptimizations = () => {
            navigate('/organization/optimizations');
        };

        const redirectPlans = () => {
            navigate('/organization/plans');
        };

        const redirectRequestsCutting = () => {
            navigate('/organization/requests-cutting');
        };

        const redirectWorkingCuttingMaps = () => {
            navigate('/organization/working-cutting-maps');
        };

        const redirectCalculationWorkingCuttingMaps = () => {
            navigate('/organization/working-cutting-maps-calculation');
        };

        const redirectCalculationRequestsCutting = () => {
            navigate('/organization/requests-cutting/Calculation');
        };

        const redirectCalculationOptimizations = () => {
            navigate('/organization/optimizations-calculation');
        };

        const approve = async () => {
            setIsLoadingCuttingMap(true)
            WorkingCuttingMapService.approveCuttingMap(workingCuttingMapId)
                .then(function () {
                    setIsLoadingCuttingMap(false)
                    setChangeCuttingMap(!changeCuttingMap)
                })
                .catch(function (error) {
                    console.log(error);
                    setIsLoadingCuttingMap(false)
                })
        }

        const cancelReserve = async () => {
            setIsLoadingCuttingMap(true)
            WorkingCuttingMapService.cancelReserve(workingCuttingMapId)
                .then(function () {
                    setIsLoadingCuttingMap(false);
                    setChangeCuttingMap(!changeCuttingMap);
                    setCurrentOrdersCuttingMapDataObjectDetails([]);
                    setCurrentOrdersCuttingMapDataObjectReserve([]);
                })
                .catch(function (error) {
                    console.log(error);
                    setIsLoadingCuttingMap(false)
                })
        }

        const cancel = async () => {
            setIsLoadingCuttingMap(true)
            WorkingCuttingMapService.cancelCuttingMap(workingCuttingMapId)
                .then(function () {
                    setIsLoadingCuttingMap(false);
                    setChangeCuttingMap(!changeCuttingMap);
                    setCurrentOrdersCuttingMapDataObjectDetails([]);
                    setCurrentOrdersCuttingMapDataObjectReserve([]);
                })
                .catch(function (error) {
                    console.log(error);
                    setIsLoadingCuttingMap(false)
                })
        }

        const [fitToStripFirst, setFitToStripFirst] = useState(false);
        const [fitToStripSecond, setFitToStripSecond] = useState(false);
        const [dimensions, setDimensions] = useState(Object.keys(Dimensions.dimensions)[0]);
        const [combiningDrops, setCombiningDrops] = useState(false);
        const [cutDropDetail, setCutDropDetail] = useState(false);
        const [cutFitToStripFirstDropDetail, setCutFitToStripFirstDropDetail] = useState(false);
        const [cutFitToStripSecondDropDetail, setCutFitToStripSecondDropDetail] = useState(false);
        const [cutCombiningDrops, setCutCombiningDrops] = useState(false);
        const [takeAccountDimensions, setTakeAccountDimensions] = useState(true);
        const [cutWastes, setCutWastes] = useState(false);
        const [cutCutOffs, setCutCutOffs] = useState(false);
        const [cutWeight, setCutWeight] = useState(4.2);
        const [minACutOff, setMinACutOff] = useState(464);
        const [minBCutOff, setMinBCutOff] = useState(64);
        const [limitNumberTurns, setLimitNumberTurns] = useState(2);
        const [rotatePartInsideStrip, setRotatePartInsideStrip] = useState(false);
        const [cutOnlyCutOffs, setCutOnlyCutOffs] = useState(false);
        const [cutOnlyWarehouse, setCutOnlyWarehouse] = useState(false);

        const cutting = async () => {
            setIsLoadingCuttingMap(true)

            const currentSupplierId = supplierId === null ? defaultSupplierId : supplierId;

            WorkingCuttingMapService.cut(workingCuttingMapId, cutWeight, minACutOff, minBCutOff, rotatePartInsideStrip, fitToStripFirst, fitToStripSecond, dimensions, combiningDrops,
                cutDropDetail, cutFitToStripFirstDropDetail, cutFitToStripSecondDropDetail,
                cutCombiningDrops, takeAccountDimensions, cutWastes, cutCutOffs, limitNumberTurns, cutOnlyCutOffs, cutOnlyWarehouse, currentSupplierId, minBalanceUpToWholeSheet,
                cutDefault, trimmingLeft, trimmingRight, trimmingBottom, trimmingTop, trimmingLeftCutOff, trimmingRightCutOff, trimmingBottomCutOff, trimmingTopCutOff).then(function () {
                setIsLoadingCuttingMap(false)
                setChangeCuttingMap(!changeCuttingMap)
            })
                .catch(function (error) {
                    console.log(error);
                    setIsLoadingCuttingMap(false)
                })
        }

        return (
            <Box sx={{
                '& > *': {
                    marginTop: '0px',
                }
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        paddingLeft: '0.1rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.1rem',
                        flexWrap: 'wrap'
                    }}
                >
                    {!isCalculation && <Button
                        size="small"
                        color="secondary"
                        onClick={redirectRequestsCutting}
                        variant="contained"
                    >
                        Заявки на раскрой
                    </Button>}
                    {isCalculation && <Button
                        size="small"
                        color="secondary"
                        onClick={redirectCalculationRequestsCutting}
                        variant="contained"
                    >
                        Рас. заявки на раскрой
                    </Button>}
                    {!isCalculation && <Button
                        size="small"
                        color="secondary"
                        onClick={redirectOptimizations}
                        variant="contained"
                    >
                        Оптимизации
                    </Button>}
                    {isCalculation && <Button
                        size="small"
                        color="secondary"
                        onClick={redirectCalculationOptimizations}
                        variant="contained"
                    >
                        Рас.оптимизации
                    </Button>}
                    {!isCalculation && <Button
                        size="small"
                        color="secondary"
                        onClick={redirectPlans}
                        variant="contained"
                    >
                        Планы
                    </Button>}
                    {!isCalculation && <Button
                        size="small"
                        color="secondary"
                        onClick={redirectWorkingCuttingMaps}
                        variant="contained"
                    >
                        Карты раскроя
                    </Button>}
                    {isCalculation && <Button
                        size="small"
                        color="secondary"
                        onClick={redirectCalculationWorkingCuttingMaps}
                        variant="contained"
                    >
                        Рас. карты раскроя
                    </Button>}
                </Box>
                <TabContext value={value}>
                    <Box sx={{
                        borderBottom: 1, borderColor: 'divider'
                    }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            {!(isArchive || isCalculationArchive) && <Tab label="Материалы" value='0'/>}
                            {!(isCalculationArchive) && <Tab label="Детали" value='1'/>}
                            <Tab label="Карты раскроя" value='2'/>
                            {!(isArchive || isCalculationArchive) && <Tab label="Настройки" value='5'/>}
                            {!(isArchive || isCalculationArchive) && <Tab label="Настройки обрезков" value='6'/>}
                            {notUsedDetailsIsEmpty() && <Tab
                                label="Неиспользованные детали"
                                value='4'
                                sx={{color: 'error.main'}}
                            />}
                            <Tab label="Резервы" value='3'/>
                            {notUsedDetailsIsEmpty() && <Tab
                                label="Неиспользованные детали"
                                value='4'
                                sx={{color: 'error.main'}}
                            />}

                        </Tabs>
                    </Box>
                    <TabPanel value='0'>
                        <Typography
                            sx={{
                                marginBottom: '3px',
                            }}
                            variant="body1" component="h3">
                            {nameMaterial}
                        </Typography>
                        <MaterialWarehouseTable
                            data={dataMaterialWarehouse}
                            setData={setDataMaterialWarehouse}
                            nameMaterial={nameMaterial}
                            isPriceInCurrencyArrival={true}
                            isPriceInCurrencyAlternative={true}
                            isPriceInCurrencyBase={true}
                            isWarehouse={true}
                            baseIndents={baseIndents}
                            tempBaseIndents={tempBaseIndents}
                            setTempBaseIndents={setTempBaseIndents}
                            unableCut={unableCutMaterialWarehouse}
                            setUnableCut={setUnableCutMaterialWarehouse}
                            isAddCutOffButton={true}
                            isIndentsButton={true}
                            isActions={true}
                            isColumnCut={true}
                            isColumnReserve={true}
                            baseCurrency={baseCurrency}
                            alternativeCurrency={alternativeCurrency}
                        />
                    </TabPanel>
                    <TabPanel value='1'>
                        <DetailTable
                            data={data}
                            setData={setData}
                            unableCut={unableCut}
                            unableTurn={unableTurn}
                            setUnableCut={setUnableCut}
                            setUnableTurn={setUnableTurn}
                            selectedIds={selectedIds}
                            setSelectedIds={setSelectedIds}
                            modalStatusOpen={modalStatusOpen}
                            setModalStatusOpen={setModalStatusOpen}
                            status={status}
                            setStatus={setStatus}
                            typeStatus={typeStatus}
                            setTypeStatus={setTypeStatus}
                            isColumnOrder={true}
                            warehouse={'orders'}
                            expanded={true}
                            pageSize={100}
                            change={change}
                            setChange={setChange}
                            isError={isError}
                            isColumnCut={true}
                            isColumnTurn={true}
                            enableRowSelection={true}
                            enableEditing={true}
                            isButtonSetStatus={true}
                            hideQuantity={true}
                        />
                    </TabPanel>
                    <TabPanel value='2'>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '0.5rem',
                                p: '0.5rem',
                                flexWrap: 'wrap',
                                flexDirection: 'column'
                            }}
                        >
                            <CuttingModule
                                images={images}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                isLoading={isLoadingCuttingMap}
                                changeCuttingMap={changeCuttingMap}
                                setChangeCuttingMap={setChangeCuttingMap}
                                fitToStripFirst={fitToStripFirst}
                                setFitToStripFirst={setFitToStripFirst}
                                fitToStripSecond={fitToStripSecond}
                                setFitToStripSecond={setFitToStripSecond}
                                dimensions={dimensions}
                                setDimensions={setDimensions}
                                combiningDrops={combiningDrops}
                                setCombiningDrops={setCombiningDrops}
                                cutDropDetail={cutDropDetail}
                                setCutDropDetail={setCutDropDetail}
                                cutFitToStripFirstDropDetail={cutFitToStripFirstDropDetail}
                                setCutFitToStripFirstDropDetail={setCutFitToStripFirstDropDetail}
                                cutFitToStripSecondDropDetail={cutFitToStripSecondDropDetail}
                                setCutFitToStripSecondDropDetail={setCutFitToStripSecondDropDetail}
                                cutCombiningDrops={cutCombiningDrops}
                                setCutCombiningDrops={setCutCombiningDrops}
                                takeAccountDimensions={takeAccountDimensions}
                                setTakeAccountDimensions={setTakeAccountDimensions}
                                cutWastes={cutWastes}
                                setCutWastes={setCutWastes}
                                cutCutOffs={cutCutOffs}
                                setCutCutOffs={setCutCutOffs}
                                cutOnlyCutOffs={cutOnlyCutOffs}
                                setCutOnlyCutOffs={setCutOnlyCutOffs}
                                cutOnlyWarehouse={cutOnlyWarehouse}
                                setCutOnlyWarehouse={setCutOnlyWarehouse}
                            />
                            <Box sx={{
                                marginRight: '10px'
                            }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        paddingTop: '0.5rem',
                                        paddingBottom: '0.5rem',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {cutButton &&
                                        <Button sx={{marginRight: '0px'}} variant="contained" color="secondary"
                                                onClick={cutting}>Раскроить</Button>}
                                    {cancelReserveButton &&
                                        <Button sx={{marginRight: '0px'}} variant="contained" color="secondary"
                                                onClick={cancelReserve}>Отменить резервы</Button>}
                                    {approveButton &&
                                        <Button sx={{marginRight: '0px'}} variant="contained" color="success"
                                                onClick={approve}>Отправить на закупку</Button>}
                                    {state === 'MAPS_APPROVED' &&
                                        <Button sx={{marginRight: '10px'}} variant="contained" color="secondary"
                                                onClick={cancel}>Отменить карты</Button>}
                                </Box>
                                {/*<Box
                                sx={{display: 'flex', gap: '0.5rem', flexWrap: 'wrap'}}
                            >
                                {state === 'MAPS_APPROVED' &&
                                    <Button sx={{marginRight: '0px'}} variant="contained" color="secondary"
                                        onClick={cutting}>Перекроить текущий лист</Button>}
                                {state === 'MAPS_APPROVED' &&
                                    <Button sx={{marginRight: '10px'}} variant="contained" color="secondary"
                                        onClick={cutting}>Перезаписать текущий лист</Button>}
                            </Box>*/}
                            </Box>
                            <Typography
                                sx={{
                                    marginBottom: '3px',
                                }}
                                variant="body1" component="h3">
                                Резерв текущей карты
                            </Typography>
                            <ReservationTableCuttingItem
                                pageSize={100}
                                enableGrouping={false}
                                columnGrouping={['articleNumber']}
                                isColumnMaterialWarehouse={true}
                                expanded={false}
                                data={currentOrdersCuttingMapDataObjectReserve}
                                setData={setCurrentOrdersCuttingMapDataObjectReserve}
                                nameMaterial={nameMaterial}
                                isCalculation={isCalculation}
                            />
                            <Typography
                                sx={{
                                    marginBottom: '3px',
                                }}
                                variant="body1" component="h3">
                                Детали текущей карты
                            </Typography>
                            <DetailTable
                                data={currentOrdersCuttingMapDataObjectDetails}
                                setData={setCurrentOrdersCuttingMapDataObjectDetails}
                                unableCut={unableCut}
                                unableTurn={unableTurn}
                                setUnableCut={setUnableCut}
                                setUnableTurn={setUnableTurn}
                                selectedIds={selectedIds}
                                setSelectedIds={setSelectedIds}
                                modalStatusOpen={modalStatusOpen}
                                setModalStatusOpen={setModalStatusOpen}
                                status={status}
                                setStatus={setStatus}
                                typeStatus={typeStatus}
                                setTypeStatus={setTypeStatus}
                                isColumnOrder={true}
                                warehouse={'orders'}
                                expanded={true}
                                pageSize={100}
                                change={change}
                                setChange={setChange}
                                isError={isError}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='3'>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '0.5rem',
                                p: '0.5rem',
                                flexWrap: 'wrap',
                                flexDirection: 'column'
                            }}
                        >
                            <ReservationTableCuttingItem
                                pageSize={100}
                                enableGrouping={false}
                                columnGrouping={['articleNumber']}
                                isColumnMaterialWarehouse={true}
                                expanded={false}
                                data={dataMaterialReserve}
                                setData={setDataMaterialReserve}
                                nameMaterial={nameMaterial}
                                isCalculation={isCalculation}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='4'>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '0.5rem',
                                p: '0.5rem',
                                flexWrap: 'wrap',
                                flexDirection: 'column'
                            }}
                        >
                            <DetailTable
                                data={notUsedDetails}
                                setData={setNotUsedDetails}
                                unableCut={unableCut}
                                unableTurn={unableTurn}
                                setUnableCut={setUnableCut}
                                setUnableTurn={setUnableTurn}
                                selectedIds={selectedIds}
                                setSelectedIds={setSelectedIds}
                                modalStatusOpen={modalStatusOpen}
                                setModalStatusOpen={setModalStatusOpen}
                                status={status}
                                setStatus={setStatus}
                                typeStatus={typeStatus}
                                setTypeStatus={setTypeStatus}
                                isColumnOrder={true}
                                warehouse={'orders'}
                                expanded={true}
                                pageSize={100}
                                change={change}
                                setChange={setChange}
                                isError={isError}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='5'>
                        <SettingsCuttingModule
                            material={material}
                            setSupplierId={setSupplierId}
                            supplierId={supplierId}
                            supplierList={supplierList}
                            cutDefault={cutDefault}
                            setCutDefault={setCutDefault}
                            setDrawEdge={setDrawEdge}
                            drawEdge={drawEdge}
                            limitNumberTurns={limitNumberTurns}
                            setLimitNumberTurns={setLimitNumberTurns}
                            rotatePartInsideStrip={rotatePartInsideStrip}
                            setRotatePartInsideStrip={setRotatePartInsideStrip}
                            cutWeight={cutWeight}
                            setCutWeight={setCutWeight}
                            trimmingLeft={trimmingLeft}
                            setTrimmingLeft={setTrimmingLeft}
                            trimmingRight={trimmingRight}
                            setTrimmingRight={setTrimmingRight}
                            trimmingBottom={trimmingBottom}
                            setTrimmingBottom={setTrimmingBottom}
                            trimmingTop={trimmingTop}
                            setTrimmingTop={setTrimmingTop}
                            minBalanceUpToWholeSheet={minBalanceUpToWholeSheet}
                            setMinBalanceUpToWholeSheet={setMinBalanceUpToWholeSheet}
                            minACutOff={minACutOff}
                            setMinACutOff={setMinACutOff}
                            minBCutOff={minBCutOff}
                            setMinBCutOff={setMinBCutOff}
                        />
                    </TabPanel>
                    <TabPanel value='6'>
                        <Typography
                            sx={{
                                marginBottom: '3px',
                            }}
                            variant="body1" component="h3">
                            Настройки получаемых обрезков после раскроя
                        </Typography>
                        <SettingsCutOffCuttingModule
                            trimmingLeftCutOff={trimmingLeftCutOff}
                            setTrimmingLeftCutOff={setTrimmingLeftCutOff}
                            trimmingRightCutOff={trimmingRightCutOff}
                            setTrimmingRightCutOff={setTrimmingRightCutOff}
                            trimmingTopCutOff={trimmingTopCutOff}
                            setTrimmingTopCutOff={setTrimmingTopCutOff}
                            trimmingBottomCutOff={trimmingBottomCutOff}
                            setTrimmingBottomCutOff={setTrimmingBottomCutOff}
                        />
                    </TabPanel>
                </TabContext>
            </Box>
        );
    }
;

export default WorkingCuttingMapItem;
