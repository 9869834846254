export const menuData = [
    {roles: ["ROLE_CUSTOMER"], type: "myOrders", name: "Мои заказы"},
    {roles: ["ROLE_CUSTOMER"], type: "mySamples", name: "Мои шаблоны"},
    {roles: ["ROLE_CUSTOMER", "SYSTEM_ADMIN", "ROLE_ADMIN"], type: "systemSamples", name: "Шаблоны системы"},
    {roles: ["ROLE_CALCULATION_USER", "ROLE_ORGANIZATION_SUPER_USER"], type: "calculation", name: "Расчеты"},
    {roles: ["ROLE_ORDERS_USER", "ROLE_ORGANIZATION_SUPER_USER"], type: "orders", name: "Заказы"},
    {roles: ["ROLE_OPTIMIZATION_USER", "ROLE_ORGANIZATION_SUPER_USER"], type: "optimization", name: "Оптимизация"},
    {roles: ["ROLE_STORAGE_USER", "ROLE_ORGANIZATION_SUPER_USER"], type: "storage", name: "Склад"},
    {roles: ["ROLE_CUTTING_USER", "ROLE_ORGANIZATION_SUPER_USER"], type: "cutting", name: "Раскрой"},
    {roles: ["ROLE_PRODUCTION_USER", "ROLE_ORGANIZATION_SUPER_USER"], type: "production", name: "Производство"},
    {roles: ["ROLE_ECONOMIC_USER", "ROLE_ORGANIZATION_SUPER_USER"], type: "economic", name: "Экономика"},
]

export const subMenuData = [
    {
        roles: ["ROLE_CUSTOMER"],
        menuType: "myOrders",
        type: "customerOrders",
        name: "Заказы",
        hrefs: "/customer/orders"
    },
    {
        roles: ["ROLE_CUSTOMER"],
        menuType: "myOrders",
        type: "customerArchive",
        name: "Архив",
        hrefs: "/customer/archive"
    },
    {
        roles: ["ROLE_CUSTOMER"],
        menuType: "mySamples",
        type: "customerSamples",
        name: "Шаблоны",
        hrefs: "/customer/samples"
    },
    {
        roles: ["ROLE_CUSTOMER"],
        menuType: "mySamples",
        type: "customerSamplesGroup",
        name: "Группы",
        hrefs: "/customer/groups"
    },
    {
        roles: ["ROLE_CUSTOMER"],
        menuType: "mySamples",
        type: "customerSamplesEdges",
        name: "Кромки",
        hrefs: "/customer/samples/edges"
    },
    {
        roles: ["ROLE_CUSTOMER"],
        menuType: "systemSamples",
        type: "customerSystemSamples",
        name: "Шаблоны",
        hrefs: "/customer/system-samples"
    },
    {
        roles: ["SYSTEM_ADMIN", "ROLE_ADMIN"],
        menuType: "systemSamples",
        type: "customerSystemSamples",
        name: "Шаблоны",
        hrefs: "/admin/system-samples"
    },
    {
        roles: ["SYSTEM_ADMIN", "ROLE_ADMIN"],
        menuType: "systemSamples",
        type: "customerSystemSamplesGroup",
        name: "Группы",
        hrefs: "/admin/system-samples/groups"
    },
    {
        roles: ["SYSTEM_ADMIN", "ROLE_ADMIN"],
        menuType: "systemSamples",
        type: "customerSystemSamplesEdges",
        name: "Кромки",
        hrefs: "/admin/system-samples/edges"
    },
    {
        roles: ["ROLE_CALCULATION_REQUESTS_CUTTING_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "calculation",
        type: "ordersCloud",
        name: "Заказы из облака",
        hrefs: "/organization/orders-cloud"
    },
    {
        roles: ["ROLE_CALCULATION_REQUESTS_CUTTING_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "calculation",
        type: "calculationRequestsCutting",
        name: "Заявки на раскрой",
        hrefs: "/organization/requests-cutting/Calculation"
    },
    {
        roles: ["ROLE_CALCULATION_OPTIMIZATIONS_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "calculation",
        type: "calculationOptimizations",
        name: "Оптимизации на просчет",
        hrefs: "/organization/optimizations-Calculation"
    },
    {
        roles: ["ROLE_CALCULATION_WORKING_CUTTING_MAPS_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "calculation",
        type: "calculationWorkingCuttingMaps",
        name: "Карты раскроя на просчет",
        hrefs: "/organization/working-cutting-maps-Calculation"
    },
    {
        roles: ["ROLE_ORDERS_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "orders",
        type: "orders",
        name: "Заказы",
        hrefs: "/organization/orders"
    },
    {
        roles: ["ROLE_ORDERS_CONSTRUCTOR_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "orders",
        type: "ordersConstructors",
        name: "Проектирование",
        hrefs: "/organization/constructors"
    },
    {
        roles: ["ROLE_ORDERS_TO_ARCHIVE_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "orders",
        type: "ordersOrdersToArchive",
        name: "На списание",
        hrefs: "/organization/for-write-off"
    },
    {
        roles: ["ROLE_ORDERS_ARCHIVE_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "orders",
        type: "ordersOrdersArchive",
        name: "Архив",
        hrefs: "/organization/archive"
    },
    {
        roles: ["ROLE_OPTIMIZATION_REQUESTS_CUTTING_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "optimization",
        type: "optimizationRequestsCutting",
        name: "Заявки на раскрой",
        hrefs: "/organization/requests-cutting"
    },
    {
        roles: ["ROLE_OPTIMIZATION_REQUESTS_CUSTOMIZED_MATERIAL", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "optimization",
        type: "optimizationRequestsCustomizedMaterials",
        name: "Заявки на индивидуальные изделия",
        hrefs: "/organization/requests-customized-materials"
    },
    {
        roles: ["ROLE_OPTIMIZATION_CUSTOMIZED_MATERIALS_DETAILS_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "optimization",
        type: "optimizationCustomizedMaterialsDetails",
        name: "Заявки на заказные материалы",
        hrefs: "/organization/requests-customized-materials-details"
    },
    {
        roles: ["ROLE_OPTIMIZATION_PLAN_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "optimization",
        type: "optimizationPlan",
        name: "Планы",
        hrefs: "/organization/plans"
    },
    {
        roles: ["ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "optimization",
        type: "optimizationOptimization",
        name: "Оптимизации",
        hrefs: "/organization/optimizations"
    },
    {
        roles: ["ROLE_OPTIMIZATION_OPTIMIZATION_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "storage",
        type: "ordersOrdersToArchive",
        name: "Материалы",
        hrefs: "/organization/materials"
    },
    {
        roles: ["ROLE_STORAGE_REQUESTS_PURCHASE_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "storage",
        type: "storageRequestsPurchase",
        name: "Заявки на закупку",
        hrefs: "/organization/requests-purchase"
    },
    {
        roles: ["ROLE_STORAGE_RESERVATION_PIECE_MATERIAL_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "storage",
        type: "storageReservationPieceMaterial",
        name: "Резервирование фурнитуры",
        hrefs: "/organization/reservation-piece-material"
    },
    {
        roles: ["ROLE_STORAGE_RESERVATION_SLAB_MATERIAL_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "storage",
        type: "storageReservationSlabMaterial",
        name: "Резервирование плитных материалов",
        hrefs: "/organization/reservation-slab-material"
    },
    {
        roles: ["ROLE_STORAGE_RESERVATION_LINEAR_MATERIAL_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "storage",
        type: "storageStorageReservationLinearMaterial",
        name: "Резервирование погонных материалов",
        hrefs: "/organization/reservation-linear-material"
    },
    {
        roles: ["ROLE_STORAGE_MATERIALS_ARRIVAL_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "storage",
        type: "storageStorageMaterialsArrival",
        name: "Заявки на поставку",
        hrefs: "/organization/materials-arrival"
    },
    {
        roles: ["ROLE_STORAGE_CURRENT_CURRENCIES_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "storage",
        type: "storageStorageCurrentCurrencies",
        name: "Курсы валют",
        hrefs: "/organization/current-currencies"
    },
    {
        roles: ["ROLE_CUTTING_WORKING_CUTTING_MAPS_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "cutting",
        type: "cuttingWorkingCuttingMaps",
        name: "Карты раскроя",
        hrefs: "/organization/working-cutting-maps"
    },
    {
        roles: ["ROLE_CUTTING_QUEUE_CUTTING_MAPS_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "cutting",
        type: "cuttingQueueCuttingMaps",
        name: "Порядок раскроя",
        hrefs: "/organization/queue-cutting-maps"
    },
    {
        roles: ["ROLE_WAREHOUSE_FINISHED_DETAIL_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "cutting",
        type: "cuttingWarehouseFinishedDetail",
        name: "Склад готовых деталей",
        hrefs: "/organization/warehouse-finished-detail"
    },
    {
        roles: ["ROLE_PRODUCTION_PRODUCTION_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "production",
        type: "productionProduction",
        name: "Производство",
        hrefs: "/organization/orders-production"
    },
    {
        roles: ["ROLE_PRODUCTION_ISSUING_EDGES_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "production",
        type: "productionIssuingEdges",
        name: "Выдача производсвенных материалов",
        hrefs: "/organization/issuing-edges",
    },
    {
        roles: ["ROLE_PRODUCTION_ISSUANCE-PIECE-MATERIALS_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "production",
        type: "productionIssuancePieceMaterials",
        name: "Выдача штучных материалов",
        hrefs: "/organization/issuance-piece-materials/"
    },
    {
        roles: ["ROLE_ECONOMIC_IN_WORK_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "economic",
        type: "economicOrders",
        name: "Заказы",
        hrefs: "/organization/orders/economic"
    },
    {
        roles: ["ROLE_ECONOMIC_ISSUANCE_MATERIAL_RESERVE_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "economic",
        type: "economicIssuanceMaterialReserve",
        name: "Выдачи",
        hrefs: "/organization/issuance-material-reserve"
    },
    {
        roles: ["ROLE_ECONOMIC_IN_WORK_USER", "ROLE_ORGANIZATION_SUPER_USER"],
        menuType: "economic",
        type: "economicInWork",
        name: "В работе",
        hrefs: "/organization/in-work"
    },
]
