import React, {useEffect, useState} from 'react';
import TypeJobOrganizationTable from "../../../components/UI/table/settings/TypeJobOrganizationTable";
import TypeJobOrganizationService from "../../../API/orders/job/TypeJobOrganizationService";

const TypeJobOrganization = ({workers}) => {
    const [data, setData] = useState([]);
    const [change, setChange] = useState(false)

    useEffect(() => {
        TypeJobOrganizationService.getAllOrganization().then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    return (
        <div>
            <TypeJobOrganizationTable
                data={data}
                setData={setData}
                workers={workers}
                setChange={setChange}
            />
        </div>
    );
};

export default TypeJobOrganization;