import {
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import ButtonAdd from "../../../../button/ButtonAdd";
import ButtonExit from "../../../../button/ButtonExit";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";

const JobNameModal = ({open, onClose, handleSubmit, initValue, title}) => {
    const [formState, setFormState] = useState({name: ""})
    const [validationState, setValidationState] = useState({name: ""})

    useEffect(() => {
        setFormState(initValue)
    }, [initValue])

    const handleClose = () => {
        setFormState({name: ""})
        setValidationState({name: ""})
        onClose()
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)
        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState)
            handleClose()
        }
    }
    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <TextFieldValidation
                    name={"name"}
                    value={formState?.name}
                    label="Наименование"
                    setCurrValues={setFormState}
                    validationError={validationState?.name}
                    setValidationState={setValidationState}
                />
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={"Выбрать"} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
}
export default JobNameModal