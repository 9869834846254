import React from "react";
import Hole from "./Hole";
import {COLORS} from "../../../../../../Constants";
import LineElement from "./LineElement";
import {calcSidePos} from "../CanvasCalcPositions/calcSidePos";
import {calcSideHolePos} from "../CanvasCalcPositions/calcSideHolePos";
import Layer from "./Layer";
import Notch from "./Notch";
import {Border} from "./Border";
import {calcGroovesOnSidePos} from "../../../../../../utils/processing/calcGroovesOnSidePos";
import {calcPrevGroove} from "../CanvasCalcPositions/calcPrevGroove";
import * as THREE from "three";

const EndFace = ({
                     reaOnly,
                     side,
                     holes,
                     groovesOnSide,
                     detailData,
                     selectedHole,
                     setSelectedHole,
                     setHoveredHoleId,
                     processingHandle,
                     texture,
                     setHoveredGrooveId,
                     editableElement,
                     scale,
                     position,
                     prevInnerContour,
                     hoveredSideHole
                 }) => {

    const {xPos, yPos, x, y} = calcSidePos(side, detailData, scale)

    const {
        scaleRadius: prevScaleRadius,
        scaleX: prevScaleX,
        scaleY: prevScaleY
    } = prevInnerContour.type === "round" && calcSideHolePos(prevInnerContour.contour, prevInnerContour.contour?.position, detailData, scale, xPos, yPos, x, y, prevInnerContour.contour.radius)

    const border = [
        [[0, 0], [0, y]],
        [[0, y], [x, y]],
        [[x, y], [x, 0]],
        [[x, 0], [0, 0]],
    ]

    return (
        <Layer layer={0}>
            <mesh
                position={[xPos + position[0], yPos + position[1], 0]}
            >
                <boxGeometry args={[x, y, 0]}/>
                <meshBasicMaterial color={"#EAEBEA"} toneMapped={false}/>

                {holes?.map((el, i) => {
                    const {
                        scaleRadius,
                        scaleX,
                        scaleY
                    } = calcSideHolePos(el.center, side, detailData, scale, xPos, yPos, x, y, el.radius)

                    return <Hole
                        reaOnly={reaOnly}
                        key={"hole-" + el.id}
                        data={{
                            id: el.id,
                            x: scaleX,
                            y: scaleY,
                            z: 0,
                            name: el.name,
                            radius: scaleRadius,
                            color: "#2C3038"
                        }}
                        side={true}
                        processingHandle={processingHandle}
                        selectedHole={selectedHole}
                        setSelectedHole={setSelectedHole}
                        setHoveredHole={setHoveredHoleId}
                        hoveredSideHole={hoveredSideHole}/>
                })}
                {
                    prevInnerContour.type === "groove" && prevInnerContour.contour?.position === side &&
                    calcPrevGroove(prevInnerContour.contour, detailData.height, {
                        x: x / scale,
                        y: y / scale
                    }).lines.map((el) => {
                        return <Layer layer={1} key={el.id}>
                            <LineElement
                                width={4}
                                points={[el.line[0], el.line[1]]}
                                position={[x / 2 - x, y / 2 - y, 0]}
                                color={COLORS[6][800]}
                                scale={scale}
                                dashSize={0.05} gapSize={0.05}
                            />
                        </Layer>
                    })
                }
                {prevInnerContour.type === "round" && <Layer layer={3}><LineElement
                    width={4}
                    points={new THREE.EllipseCurve(prevScaleX / scale, prevScaleY / scale, prevScaleRadius / scale, prevScaleRadius / scale, 2 * Math.PI, false, 0).getPoints(100)}
                    position={[0, 0, 0]}
                    scale={scale}
                    color={COLORS[6][800]}
                    dashSize={0.05} gapSize={0.05}
                /></Layer>}

                {/*{border.map((el, i) => {*/}
                {/*    return <LineElement*/}
                {/*        key={"border-" + i}*/}
                {/*        position={[x / 2 - x, y / 2 - y, 0]}*/}
                {/*        points={[el[0], el[1]]}*/}
                {/*        color={"black"}*/}
                {/*    />*/}
                {/*})}*/}

                {groovesOnSide?.map((el, i) => {
                    const contour = el.data.sideContours ? el.data.sideContours : el.data.contour
                    const isGroove = el.data.name.includes("groove")
                    const isQuarter = el.data.name.includes("quarter")

                    const notThroughGroove =
                        (side === "LEFT" || side === "RIGHT") && el.data.length !== detailData.length ||
                        (side === "FRONT" || side === "BACK") && (el.data.width !== detailData.width)

                    const notThroughQuarter =
                        (side === "LEFT" || side === "RIGHT") && el.data.length !== detailData.length ||
                        (side === "FRONT" || side === "BACK") && (el.data.length !== detailData.width)

                    const notThrough = isGroove ? notThroughGroove : notThroughQuarter

                    return <React.Fragment key={"groove-" + i}>
                        <Notch
                            reaOnly={reaOnly}
                            notch={el}
                            detailData={detailData}
                            scale={scale}
                            position={[x / 2 - x, y / 2 - y, 0]}
                            texture={texture}
                            isGroove={isGroove}
                            isQuarter={isQuarter}
                            setHoveredGrooveId={setHoveredGrooveId}
                            processingHandle={processingHandle}
                            notThrough={notThrough}
                        />
                        {contour.points.map((point, i, arr) => {
                            const {
                                start,
                                next
                            } = calcGroovesOnSidePos(contour.position, x / scale, y / scale, point, arr[i + 1], detailData.height)

                            return i < (isQuarter && !notThrough ? arr.length - 3 : arr.length - 1) &&
                                <Border
                                    reaOnly={reaOnly}
                                    key={"border2-" + i}
                                    start={start}
                                    next={next}
                                    editableElement={editableElement}
                                    processingHandle={processingHandle}
                                    position={[x / 2 - x, y / 2 - y, 0]}
                                    isGroove={true}
                                    scale={scale}
                                />
                        })}
                    </React.Fragment>
                })}

            </mesh>
        </Layer>)
}
export default EndFace
