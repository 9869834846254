import React, {useEffect, useState} from 'react';
import EstimateTableItem from "../../../../components/UI/table/orders/organization/estimate/EstimateTableItem";
import {useNavigate} from "react-router-dom";
import ProductionCosts from "../../../../components/UI/table/orders/organization/estimate/ProductionCosts";
import CalculationTable from "../../../../components/UI/table/orders/organization/estimate/CalculationTable";
import JobMetricOrderOrganizationTable
    from "../../../../components/UI/table/orders/organization/estimate/JobMetricOrderOrganizationTable";
import MaterialEstimateUnitService from "../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import MaterialCostTable from "../../../../components/UI/table/orders/organization/estimate/MaterialCostTable";
import MenuItem from "@mui/material/MenuItem";
import {Button, TextField} from "@mui/material";
import {ButtonGroupTabBar} from "../../../../components/UI/button/ButtonGroupTabBar";
import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";

const EstimateItem = ({
                          orderId,
                          orderNumber,
                          orderHeaderId,
                          year,
                      }) => {
    const profile = JSON.parse(localStorage.getItem("profile"))
    const currency = [
        {tab: 1, type: "baseCurrency", value: profile?.baseCurAbbreviation},
        {tab: 2, type: "alternativeCurrency", value: profile?.alternativeCurAbbreviation}]
    const cost = [
        {tab: 1, value: "Расчетная"},
        {tab: 2, value: "Фактическая"}]

    const [currentEstimateState, setCurrentEstimateState] = useState()

    const [JobCalculationMetricOrderOrganization, setJobCalculationMetricOrderOrganization] = useState([]);
    const [JobWorkMetricOrderOrganization, setJobWorkMetricOrderOrganization] = useState([]);
    const [calculationData, setCalculationData] = useState([]);
    const [workData, setWorkData] = useState([]);
    const [productionCostsCalculation, setProductionCostsCalculation] = useState([]);
    const [productionCostsWork, setProductionCostsWork] = useState([]);

    const [tab, setTab] = useState(1);
    const [costType, setCostType] = useState(1);
    const [currencyTab, setCurrencyTab] = useState(1);

    useEffect(() => {
        MaterialEstimateUnitService.getAllByOrderId(orderId)
            .then(function (response) {
                const fetchedData = response.data;
                const {calculation, others} = fetchedData.reduce((acc, item) => {
                    if (item.materialEstimateUnitState === 'CALCULATION') {
                        acc.calculation.push(item);
                    } else {
                        acc.others.push(item);
                    }
                    return acc;
                }, {calculation: [], others: []});
                setCalculationData(calculation.length > 0 ? calculation : []);
                setWorkData(others.length > 0 ? others : []);

                const calculationWithJobMetric = [];
                const prCostsCalculation = [];
                calculation.forEach(item => {
                    let jobMetricOrderOrganizations = item.jobMetricOrderOrganizations;
                    if (jobMetricOrderOrganizations) {
                        calculationWithJobMetric.push(...item.jobMetricOrderOrganizations);
                        jobMetricOrderOrganizations.forEach(item => {
                            let productionCosts = item.productionCosts
                            if (Array.isArray(productionCosts)) {
                                prCostsCalculation.push(...productionCosts);
                            }
                        });
                    }
                });
                const othersWithJobMetric = [];
                const prCostsWork = [];
                others.forEach(item => {
                    let jobMetricOrderOrganizations = item.jobMetricOrderOrganizations;
                    if (jobMetricOrderOrganizations) {
                        othersWithJobMetric.push(...item.jobMetricOrderOrganizations);
                        jobMetricOrderOrganizations.forEach(item => {
                            let productionCosts = item.productionCosts
                            if (Array.isArray(productionCosts)) {
                                prCostsWork.push(...productionCosts);
                            }
                        });
                    }
                });

                setJobCalculationMetricOrderOrganization(calculationWithJobMetric);
                setJobWorkMetricOrderOrganization(othersWithJobMetric)
                setProductionCostsCalculation(prCostsCalculation)
                setProductionCostsWork(prCostsWork);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [orderId]);

    const navigate = useNavigate();

    const redirectOrder = () => {
        navigate('/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year);
    };

    const generalTabComponents = [
        {
            tabIndex: 1, label: "Итоги",
            component: <CalculationTable
                data={costType === 1 ? calculationData : workData}
                setData={costType === 1 ? setCalculationData : setWorkData}
                currency={currency}
                currentCurrency={currencyTab}
                orderId={orderId}
                orderNumber={orderNumber}
                orderHeaderId={orderHeaderId}
                year={year}
                isActual={true}
                inWork={currentEstimateState === 'IN_WORK'}
                isFact={costType === 2}
                redirectOrder={redirectOrder}
            />
        },
        {
            tabIndex: 2, label: "Себестоимость материалов",
            component: <EstimateTableItem
                data={costType === 1 ? calculationData : workData}
                setData={costType === 1 ? setCalculationData : setWorkData}
                currency={currency}
                currentCurrency={currencyTab}
                orderId={orderId}
                orderNumber={orderNumber}
                orderHeaderId={orderHeaderId}
                year={year}
                isButtonPurchase={costType === 1}
                enableEditing={true}
                isActual={true}
                inWork={currentEstimateState === 'IN_WORK'}
                orderCloud={false}
                isFact={costType === 2}
            />
        },
        {
            tabIndex: 3, label: "Себестоимость работ",
            component: <ProductionCosts
                data={costType === 1 ? productionCostsCalculation : productionCostsWork}
                jobData={costType === 1 ? JobCalculationMetricOrderOrganization : JobWorkMetricOrderOrganization}
                setData={costType === 1 ? setProductionCostsCalculation : setProductionCostsWork}
                currency={currency}
                currentCurrency={currencyTab}
            />
        },
        {
            tabIndex: 4, label: "Стоимость материалов",
            component: <MaterialCostTable
                materialEstimateUnits={costType === 1 ? calculationData : workData}
                setMaterialEstimateUnits={costType === 1 ? setCalculationData : setWorkData}
                currency={currency}
                currentCurrency={currencyTab}
                orderId={orderId}
                orderNumber={orderNumber}
                orderHeaderId={orderHeaderId}
                year={year}
                isButtonPurchase={true}
                enableEditing={true}
                isActual={true}
            />
        },
        {
            tabIndex: 5, label: "Стоимость работ",
            component: <JobMetricOrderOrganizationTable
                data={costType === 1 ? JobCalculationMetricOrderOrganization : JobWorkMetricOrderOrganization}
                setData={costType === 1 ? setJobCalculationMetricOrderOrganization : setJobWorkMetricOrderOrganization}
                orderId={orderId}
                currency={currency}
                currentCurrency={currencyTab}
            />
        },
    ];
    return (
        <>
            <ButtonGroupTabBar activeTab={tab}>
                {generalTabComponents.map(({tabIndex, label}, index) => (
                    <Button key={tabIndex} tabIndex={tabIndex} onClick={() => setTab(tabIndex)}>
                        <CheckIcon/>
                        {label}
                    </Button>
                ))}
            </ButtonGroupTabBar>
            <Box sx={{display: "flex", gap: "16px"}}>
                <TextField
                    sx={{width: "240px"}}
                    size="small"
                    select
                    label="Вид стоимости"
                    value={costType}
                    onChange={(e) => setCostType(e.target.value)}
                >
                    {cost.map((option) => (
                        <MenuItem key={option.tab} value={option.tab}>
                            {option.value}
                        </MenuItem>))}
                </TextField>
                <TextField
                    sx={{width: "240px"}}
                    size="small"
                    select
                    label="Валюта"
                    value={currencyTab}
                    onChange={(e) => setCurrencyTab(e.target.value)}
                >
                    {currency.map((option) => (
                        <MenuItem key={option.tab} value={option.tab}>
                            {option.value}
                        </MenuItem>))}
                </TextField>
            </Box>
            {generalTabComponents.find(component => component.tabIndex === tab)?.component}
        </>
    );
};

export default EstimateItem;