import axios from "axios";
import {apiVersionAuthorizationServer, gatewayUrl, nameAuthorizationServer} from "../../Constants";

const jobRestUrl = gatewayUrl + nameAuthorizationServer + apiVersionAuthorizationServer + '/organizations/addresses'

export default class AddressOrganizationService {

    static async getAllOrganizations() {
        return axios.get(jobRestUrl);
    }

    static async update(data, id) {
        return await axios.put(jobRestUrl + "/" + id,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async delete(id) {
        await axios.delete(jobRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async create(data) {
        return await axios.post(jobRestUrl,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }
}