import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {TabContext, TabPanel} from "@mui/lab";
import {Box, Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import JobMetricOrderOrganizationTable
    from "../../../components/UI/table/orders/organization/estimate/JobMetricOrderOrganizationTable";
import CustomerEstimateOrganizationCostMaterialTable
    from "../../../components/UI/table/orders/customer/order/CustomerEstimateOrganizationCostMaterialTable";
import CustomerMaterialEstimateUnitService
    from "../../../API/orders/estimate/customer/CustomerMaterialEstimateUnitService";
import CustomerEstimateOrganizationTotalMaterialTable
    from "../../../components/UI/table/orders/customer/order/CustomerEstimateOrganizationTotalMaterialTable";

const CustomerOrganizationEstimateItem = ({}) => {

    const params = useParams();
    const estimateId = params.estimateId;
    const [value, setValue] = useState('1');
    const [JobMetricOrderOrganization, setJobMetricOrderOrganization] = useState([]);
    const [materialEstimateUnit, setMaterialEstimateUnit] = useState([]);
    const [baseCurrency, setBaseCurrency] = useState(params.base);
    const [alternativeCurrency, setAlternativeCurrency] = useState(params.alternative);

    useEffect(() => {
        CustomerMaterialEstimateUnitService.getAllByEstimateOrganization(estimateId)
            .then(function (response) {
                const fetchedData = response.data;
                setMaterialEstimateUnit(fetchedData);
                const calculationWithJobMetric = [];
                fetchedData.forEach(item => {
                    let jobMetricOrderOrganizations = item.jobMetricOrderOrganizations;
                    if (jobMetricOrderOrganizations) {
                        calculationWithJobMetric.push(...item.jobMetricOrderOrganizations);
                    }
                });
                setJobMetricOrderOrganization(calculationWithJobMetric);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [estimateId]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    //TODO редеректнуть обратно на заказ
    //TODO редеректнуть обратно на предложения
    //TODOСозадть кнопку выбора (утвердить исполнителя

    /*    const redirectEstimate = () => {
            navigate('/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year + '/estimates');
        };

        const redirectOrder = () => {
            navigate('/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year);
        };

        const redirectOrders = () => {
            navigate('/organization/orders/');
        };

        const redirectRequestsCutting = () => {
            navigate('/organization/requests-cutting');
        };*/


    return (

        <Box sx={{
            '& > *': {
                marginTop: '0px',
            }
        }}>
            <TabContext value={value}>
                <Box sx={{
                    borderBottom: 1, borderColor: 'divider'
                }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="ИТОГИ" value='1'/>
                        <Tab label="Стоимость материалов" value='2'/>
                        <Tab label="Стоимость работ" value='3'/>
                    </Tabs>
                </Box>
                <TabPanel value='1'>
                    <CustomerEstimateOrganizationTotalMaterialTable
                        data={materialEstimateUnit}
                        setData={setMaterialEstimateUnit}
                        estimateId={estimateId}
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}
                    />
                </TabPanel>
                <TabPanel value='2'>
                    <CustomerEstimateOrganizationCostMaterialTable
                        data={materialEstimateUnit}
                        setData={setMaterialEstimateUnit}
                        estimateId={estimateId}
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}
                    />
                </TabPanel>
                <TabPanel value='3'>
                    <JobMetricOrderOrganizationTable
                        data={JobMetricOrderOrganization}
                        setData={setJobMetricOrderOrganization}
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

export default CustomerOrganizationEstimateItem;