import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const detailControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/customers/orders/'

export default class DetailControllerService {

    static async getAll(orderId, requestsCuttingId) {
        return axios.get(`${detailControllerRestUrl}${orderId}/details?requestsCuttingId=${requestsCuttingId}`);
    }

    static async getById(orderId, id) {
        return axios.get(`${detailControllerRestUrl}${orderId}/details/${id}`);
    }

    static async save(orderId, requestsCuttingId, data) {
        return await axios.post(`${detailControllerRestUrl}${orderId}/details?requestsCuttingId=${requestsCuttingId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(orderId, data) {
        await axios.delete(`${detailControllerRestUrl}${data.orderId}/details/${data.id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(orderId, id, data) {
        return await axios.put(`${detailControllerRestUrl}${orderId}/details/${id}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async resetContour(orderId, id) {
        return await axios.patch(`${detailControllerRestUrl}${orderId}/details/${id}/reset-contour`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByOrderId(orderId) {
        return axios.get(`${detailControllerRestUrl}${orderId}/details`);
    }

    static async prevAll(orderId, detailId, data) {
        return await axios.post(`${detailControllerRestUrl}${orderId}/details/${detailId}/variables/preview-all`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async exportFile(orderId, id, machineManufacturer) {
        return axios.get(`${detailControllerRestUrl}${orderId}/details/${id}/file?machineManufacturer=${machineManufacturer}`);
    }

    static async saveBySample(orderId, requestsCuttingId, data) {
        return await axios.post(`${detailControllerRestUrl}${orderId}/details/by-sample?requestsCuttingId=${requestsCuttingId}`,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

}
