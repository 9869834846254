import React, {useEffect, useState} from 'react';
import JobMetricOrganizationTable
    from "../../../components/UI/table/prodaction/jobmetricorganization/JobMetricOrganizationTable";
import JobMetricOrganizationService from "../../../API/orders/job/JobMetricOrganizationService";

const JobMetricOrganization = () => {

    const [change, setChange] = useState(false);
    const [dataJobMetricOrganization, setDataJobMetricOrganization] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [rowIndexSelection, setRowIndexSelection] = useState([])
    const [selectedIds, setSelectedIds] = useState([]);
    const profile = JSON.parse(localStorage.getItem("profile"))
    const [baseCurrency, setBaseCurrency] = useState(profile?.baseCurAbbreviation);
    const [alternativeCurrency, setAlternativeCurrency] = useState(profile?.alternativeCurAbbreviation);

    useEffect(() => {
        JobMetricOrganizationService.getAllJobMetricOrganizationForSettings().then(function (response) {
            setDataJobMetricOrganization(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    return (
        <div>
            <JobMetricOrganizationTable
                data={dataJobMetricOrganization}
                setData={setDataJobMetricOrganization}
                change={change}
                setChange={setChange}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                rowIndexSelection={rowIndexSelection}
                setRowIndexSelection={setRowIndexSelection}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                isChooseSignJob={false}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
            />
        </div>
    );
};

export default JobMetricOrganization;