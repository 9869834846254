import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const fileNameRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/fileNames'
const fileRestOrdersUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/files';

export default class FileService {

    static getFormData(selectedFile) {
        const formData = new FormData();
        formData.append("selectedFile", selectedFile);
        return formData;
    }

    static getFormDataArray(files) {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });
        return formData;
    }

    static async uploadFiles(year, nameOrder, orderId, fileNameType, files) {
        return await axios.post(fileRestOrdersUrl + '/upload/order-files' + "?orderId=" + orderId + "&nameOrder="
            + nameOrder + "&year=" + year + "&fileNameType=" + fileNameType,
            FileService.getFormDataArray(files), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

    static async uploadDetailFiles(files, requestsCuttingId) {
        return await axios.post(fileRestOrdersUrl + '/upload/detail-files/for-basis' + "?requestsCuttingId=" + requestsCuttingId,
            FileService.getFormDataArray(files), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

    static async uploadRequestsCuttingFiles(files, calculationRequestsCutting, orderId) {
        return await axios.post(fileRestOrdersUrl + '/upload/order-requests-cutting-files/for-basis' + "?orderId="
            + orderId + "&calculationRequestsCutting=" + calculationRequestsCutting,
            FileService.getFormDataArray(files), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

    static async deleteFileById(fileId) {
        return await axios.delete(fileRestOrdersUrl + '/delete' + "?fileId=" + fileId, {});
    }

    static async getAllProjectFileNamesByOrderId(orderId) {
        return axios.get(fileNameRestUrl + '/orders/' + orderId + '/project', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllMeasuringFileNamesByOrderId(orderId) {
        return axios.get(fileNameRestUrl + '/orders/' + orderId + '/measuring', {
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }

    static async update(data) {
        await axios.post(fileNameRestUrl + '/' + data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async downloadFile(fileId) {
        return axios.get(fileRestOrdersUrl + '/download' + "?fileId=" + fileId, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    static async uploadExelDataBaseOfMaterialsFile(selectedFile, overwriteDuplicateMaterial) {
        return await axios.post(fileRestOrdersUrl + '/upload/exel-data-base-of-materials'
            + "?overwriteDuplicateMaterial=" + overwriteDuplicateMaterial
            , FileService.getFormData(selectedFile)), {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };
    }

    static async uploadOrderEstimateFile(year, orderId, selectedFile) {
        return await axios.post(fileRestOrdersUrl + '/upload/exel-estimate'
            + "?orderId=" + orderId + "&year=" + year
            , FileService.getFormData(selectedFile), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

    static async uploadSignJobNameFile(selectedFile) {
        return await axios.post(fileRestOrdersUrl + '/upload/sign-job-name',
            FileService.getFormData(selectedFile), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

    static async uploadJobMetricOrganizationFile(selectedFile) {
        return await axios.post(fileRestOrdersUrl + '/upload/job-metric-organization',
            FileService.getFormData(selectedFile), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

    static async uploadSignJobFile(selectedFile) {
        return await axios.post(fileRestOrdersUrl + '/upload/sign-job',
            FileService.getFormData(selectedFile), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

    static async downloadGroupFile(selectedFile) {
        return await axios.post(fileRestOrdersUrl + '/upload/material-group',
            FileService.getFormData(selectedFile), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

    static async downloadMaterialsFile(selectedFile) {
        return await axios.post(fileRestOrdersUrl + '/upload/materials',
            FileService.getFormData(selectedFile), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

    static async downloadSupplierMaterialsFile(selectedFile) {
        return await axios.post(fileRestOrdersUrl + '/upload/supplier-materials',
            FileService.getFormData(selectedFile), {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }
}