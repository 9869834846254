import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../../Constants";

const samplesControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/admin/detail-samples/group'
export default class SamplesControllerServiceGroupAdmin {

    static async getAll() {
        return axios.get(`${samplesControllerRestUrl}/root`);
    }

    static async save(data) {
        return await axios.post(`${samplesControllerRestUrl}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(groupId) {

        return await axios.delete(`${samplesControllerRestUrl}/${groupId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async edit(groupId, data) {
        return await axios.put(`${samplesControllerRestUrl}/${groupId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

}