import axios from "axios";
import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";

const jobRestUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/user/job-metric-packing-material'

export default class JobMetricPacking {

    static async getJobMetricPackingByMaterialId(materialId) {
        return axios.get(jobRestUrl + '/by-material-id-for-catalog'
            + '?materialId=' + materialId);
    }
}