import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Step,
    StepLabel,
    Stepper
} from "@mui/material";
import Box from "@mui/material/Box";
import TextFieldValidation from "../../../customer-detales/TextFieldValidation";
import ButtonExit from "../../../../button/ButtonExit";
import ButtonAdd from "../../../../button/ButtonAdd";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import SuppliersTable from "./SuppliersTable";

export const SuppliersModal = ({open, title, initValue, onClose, onSubmit, suppliers}) => {
    const [activeStep, setActiveStep] = useState(0)
    const [formState, setFormState] = useState(initValue)
    const [validationState, setValidationState] = useState({name: ""})
    const [type, setType] = useState("1")
    const [selectedString, setSelectedString] = useState({})

    useEffect(() => {
        setFormState(initValue)
        if (initValue?.id && initValue.supplierMaterialCloudId) {
            setType("2")
            setSelectedString(suppliers.find(el => el.id === initValue.supplierMaterialCloudId))
        }
    }, [initValue])

    const handleEditType = (type) => {
        type === "1" && setSelectedString({})
        setType(type)
    }
    const handleClose = () => {
        setType("1")
        setActiveStep(0)
        setSelectedString({})
        setValidationState({name: ""})
        onClose()
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (name === "name" && value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit(formState)
            handleClose()
        }
    }

    const submit = () => {
        if (activeStep === 0) {
            setFormState({
                ...formState,
                supplierMaterialCloudId: selectedString?.id || null,
                name: initValue?.name || selectedString?.name || ""
            })
            setActiveStep(1)
        } else {
            handleValidationData()
        }
    }

    return (
        <Dialog open={open}>
            <Box style={{
                padding: 40,
                display: "flex",
                flexDirection: "column",
                gap: 16,
                alignItems: "center"
            }}>
                <DialogTitle sx={{
                    color: (theme) => theme.palette.text.main,
                    margin: "auto",
                    padding: 0,
                    fontSize: 24,
                    fontWeight: 700
                }}>{title}</DialogTitle>
                <Stepper
                    alternativeLabel
                    style={{width: "600px"}}
                    activeStep={activeStep}
                >
                    {["Поставщики", "Наиминование"].map((label) => (
                        <Step key={label} sx={{
                            "& .MuiStepConnector-root": {
                                top: "20px",
                                left: 'calc(-50% + 30px)',
                                right: "calc(50% + 30px)"
                            }
                        }}>
                            <StepLabel sx={{
                                ".MuiSvgIcon-root": {
                                    fontSize: 40,
                                    color: "#737478",
                                    "&.Mui-active": {
                                        color: "#3B8BEC",
                                    }
                                },
                                "& .MuiStepLabel-label": {
                                    fontSize: 18,
                                    "&.Mui-active": {
                                        fontWeight: 700,
                                    }
                                },

                            }}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <DialogContent>
                {activeStep === 0
                    ?
                    <Box style={{display: "flex", flexDirection: "column"}}>
                        <Box style={{width: "100%"}}>
                            <RadioGroup
                                defaultValue="female"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={type}
                                onChange={(event) => handleEditType(event.target.value)}
                            >
                                {[{id: 1, name: "Добавить нового поставщика"},
                                    {id: 2, name: "Выбрать из каталога"}].map((option) => (
                                        <FormControlLabel
                                            key={option.id}
                                            value={option.id}
                                            control={<Radio/>}
                                            label={option.name}
                                            sx={{
                                                width: "100%",
                                                height: "36px",
                                                "&:not(:last-of-type)": {
                                                    marginBottom: "4px",
                                                },
                                                borderBottom: (theme) => `${theme.palette.primary.stroke} solid 1px`,
                                                "&:hover": {
                                                    backgroundColor: (theme) => theme.palette.primary.light
                                                }
                                            }
                                            }

                                        />
                                    )
                                )}

                            </RadioGroup>
                            <SuppliersTable
                                data={suppliers} isModal={true} selectedString={selectedString}
                                setSelectedString={setSelectedString} disabled={type === "1"}/>
                        </Box>
                    </Box>
                    :
                    <TextFieldValidation
                        name={"name"}
                        value={formState?.name}
                        label="Наименование"
                        setCurrValues={setFormState}
                        validationError={validationState?.name}
                        setValidationState={setValidationState}
                    />}
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={"Выбрать"} onClick={submit}
                           disabled={activeStep === 0 && type === "2" && !selectedString.id}/>
            </DialogActions>
        </Dialog>
    );
};