export default class OrderProductionUtil {

    static getValueByHeader(data, header) {
        for (const row of data) {
            if (row !== null && row.typeJob !== null) {
                if (row.typeJob.name === header) {
                    return row.completionPercentage;
                }
            }
        }
        return null;
    }
}