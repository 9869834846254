import {
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
} from "@mui/material";
import ButtonAdd from "../../../button/ButtonAdd";
import ButtonExit from "../../../button/ButtonExit";
import React, {useEffect, useState} from "react";
import TextFieldValidation from "../../customer-detales/TextFieldValidation";
import MenuItem from "@mui/material/MenuItem";
import UtilityService from "../../../../../API/common/UtilityService";
import {calcDistance} from "../../../../../utils/processing/calcDistance";

const ModalAddJob = ({open, onClose, handleSubmit}) => {
    const [currencies, setCurrencies] = useState([])
    const [formState, setFormState] = useState({currency: currencies[0]?.curAbbreviation, price: ""})
    const [validationState, setValidationState] = useState({price: ""})

    useEffect(() => {
        UtilityService.getCurrencies()
            .then(function (response) {
                setCurrencies(response.data)
                setFormState({currency: response.data[0]?.curAbbreviation, price: ""})
            })
    }, []);

    const handleClose = () => {
        setFormState({currency: currencies[0]?.curAbbreviation, price: ""})
        setValidationState({price: ""})
        onClose()
    }
    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })
        setValidationState(newErrors)
        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState)
            handleClose()
        }
    }
    return (
        <Dialog open={open} PaperProps={{
            style: {
                minWidth: "400px",
                borderRadius: 8,
            }
        }}>
            <DialogTitle textAlign="center">Добавить работу</DialogTitle>
            <DialogContent>
                <TextFieldValidation
                    type={"number"}
                    name={"price"}
                    value={formState?.price}
                    label="Стимость"
                    setCurrValues={setFormState}
                    validationError={validationState?.price}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    select
                    name="currency"
                    label="Валюта"
                    value={formState.currency}
                    setCurrValues={setFormState}
                >
                    {currencies.map((option) => (
                        <MenuItem key={option.id} value={option.curAbbreviation}>
                            {option.curName}
                        </MenuItem>))}
                </TextFieldValidation>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <ButtonExit text={"Отменить"} onClick={handleClose}/>
                <ButtonAdd text={"Выбрать"} onClick={handleValidationData}/>
            </DialogActions>
        </Dialog>
    );
}
export default ModalAddJob