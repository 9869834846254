import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../Constants";

const jobUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/type-jobs'

export default class TypeJobOrganizationService {

    static async getAllOrganization() {
        return axios.get(jobUrl);
    }

    static async delete(id) {
        await axios.delete(jobUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data, id) {
        return await axios.put(jobUrl + '/' + id,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async create(data) {
        return await axios.post(jobUrl,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async unable(id, status) {
        return axios.patch(jobUrl + '/' + id + '/unable?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async addWorker(id, workerId) {
        return axios.patch(jobUrl + '/' + id + '/add-worker?workerId=' + workerId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async deleteWorker(id, workerId) {
        return axios.patch(jobUrl + '/' + id + '/delete-worker?workerId=' + workerId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async addSignJob(id, signJobId) {
        return axios.patch(jobUrl + '/' + id + '/add-sign-Job?signJobId=' + signJobId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async deleteSignJob(id, signJobId) {
        return axios.patch(jobUrl + '/' + id + '/delete-sign-Job?signJobId=' + signJobId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}