import {SimpleTreeView} from "@mui/x-tree-view/SimpleTreeView";
import {TreeItem} from "@mui/x-tree-view/TreeItem";
import React from "react";
import {CloseSquare, MinusSquare, PlusSquare} from "../../../components/UI/icons/IconsComponents";

const TreeViewSettings = ({activeSetting, setActiveSetting, expandedItems, handleExpandedItemsChange, userRoles}) => {
    return <SimpleTreeView
        aria-label="customized"
        selectedItems={[activeSetting]}
        expandedItems={expandedItems}
        onExpandedItemsChange={handleExpandedItemsChange}
        onItemClick={(e, itemId) => setActiveSetting(itemId)}
        slots={{
            expandIcon: PlusSquare,
            collapseIcon: MinusSquare,
        }}>
        <TreeItem itemId="profile" label={"Профиль"}/>
        {userRoles && <TreeItem itemId="settings" label={userRoles.name}>
            {userRoles.type === "organization" &&
                <>
                    <TreeItem itemId="warehouseSettings" label="Настройки склада">
                        <TreeItem itemId="materialGroups" label="Группы материалов"/>
                        <TreeItem itemId="material" label="Материалы"/>
                        <TreeItem itemId="imports" label="Импорт"/>
                    </TreeItem>
                    <TreeItem itemId="jobSettings" label="Настройки работ">
                        <TreeItem itemId="listJob" label="Найменования работ"/>
                        <TreeItem itemId="listJobName" label="Найменования затрат по работам"/>
                        <TreeItem itemId="listTypeJob" label="Группы работ"/>
                        <TreeItem itemId="importJob" label="Импорт"/>
                    </TreeItem>
                    <TreeItem itemId="orderSettings" label="Настройки заказа">
                        <TreeItem itemId="headers" label="Заголовки заказов"/>
                        <TreeItem itemId="attributes" label="Aтрибуты заказов"/>
                    </TreeItem>
                    <TreeItem itemId="modelSettings" label="Настройки моделей">
                        <TreeItem itemId="packagingUnits" label="Единицы упаковки"/>
                        <TreeItem itemId="packagingSets" label="Комплекты упаковки"/>
                        <TreeItem itemId="modelHeaders" label="Найменования моделей"/>
                        <TreeItem itemId="basicModels" label="Базовые модели"/>
                        <TreeItem itemId="models" label="Модели"/>
                    </TreeItem>
                    <TreeItem itemId="workerSettings" label="Настройки сотрудников">
                        <TreeItem itemId="workers" label="Работники"/>
                    </TreeItem>
                    <TreeItem itemId="organizationCustomersSettings" label="Настройки заказчиков">
                        <TreeItem itemId="organizationCustomers" label="Заказчики"/>
                    </TreeItem>
                    <TreeItem itemId="organizationSuppliersSettings" label="Настройки поставщиков">
                        <TreeItem itemId="organizationSuppliers" label="Поставщики"/>
                    </TreeItem>
                    <TreeItem itemId="adressOrganization" label="Адреса">
                        <TreeItem itemId="addressOrganizationList" label="Список адресов"/>
                    </TreeItem>
                </>}
        </TreeItem>}
    </SimpleTreeView>
}
export default TreeViewSettings