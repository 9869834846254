import React, {memo, useEffect, useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Step,
    StepLabel,
    Stepper
} from "@mui/material";
import TextFieldValidation from "./TextFieldValidation";
import {constantsOfInputValues} from "../../../../Constants";
import ButtonExit from "../../button/ButtonExit";
import ButtonAdd from "../../button/ButtonAdd";
import TemplateControllerServiceGroup from "../../../../API/samples/Customers/SamplesGroup";
import GroupMaterialUtil from "../../../../utils/orders/GroupMaterialUtil";
import Box from "@mui/material/Box";
import MaterialTree from "../../tree/MaterialTree";
import ButtonBack from "../../button/ButtonBack";

const steps = [
    'Группа',
    'Деталь',
];
const ModalAddDetail = ({
                            initValue,
                            initValueGroup,
                            open,
                            onClose,
                            title,
                            labelButtonSubmit,
                            handleSubmit,
                            uniqueNames,
                            samples,
                            handleError,
                            materialTreeData
                        }) => {

        const [activeStep, setActiveStep] = useState(0)

        const [formState, setFormState] = useState(initValue)
        const [validationState, setValidationState] = useState(initValue);

        const [currentGroup, setCurrentGroup] = useState(initValueGroup);
        const [filterGroup, setFilterGroup] = useState(new Map());
        const [changeFilterGroup, setChangeFilterGroup] = useState(false);

        const {MAX_LENGTH, MAX_WIDTH} = constantsOfInputValues

        useEffect(() => {
            setFormState(initValue)
            setCurrentGroup(initValueGroup)
            setValidationState(Object.keys(initValue).reduce((acc, it) => ({...acc, [it]: ""}), {}))
        }, [initValue, initValueGroup])

        const handleClose = () => {
            onClose()
            setActiveStep(0)
        }
        const submit = () => {
            if (activeStep === 0 && samples) {
                setActiveStep(1)
            } else {
                handleValidationData()
            }
        }
        const handleValidationData = () => {
            const newErrors = {...validationState}

            Object.entries(formState).forEach(([name, value]) => {
                if (name === "position" && uniqueNames.includes(value)) {
                    newErrors.position = "значение должно быть уникальным"
                } else if (name === "length" && (Number(value) > MAX_LENGTH || Number(value) < 1)) {
                    newErrors.length = `значени должно быть от 1 до ${MAX_LENGTH}`
                } else if (name === "width" && (Number(value) > MAX_WIDTH || Number(value) < 1)) {
                    newErrors.width = `значени должно быть от 1 до ${MAX_WIDTH}`
                }

                if (value === "") {
                    newErrors[name] = "поле не должно быть пустым"
                }
            })

            setValidationState(newErrors)

            if (Object.values(newErrors).every(el => el === "")) {
                if (samples) {
                    handleSubmit({
                        dto: {...formState, quantity: 1},
                        "groupId": currentGroup.id,
                        "name": currentGroup.name
                    })
                } else {
                    handleSubmit(formState)
                }
                handleClose()
            }
        }
        return (
            <Dialog open={open}>
                <Box style={{
                    padding: 40,
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    justifyContent: "space-between"
                }}>
                    <DialogTitle sx={{
                        color: (theme) => theme.palette.text.main,
                        margin: "auto",
                        padding: 0,
                        fontSize: 24,
                        fontWeight: 700
                    }}>{title}</DialogTitle>
                    {samples && <Stepper
                        style={{width: 446}}
                        activeStep={activeStep}
                    >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel sx={{
                                    ".MuiSvgIcon-root": {
                                        fontSize: 40,
                                        color: "#737478",
                                        "&.Mui-active": {
                                            color: "#3B8BEC",
                                        }
                                    },
                                    "& .MuiStepLabel-label": {
                                        fontSize: 18,
                                        "&.Mui-active": {
                                            fontWeight: 700,
                                        }
                                    }
                                }}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>}
                </Box>
                <DialogContent sx={{padding: "0 24px", margin: 0}}>
                    {activeStep === 0 && samples ?
                        <Box sx={{padding: "0 40px"}}>
                            <MaterialTree
                                name={"Группы"}
                                filterGroup={filterGroup}
                                setFilterGroup={setFilterGroup}
                                changeFilterGroup={changeFilterGroup}
                                setChangeFilterGroup={setChangeFilterGroup}
                                data={materialTreeData.dataTree}
                                groupMap={materialTreeData.groupMap}
                                currentGroup={currentGroup}
                                setCurrentGroup={setCurrentGroup}
                            />
                        </Box>
                        :
                        <form onSubmit={(e) => e.preventDefault()}>
                            <Stack
                                sx={{
                                    width: '100%',
                                    minHeight: "365px",
                                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                }}
                            >
                                <TextFieldValidation
                                    name={"position"}
                                    label={"Позиция"}
                                    value={formState?.position}
                                    setCurrValues={setFormState}
                                    validationError={validationState?.position}
                                    setValidationState={setValidationState}
                                />
                                <TextFieldValidation
                                    name={"name"}
                                    label={"Наименование"}
                                    value={formState?.name}
                                    setCurrValues={setFormState}
                                    validationError={validationState?.name}
                                    setValidationState={setValidationState}
                                />
                                <TextFieldValidation
                                    type="number"
                                    name={"length"}
                                    label={"Длина"}
                                    value={formState?.length}
                                    setCurrValues={setFormState}
                                    validationError={validationState?.length}
                                    setValidationState={setValidationState}
                                />
                                <TextFieldValidation
                                    type="number"
                                    name={"width"}
                                    label={"Ширина"}
                                    value={formState?.width}
                                    setCurrValues={setFormState}
                                    validationError={validationState?.width}
                                    setValidationState={setValidationState}
                                />
                                {!samples && <TextFieldValidation
                                    type="number"
                                    name={"quantity"}
                                    label={"Количество деталей"}
                                    value={formState?.quantity}
                                    setCurrValues={setFormState}
                                    validationError={validationState?.quantity}
                                    setValidationState={setValidationState}
                                />}

                            </Stack>
                        </form>}
                </DialogContent>
                <DialogActions style={{padding: 40, display: "flex", justifyContent: "space-between"}}>
                    <Box>{activeStep !== 0 && samples &&
                        <ButtonBack text={"Назад"} onClick={() => setActiveStep(0)}/>} </Box>
                    <Box>
                        <ButtonExit text={"Выйти"} onClick={handleClose}/>
                        <ButtonAdd text={"Выбрать"} onClick={submit} disabled={!currentGroup?.id && samples}/>
                    </Box>
                </DialogActions>
            </Dialog>
        )
            ;
    }
;

export default ModalAddDetail;
