import React from 'react';
import {Slide, Snackbar} from "@mui/material";
import {Alert} from '@mui/material'

const ModalAlert = ({data, onClose}) => {

    return (
        <Snackbar
            open={data.open} onClose={onClose}
            TransitionComponent={Slide} autoHideDuration={5000}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert severity={data.type}>
                {data.message}
            </Alert>
        </Snackbar>
    )
}
export default ModalAlert
