import React, {useState} from "react";
import EstimateItem from "./EstimateItem";
import {useParams} from "react-router-dom";
import {BreadcrumbsComponent} from "../../../../components/UI/breadcrumb/BreadcrumbComponent";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {estimateStatuses} from "../../../../data/EstimateState";

const OrganizationEstimate = () => {

    const params = useParams();
    const year = params.year;
    const orderNumber = params.orderNumber;
    const orderId = params.orderId;
    const orderHeaderId = params.orderHeaderId;
    const state = params.state;

    const [currentState, setCurrentState] = useState(state);
    const [isLoading, setIsLoading] = useState(true);

    return (
        <Box sx={{padding: "0 30px"}}>
            <BreadcrumbsComponent
                page={"Смета"}
                path={[
                    {name: "Главная", href: "/"},
                    {name: "Заказы", href: "/organization/orders"},
                    {
                        name: `${orderNumber}`,
                        href: `/organization/orders/${orderId}/${orderNumber}/${orderHeaderId}/${year}`
                    }
                ]}/>
            <Box sx={{display: "flex", flexDirection: "column", gap: "32px"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="p" sx={{fontSize: "24px", fontWeight: 700}}>
                        Заказ № {orderNumber}
                    </Typography>
                    <Typography>{`Статус: ${estimateStatuses[state]}`}</Typography>
                </Box>
                <EstimateItem
                    orderId={orderId}
                    isButtonPurchase={true}
                    enableEditing={true}
                    isActual={true}
                    inWork={true}
                    orderCloud={true}
                    isImport={true}
                    detailed={false}
                    orderNumber={orderNumber}
                    orderHeaderId={orderHeaderId}
                    year={year}
                    setIsLoading
                />
            </Box>
            {/* <ModalAlert data={alertData} onClose={handleCloseAlert}/>*/}
        </Box>
    )
};

export default OrganizationEstimate;