import React, {useState} from 'react';
import {Checkbox} from "@mui/material";


const CellCheckboxCurrent = ({cell}) => {

    const [status, setStatus] = useState(cell.getValue);

    return (
        <div>
            <Checkbox
                color="success"
                checked={status}
                sx={{'& .MuiSvgIcon-root': {fontSize: 20}}}
            />
        </div>
    );
};

export default CellCheckboxCurrent;