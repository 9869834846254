import React from "react";
import GroupMaterialUtil from "../../../utils/orders/GroupMaterialUtil";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles"
import {TreeItem, treeItemClasses} from '@mui/x-tree-view/TreeItem';
import Collapse from '@mui/material/Collapse';
import {SimpleTreeView} from '@mui/x-tree-view/SimpleTreeView';
import {CloseSquare, MinusSquare, PlusSquare} from "../icons/IconsComponents";

const MaterialTree = ({
                          name, filterGroup, setFilterGroup, changeFilterGroup,
                          setChangeFilterGroup, data, groupMap, currentGroup, setCurrentGroup, readOnly
                      }) => {

    const handleToggle = (node) => {
        let id = parseInt(node?.id);
        const group = groupMap.get(id) || {}
        if (group !== null) {
            GroupMaterialUtil.addToFilterMapGroup(group, filterGroup)
            setFilterGroup(filterGroup);
            setChangeFilterGroup(!changeFilterGroup)
            setCurrentGroup(group)
        }
    };

    const StyledTreeItem = styled((props) => (
            <TreeItem {...props} slots={{groupTransition: TransitionComponent}}/>
        ))(({theme}) => ({
            [`& .${treeItemClasses.iconContainer}`]: {
                '& .close': {
                    opacity: 0.3,
                },
            },
            "& .MuiTreeItem-content": {
                padding: 2,
                margin: `8px 0`,
                "&.Mui-selected": {
                    backgroundColor: "#D7E8FA",
                    borderRadius: 0
                },
                "& .MuiTreeItem-label": {
                    fontSize: 16,
                    lineHeight: 1,
                },
                "& .MuiTreeItem-iconContainer": {
                    width: 18,
                },
            },
            "& .MuiTreeItem-root": {
                '&:first-of-type': {
                    "& .MuiTreeItem-content": {marginTop: `0`},
                },
                '&[aria-expanded=true]': {
                    '& .MuiTreeItem-groupTransition .MuiTreeItem-root': {
                        '&:last-child': {
                            "& .MuiTreeItem-content": {margin: `0`},
                        },
                    }
                }
            },

            [`& .${treeItemClasses.groupTransition}`]: {
                paddingLeft: 14,
                marginLeft: 7,
                borderLeft: `2px dashed #737478`,
            },
        }))
    ;

    function TransitionComponent(props) {
        return <Collapse {...props} />;
    }

    const renderStyledTreeItem = (groups) => {
        return groups.map((group) => {
            const hasSubgroups = group.subGroups && group.subGroups.length > 0;

            return (
                <StyledTreeItem
                    key={group.id}
                    itemId={`${group.id}`}
                    label={group.name}
                    onClick={() => readOnly ? setCurrentGroup(group) : handleToggle(group)}
                >
                    {hasSubgroups && (
                        renderStyledTreeItem(group.subGroups)
                    )}
                </StyledTreeItem>
            );
        });
    }
    return <div>
        <Box sx={{flexGrow: 1, maxWidth: 225, overflowY: 'auto'}}>
            <SimpleTreeView
                expansionTrigger="iconContainer"
                aria-label="customized"
                defaultExpandedItems={['M']}
                selectedItems={[currentGroup !== null && (currentGroup?.id?.toString() || "M")]}
                slots={{
                    expandIcon: PlusSquare,
                    collapseIcon: MinusSquare,
                    endIcon: CloseSquare,
                }}
                sx={{
                    flexGrow: 1,
                    maxWidth: 400,
                    overflowY: 'auto'
                }}
            >
                <StyledTreeItem
                    itemId="M"
                    label={name}
                    onClick={() => readOnly ? setCurrentGroup(null) : handleToggle('M')}
                >
                    {renderStyledTreeItem(data)}
                </StyledTreeItem>
            </SimpleTreeView>
        </Box>
    </div>;
};

export default MaterialTree;
