import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Tooltip} from "@mui/material";
import {Delete, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";
import UnitService from "../../../../../../API/orders/warehouse/UnitService";
import EstimateService from "../../../../../../API/orders/estimate/organization/EstimateService";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import CellCheckboxCurrentWithUnable from "../../../../сheckbox/CellCheckboxCurrentWithUnable";
import {estimateStatuses} from "../../../../../../data/EstimateState";
import ModalConfirm from "../../../../modal/ModalConfirm";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import CustomerEstimateService from "../../../../../../API/orders/estimate/customer/CustomerEstimateService";

const CustomerOffersTable = ({
                                 orderId, customerOrderNumber
                             }) => {

    const [data, setData] = useState([]);
    const [currents, setCurrents] = useState({});
    const [change, setChange] = useState(false);
    const [unabledChangeCurrent, setUnabledChangeCurrent] = useState(false);
    const [currentIdRow, setCurrentIdRow] = useState(null);
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [modalOpenSetInWork, setModalOpenSetInWork] = useState(false);
    const [modalOpenReturnInCalculation, setModalOpenReturnInCalculation] = useState(false);

    useEffect(() => {
        CustomerEstimateService.getAllByOrderId(orderId).then(function (response) {
            const data = response.data;
            setData(data);
            /*setUnabledChangeCurrent(EstimateUtil.checkInWork(data));*/
            data.forEach((row) => {
                currents[row.id] = row.current;

            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {
        UnitService.update(values,)
            .then(function () {
                data[row.index] = values;
                setData([...data]);
            })
            .catch(function (error) {
                console.log(error);
            })
        exitEditingMode();
    };


    const handleDeleteRow = useCallback(
        (row) => {
            UnitService.delete(row.original.id,)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const setInWork = useCallback(
        (row) => {
            setCurrentIdRow(row.original.id);
            setCurrentRowIndex(row.index)
            setModalOpenSetInWork(true)
        },
        [data],
    );

    const returnInCalculation = useCallback(
        (row) => {
            setCurrentIdRow(row.original.id);
            setCurrentRowIndex(row.index)
            setModalOpenReturnInCalculation(true)
        },
        [data],
    );

    const handleSubmitSetInWork = async () => {
        EstimateService.setInWork(currentIdRow,)
            .then(function () {
                data[currentRowIndex].estimateState = 'IN_WORK';
                setData([...data]);
                setCurrentIdRow(null);
                setCurrentRowIndex(null);
                setModalOpenSetInWork(false);
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleSubmitReturnInCalculation = async () => {
        EstimateService.returnInCalculation(currentIdRow,)
            .then(function () {
                data[currentRowIndex].estimateState = 'CALCULATION';
                setData([...data]);
                setCurrentIdRow(null);
                setCurrentRowIndex(null);
                setModalOpenReturnInCalculation(false);
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleCloseSetInWork = () => {
        setCurrentIdRow(null);
        setCurrentRowIndex(null)
        setModalOpenSetInWork(false);
    };

    const handleCloseReturnInCalculation = () => {
        setCurrentIdRow(null);
        setCurrentRowIndex(null)
        setModalOpenReturnInCalculation(false);
    };

    const navigate = useNavigate();

    const redirectOrders = () => {
        navigate('/organization/orders/');
    };

    /*    const redirectOrder = () => {
            navigate('/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year);
        };*/

    const [modalBasicModelsOpen, setBasicModalModelsOpen] = useState(false);
    const [modalModelsOpen, setModalModelsOpen] = useState(false);
    const [basicModelId, setBasicModelId] = useState(null);

    const openBasicModels = () => {
        setBasicModalModelsOpen(true);
    };

    const closeModels = () => {
        setModalModelsOpen(false);
        setBasicModelId(null);
    };

    const closeBasicModels = () => {
        setBasicModalModelsOpen(false);
    };

    const renderCellValue = (cell, row, curAbbreviation) => {
        const value = cell.getValue();
        const currency = row.original[curAbbreviation];
        return value === null || value === undefined
            ? 'Ожидание'
            : value.price.toFixed(2) + " " + currency;
    };

    const columns = useMemo(
        () => [
            {
                id: 'nameOrganization',
                header: 'Организация',
                accessorKey: 'nameOrganization',
                minSize: 20,
                maxSize: 20,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/customer/orders/' + orderId + '/' + customerOrderNumber + '/estimates/'
                                + data[row.index].id
                                + '/' + row.original.baseCurAbbreviation
                                + '/' + row.original.alternativeCurAbbreviation
                        }>{cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                id: 'current',
                header: 'Текущий',
                accessorKey: 'current',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Cell: ({cell, row}) => <CellCheckboxCurrentWithUnable
                    id={row.id}
                    unable={currents}
                    setUnable={setCurrents}
                    orderId={orderId}
                    disabled={unabledChangeCurrent}
                    unableMethod={EstimateService.unableCurrent}
                />,
            },
            {
                id: 'basePrice',
                header: `Материал`,
                accessorKey: 'basePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => renderCellValue(cell, row, 'baseCurAbbreviation')
            },
            {
                id: 'baseJobPrice',
                header: `Работа`,
                accessorKey: 'baseJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => renderCellValue(cell, row, 'baseCurAbbreviation')
            },
            {
                id: 'baseTotalPrice',
                header: `Итого:`,
                accessorKey: 'baseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => renderCellValue(cell, row, 'baseCurAbbreviation')
            },
            {
                id: 'alternativePrice',
                header: `Материал`,
                accessorKey: 'alternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => renderCellValue(cell, row, 'alternativeCurAbbreviation')
            },
            {
                id: 'alternativeJobPrice',
                header: `Работа`,
                accessorKey: 'alternativeJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => renderCellValue(cell, row, 'alternativeCurAbbreviation')
            },
            {
                id: 'alternativeTotalPrice',
                header: `Итого:`,
                accessorKey: 'alternativeTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => renderCellValue(cell, row, 'alternativeCurAbbreviation')
            },
            {
                id: 'estimateState',
                header: 'Статус',
                accessorKey: 'estimateState',
                minSize: 20,
                maxSize: 20,
                Cell: ({cell}) => estimateStatuses[cell.getValue()],
            },

            {
                accessorFn: (row) => {
                    if (row.date === null) {
                    } else {
                        return new Date(row.date)
                    }
                },
                id: 'date',
                header: 'Дата',
                sortingFn: 'datetime',
                size: 10,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'Локация',
                id: 'locality',
                accessorKey: 'organization.address.locality',
                maxSize: 35,
            },
            {
                header: 'Регион доставки',
                id: 'regionDelivery',
                accessorKey: 'organization.regionDelivery',
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                editingMode="modal"
                enableEditing
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        {row.original.estimateState === 'CALCULATION' ?
                            <Tooltip arrow placement="right" title="Удалить">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="error"
                                    onClick={() => handleDeleteRow(row)}>
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> : <Button
                                disabled={true}
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="error"
                            >
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>}
                        {row.original.current === true && row.original.estimateState === 'IN_WORK' ?
                            <Tooltip arrow placement="right" title="Вернуть смету на доработку">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="primary"
                                    onClick={() => returnInCalculation(row)}>
                                    <KeyboardDoubleArrowLeft
                                        sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> : <Button
                                disabled={true}
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="primary"
                            >
                                <KeyboardDoubleArrowLeft sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>}
                        {row.original.current === true && row.original.estimateState === 'CALCULATION' ?
                            <Tooltip arrow placement="right" title="Отправить смету в работу">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                    color="primary"
                                    onClick={() => setInWork(row)}>
                                    <KeyboardDoubleArrowRight
                                        sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> : <Button
                                disabled={true}
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="primary"
                            >
                                <KeyboardDoubleArrowRight sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>}
                    </Box>
                )}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                }}
                localization={MRT_Localization_RU}
                getRowId={(originalRow) => originalRow.id}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '0.5rem',
                            paddingLeft: '0.5rem',
                            paddingTop: '0.5rem',
                            paddingBottom: '0.2rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        {/*  <Button
                            size="small"
                            color="secondary"
                            onClick={redirectOrder}
                            variant="contained"
                        >
                            Вернуться к заказу
                        </Button>*/}
                        <Button
                            size="small"
                            color="secondary"
                            onClick={redirectOrders}
                            variant="contained"
                        >
                            Вернуться в заказы
                        </Button>
                    </Box>
                )}
            />
            <ModalConfirm
                title={"Подтвердить отправить смету в работу?"}
                modalOpen={modalOpenSetInWork}
                handleClose={handleCloseSetInWork}
                handleSubmit={handleSubmitSetInWork}
                action={"Подтвердить"}
            />
            <ModalConfirm
                title={"Подтвердить вернуть смету на доработку?"}
                modalOpen={modalOpenReturnInCalculation}
                handleClose={handleCloseReturnInCalculation}
                handleSubmit={handleSubmitReturnInCalculation}
                action={"Подтвердить"}
            />
        </>
    );
};

export default CustomerOffersTable;