import React from "react";

export function MinusSquare(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#2C3038"/>
            <rect x="3.5" y="8.5" width="11" height="1" stroke="#2C3038"/>
        </svg>
    );
}

export function PlusSquare(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#737478"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8 15V14V10H4H3V9V8H4H8V4V3H9H10V4V8H14H15V9V10H14H10V14V15H9H8Z"
                  fill="#737478"/>
        </svg>
    );
}

export function CloseSquare(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#737478"/>
        </svg>

    );
}