import React from 'react';
import OrderProductionUtil from "../../../../../../utils/OrderProductionUtil";

const CellOPTm = ({ cell2, row }) => {
    const header = cell2.column.id;
    const cell = OrderProductionUtil.getValueByHeader(row.original.packageJobs, header);
    let backgroundColor;
    let displayValue;

    if (cell === null || cell === undefined) {
        backgroundColor = 'white';
        displayValue = 'n';
    } else if (cell >= 100) {
        backgroundColor = 'rgba(22, 184, 44, 0.5)';
        displayValue = '+';
    } else if (cell < 0) {
        backgroundColor = undefined;
        displayValue = 'n';
    } else {
        backgroundColor = 'rgba(240, 130, 12, 1)';
        displayValue = cell.toFixed(0);
    }

    return (
        <span style={{
            padding: cell >= 100 ? '0.82rem' : cell < 10 ? '0.83rem' : '0.58rem',
            backgroundColor: backgroundColor,
        }}>
            {displayValue}
        </span>
    );
};

export default CellOPTm;