import {BreadcrumbsComponent} from "../../../components/UI/breadcrumb/BreadcrumbComponent";
import Box from "@mui/material/Box";
import React from "react";
import CustomerRequestsEdges from "../edges/CustomerRequestsEdges";

const SamplesEdges = ({system}) => {
    return <Box sx={{padding: "0 30px"}}>
        <BreadcrumbsComponent
            page={"Кромки шаблонов"}
            path={[
                {name: "Главная", href: "/"},
            ]}/>

        <CustomerRequestsEdges samples={true} system={system}/></Box>
}
export default SamplesEdges