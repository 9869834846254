import {Popover} from "@mui/material";
import {Canvas} from "@react-three/fiber";
import DetailCanvas from "../../../../pages/customer/processing/DetailProcessing/Canvas/DetailCanvas";
import React, {useEffect, useRef, useState} from "react";
import {calcNewScale, INIT_SCALE_PREV_DETAIL} from "../../../../utils/processing/calcNewScale";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const SeeDetail = ({detailData, params}) => {
    return <Box
        sx={{
            "& canvas": {
                minWidth: "400px !important",
                minHeight: "400px !important",
            }
        }}>
        <Canvas>
            <DetailCanvas
                reaOnly={true}
                position={params.corePosition}
                detailData={detailData}
                scale={params.scale}
            />
        </Canvas>
    </Box>
}
const LOADING_BOX_SX = {
    height: "400px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const Loading = React.memo(() => (
    <Box sx={LOADING_BOX_SX}>
        <CircularProgress disableShrink/>
    </Box>
))

const ModalSeeDetail = ({open, seeDetailData, handleClose}) => {
    const refCanvas = useRef()
    const [params, setParams] = useState({scale: INIT_SCALE_PREV_DETAIL, corePosition: [0, 0, 0]});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        if (refCanvas.current) {
            const newScale = calcNewScale(refCanvas.current || {}, seeDetailData.data, true)
            const startX = -(seeDetailData.data.length / 2 * newScale)
            const startY = -(seeDetailData.data.width / 2 * newScale)
            const corePosition = [startX, startY, 0];

            setParams({scale: newScale, corePosition})
            setIsLoading(false)
        }

    }, [seeDetailData.data, refCanvas.current])


    return <Popover
        id="mouse-over-popover"
        ref={refCanvas}
        anchorEl={seeDetailData.position}
        anchorOrigin={{
            vertical: "top",
            horizontal: "right"
        }}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
        }}
        sx={{
            width: "600px",
            height: "600px",
            pointerEvents: 'none',
        }}
        open={open}
        onClose={handleClose}
        disableRestoreFocus>
        {isLoading
            ? <Loading/>
            : (
                <SeeDetail detailData={seeDetailData.data} params={params}/>)}
    </Popover>
}
export default ModalSeeDetail
