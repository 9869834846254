import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {
    Checkbox,
} from "@mui/material";
import Box from "@mui/material/Box";
import ButtonCreate from "../../../../button/ButtonCreate";
import MenuItem from "@mui/material/MenuItem";
import SupplierService from "../../../../../../API/orders/warehouse/supplier/SupplierService";
import {constantsErrorMessages} from "../../../../../../Constants";
import ModalAlert from "../../../customer-detales/ModalAlert";
import {SuppliersModal} from "./SuppliersModal";

const SuppliersTable = ({isModal, data, setData, suppliers, selectedString, setSelectedString, disabled}) => {
    const initValue = {name: "", supplierMaterialCloudId: "", active: true}

    const [modalOpen, setModalOpen] = useState(false);
    const [alertData, setAlertData] = useState({});
    const [currentEditRowData, setCurrentEditRowData] = useState(initValue);

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }

    const handleError = (error) => {
        setAlertData({
            type: 'error', open: true, message: constantsErrorMessages[error.response.status] || error.message
        })
    }

    const handleEditRow = (row) => {
        setCurrentEditRowData(row);
        setModalOpen(true)
    }
    const handleClose = () => {
        setModalOpen(false)
        setCurrentEditRowData(initValue)
    }
    const handleUpdate = (data) => {
        SupplierService.update(data, currentEditRowData.id)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleSubmit = (values) => {
        SupplierService.save(values).then(function (response) {
            setData(prev => [...prev, response.data])
        })
            .catch(function (error) {
                handleError(error)
            });

    };

    const handleDeleteRow = (row) => {
        SupplierService.delete(row.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.id))
            })
            .catch(function (error) {
                handleError(error)
            })
    };

    const columns = useMemo(
        () => [
            {
                id: 'name',
                header: 'Наименование',
                accessorKey: 'name',
                size: 300,
                grow: true,
            },
            {
                header: 'Активна',
                accessorKey: 'active',
                size: 8,
                grow: false,
                Cell: ({cell, row}) => <Checkbox
                    cell={cell}
                    checked={row.original.active}
                    disabled={true}
                    sx={{padding: "7px", '& .MuiSvgIcon-root': {fontSize: 22}}}
                >
                </Checkbox>,
            },
        ],
        [data, setData],
    );
    const menuItem = [
        {type: "edit", text: "Редактировать", handler: handleEditRow},
        {type: "delete", text: "Удалить", handler: handleDeleteRow}
    ]
    return (
        <>  < Box
            sx={{display: 'flex', justifyContent: "flex-end", paddingBottom: "30px", gap: "16px"}}
        >
            {!isModal && <ButtonCreate
                text={" Создать поставщика"}
                width={230}
                onClick={() => setModalOpen(true)}
            />}
        </Box>
            <MaterialReactTable
                muiTablePaperProps={{
                    sx: disabled && {
                        opacity: 0.7
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        width: '700px',
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                        border: "none",
                        "& td:after": {
                            "&:hover": {
                                backgroundColor: "red"
                            }
                        },
                        "&:not(:last-of-type)": {
                            borderRight: '1px solid #EAEBEA',

                        },
                        "&:last-of-type": {
                            padding: 0,
                            width: 'calc(100% - 1px)',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        },
                        "&:first-of-type": !isModal && {
                            padding: 0,
                            width: "100%",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: (theme) => theme.palette.text.light,
                            "& .MuiButtonBase-root": {
                                borderRadius: "4px",
                                width: "24px",
                                height: "24px",
                                opacity: 1,
                                margin: 0,
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.stroke,
                                }
                            }
                        },
                        "& li": {
                            listStyleType: "none",
                            "& a": {
                                color: "#006CCC",
                                textDecoration: "none",
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }
                        }
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid #EAEBEA',
                        backgroundColor: "#F5F6FA",
                        padding: "10px",
                        paddingBottom: " 0.2rem",
                        paddingTop: "0.2rem"
                    },
                }}
                muiTableBodyRowProps={({row}) => {
                    return ({
                        onClick: () => isModal && !disabled && setSelectedString(row.original),
                        sx: () => ({
                            height: '36px',
                            cursor: isModal && "pointer",
                            backgroundColor: row.original.id === selectedString?.id ? "#D7E8FA" : "white",
                            "&:hover& td:after": {
                                display: "none",
                            },
                            "&:hover": {
                                "& td:after": {
                                    display: "none",
                                },
                            },
                            '&:nth-of-type(even)': {
                                backgroundColor: row.original.id === selectedString?.id ? "#D7E8FA" : '#F5F6FA',
                            },
                        })
                    })
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: "",
                        size: 3,
                        minSize: 3,
                        grow: false,
                    },
                }}
                muiSearchTextFieldProps={{
                    placeholder: `Поиск`,
                    backgroundColor: "red",
                    sx: {
                        backgroundColor: "red",
                        paddingTop: "5px",
                        paddingBottom: "0px",
                        "& .MuiCollapse-root": {
                            borderRadius: '12px',
                            height: 40, width: 290,
                            "& .MuiSvgIcon-root": {
                                color: "#737478"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: 0
                            }
                        }
                    },
                    variant: 'outlined',
                }}
                state={{showGlobalFilter: true}}
                columns={columns}
                data={data || []}
                renderRowActionMenuItems={({closeMenu, row}) => menuItem.map(el => {
                    return <MenuItem
                        key={el.type}
                        onClick={() => {
                            el.handler(row.original)
                            closeMenu();
                        }}
                        sx={{
                            margin: "0 8px",
                            borderRadius: "4px",
                            "&:hover": {backgroundColor: (theme) => theme.palette.primary.light}
                        }}
                    >
                        {el.text}
                    </MenuItem>
                })}
                initialState={{
                    sorting: [{id: 'name', desc: false}],
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                    columnPinning: {left: ['number']}
                }}
                localization={MRT_Localization_RU}
                enableToolbarInternalActions={false}
                enableRowActions={!isModal}
                enableColumnActions={false}

            />
            <ModalAlert data={alertData} onClose={handleCloseAlert}/>
            <SuppliersModal
                columns={columns}
                open={modalOpen}
                onClose={handleClose}
                initValue={currentEditRowData}
                title={currentEditRowData.id ? "Изменить поставщика" : "Добавить поставщика"}
                onSubmit={currentEditRowData.id ? handleUpdate : handleSubmit}
                suppliers={suppliers}
            />
        </>
    );
};

export default SuppliersTable;